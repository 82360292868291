import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Button from "@mui/material//Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import * as configActions from "../../store/actions/config";

import Confirmation from "../components/Confirmation";

import { getOptions, yesOrNo } from "../../utils/selectOptions";

import deleteFiles from "../../utils/deleteFiles";

import getLabel from "../../utils/getLabel";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const TextSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(6, "Title is too short.")
    .max(150, "Too Long!"),
  text: Yup.string().required("Text is required").min(10, "Text is too short."),
});

const ErrorText = (props) => {
  return (
    <div style={{ marginLeft: 10, marginBottom: 10 }}>
      <Typography style={{ color: "#FF0000" }} variant="caption">
        {props.label}
      </Typography>
    </div>
  );
};

const Details = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const awsKey = useSelector((state) => state.config.awsKey);
  const fileList = useSelector((state) => state.config.attachments);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);

  const handleSave = async () => {
    const selectedCompany = [];

    !!props.formData.company &&
      props.formData.company.map((item) => {
        selectedCompany.push(item.value);
      });

    const data = {
      ...props.formData,
      row_id: props.bannerID,
      company: selectedCompany,
      comments_on: props.formData.commenting.value,
    };

    try {
      setLoadingSave(true);
      const output = await dispatch(configActions.addEditBanner(data));
      props.setBannerID(output);
      setLoadingSave(false);

      props.setFormData({
        ...props.formData,
        mode: "edit",
      });

      setLoadingAlert(true);
      setTimeout(() => {
        setLoadingAlert(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    try {
      setLoading(true);
      await dispatch(configActions.addEditBanner(formData));
      deleteFiles(awsKey, fileList, "announcements");
      setLoading(false);

      navigate(`/manageBanner`, {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          row_id: props.formData.row_id || props.bannerID,
          title: props.formData.title,
          text: props.formData.text,
          dateFrom: props.formData.validFrom,
        }}
        // validationSchema={TextSchema}
        onSubmit={() => {
          handleSave();
        }}
      >
        {({ handleChange, values, setFieldValue, errors, touched }) => (
          <Form>
            <Stack spacing={2}>
              {(props.formData.row_id || props.bannerID) && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <TextField
                    disabled
                    autoComplete="off"
                    id="row_id"
                    name="row_id"
                    label={getLabel(
                      sysLanguage,
                      "6000",
                      "1",
                      "Announcement ID"
                    )}
                    value={
                      values.row_id
                        ? values.row_id.toString().padStart(7, "0")
                        : props.bannerID.toString().padStart(7, "0")
                    }
                    size="small"
                  />
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={
                      values.dateFrom ? new Date(values.dateFrom) : Date.now()
                    }
                    disabled={props.canEdit}
                    label={getLabel(
                      sysLanguage,
                      "6000",
                      "8",
                      "Date Validity (From)"
                    )}
                    inputFormat="yyyy/MM/dd"
                    value={props.formData.validFrom}
                    onChange={(data) =>
                      props.setFormData({
                        ...props.formData,
                        validFrom: !!data ? data.toLocaleDateString() : null,
                        validTo: null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        sx={{ width: 250 }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={props.canEdit || !props.formData.validFrom}
                    minDate={new Date(props.formData.validFrom)}
                    label={getLabel(
                      sysLanguage,
                      "6000",
                      "9",
                      "Date Validity (To)"
                    )}
                    inputFormat="yyyy/MM/dd"
                    value={props.formData.validTo}
                    onChange={(data) =>
                      props.setFormData({
                        ...props.formData,
                        validTo: !!data ? data.toLocaleDateString() : null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        sx={{ width: 250 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Autocomplete
                  disabled={props.canEdit}
                  fullWidth
                  multiple
                  id="company"
                  name="company"
                  value={props.formData.company}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, company: data })
                  }
                  size="small"
                  options={getOptions(sysLanguage, props.companyList)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "6000",
                        "10",
                        "Target Company"
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  disabled={props.canEdit}
                  fullWidth
                  required
                  autoComplete="off"
                  id="title"
                  name="title"
                  label={getLabel(sysLanguage, "6000", "11", "Title")}
                  value={props.formData.title}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      title: e.target.value,
                    });
                  }}
                  // onChange={handleChange("title")}
                  size="small"
                />
                {errors.title && touched.title ? (
                  <ErrorText label={errors.title} />
                ) : null}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  disabled={props.canEdit}
                  fullWidth
                  required
                  multiline
                  minRows={3}
                  id="text"
                  name="text"
                  label={getLabel(sysLanguage, "6000", "12", "Content")}
                  value={props.formData.text}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      text: e.target.value,
                    });
                  }}
                  // onChange={handleChange("text")}
                  size="small"
                />
                {errors.text && touched.text ? (
                  <ErrorText label={errors.text} />
                ) : null}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Autocomplete
                  disabled={props.canEdit}
                  id="commenting"
                  name="commenting"
                  value={props.formData.commenting}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, commenting: data })
                  }
                  size="small"
                  sx={{ width: 200 }}
                  options={yesOrNo([])}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={getLabel(
                        sysLanguage,
                        "6000",
                        "13",
                        "Enable Comments"
                      )}
                    />
                  )}
                />
              </Grid>
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignContent: "flex-end",
                mt: 1,
              }}
            >
              <LoadingButton
                disabled={props.canEdit}
                type="submit"
                // onClick={() => handleSave()}
                loading={loadingSave}
                variant="contained"
                color="primary"
                sx={{ m: 1, width: 150 }}
              >
                {getLabel(sysLanguage, "9000", "6", "Save")}
              </LoadingButton>
              <Button
                sx={{ m: 1, width: 150 }}
                color="warning"
                variant="contained"
                onClick={() => navigate(`/manageBanner`, { replace: true })}
              >
                {getLabel(sysLanguage, "9000", "7", "Cancel")}
              </Button>
              {!props.canEdit && (
                <>
                  {!!props.formData.row_id && (
                    <Grid container justifyContent="flex-end">
                      <Confirmation
                        buttonName={getLabel(
                          sysLanguage,
                          "6000",
                          "18",
                          "Delete Announcement"
                        )}
                        title={getLabel(
                          sysLanguage,
                          "6000",
                          "18",
                          "Delete Announcement"
                        )}
                        context={getLabel(
                          sysLanguage,
                          "9000",
                          "5",
                          "Are you sure you want to delete this item?"
                        )}
                        color="error"
                        variant="outlined"
                        yesFunction={() =>
                          handleDelete({
                            mode: "delete",
                            row_id: props.formData.row_id,
                          })
                        }
                      />
                    </Grid>
                  )}
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
      {loadingAlert && (
        <div>
          <Alert severity="success">
            {getLabel(sysLanguage, "9000", "10", "Announcement Saved!")}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Details;
