import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import S3 from "react-aws-s3";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

import formatTime from "../../utils/formatTime";
import getLabel from "../../utils/getLabel";

import * as userActions from "../../store/actions/user";

import { Formik, Form } from "formik";

const fileSizeLimit = 5242880;

const Details = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);
  const awsKey = useSelector((state) => state.config.awsKey);

  const userData = useSelector((state) => state.auth.userData[0]);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [loadingAttach, setLoadingAttach] = useState(false);
  const [error, setError] = useState("");

  const [myAvatarUser] = useState(props.searchOneUser.user_id);
  const [myAvatar, setMyAvatar] = useState(props.searchOneUser.avatar);
  const [myAvatarKey, setMyAvatarKey] = useState(
    props.searchOneUser.avatar_key
  );

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 3000);
  }, [error]);

  const handleSave = async (formData) => {
    try {
      setLoadingSave(true);
      await dispatch(userActions.updateAccount(formData, userData.user_id));
      setLoadingSave(false);

      setLoadingAlert(true);
      setTimeout(() => {
        setLoadingAlert(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const checkAccess = () => {
    if (props.searchOneUser.role_name === "TRAINEE") {
      if (
        userData.user_id === props.searchOneUser.user_id ||
        props.canEditTrainee ||
        props.canEditOfficer
      )
        return false;
      else {
        return true;
      }
    } else {
      if (
        userData.user_id === props.searchOneUser.user_id ||
        props.canEditOfficer
      )
        return false;
      else {
        return true;
      }
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  //START Attachment
  const changeHandler = (event) => {
    if (!!event.target.files[0]) {
      uploadFile(event);
    }
  };

  const uploadFile = (event) => {
    const config = {
      bucketName: awsKey.bucket,
      dirName: "profile",
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    };

    const ReactS3Client = new S3(config);

    const random = uuidv4();
    const newFileName = random + "_" + event.target.files[0].name;

    setLoadingAttach(true);

    if (event.target.files[0].size <= fileSizeLimit) {
      ReactS3Client.uploadFile(event.target.files[0], newFileName)
        .then((data) => {
          if (data.status === 204) {
            deleteFile(myAvatarKey, "add");
            addAttachment({
              user_id: myAvatarUser,
              avatar: data.location,
              key: data.key,
            });
            setMyAvatar(data.location);
            setMyAvatarKey(data.key);
          } else {
            setError("File upload error-connection refused!");
            setLoadingAttach(false);
          }
        })
        .catch((err) => {
          setError("File upload error-connection refused!");
          setLoadingAttach(false);
          console.error(err);
        });
    } else {
      setError(
        getLabel(sysLanguage, "9000", "33", "File size must be less than ") +
          " " +
          formatBytes(fileSizeLimit) +
          "."
      );
      setLoadingAttach(false);
    }
  };

  const addAttachment = async (data) => {
    try {
      await dispatch(userActions.updateAvatar(data));
      setLoadingAttach(false);
    } catch (err) {
      setError("File upload error-cannot connect to server!");
      setLoadingAttach(false);
      console.log(err.message);
    }
  };

  const deleteFile = async (keyName, mode) => {
    AWS.config.update({
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    });
    const s3 = new AWS.S3();

    const deleteParam = {
      Bucket: awsKey.bucket,
      Key: keyName,
    };

    if (!!myAvatar) {
      await s3.deleteObject(deleteParam, function (err, data) {
        if (err) {
          // an error occurred
          console.log(err, err.stack);
        } else {
          // successful response

          const delAtt = async () => {
            const data = {
              user_id: myAvatarUser,
              avatar: "",
              key: "",
            };

            await dispatch(userActions.updateAvatar(data));
          };

          if (mode === "delete") {
            delAtt();
            setMyAvatar();
            setMyAvatarKey();
          }
          console.log("Deleted Sucessfully!");
        }
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          user_id: props.searchOneUser.user_id,
          role_name: props.searchOneUser.role_name,
          email_id: props.searchOneUser.email_id,
          position: props.searchOneUser.position,
          fullname: props.searchOneUser.fullname,
          traineename: props.searchOneUser.traineename,
          last_login_dttm: props.searchOneUser.last_login_dttm,
          auditname: props.searchOneUser.auditname,
          last_update_dttm: props.searchOneUser.last_update_dttm,
        }}
        onSubmit={(values) => {
          const addedData = {
            ...values,
            language: props.formData.language.value,
            email_notif: props.formData.email_notif.value,
            account_locked: props.formData.status.value,
            position: props.formData.position,
            alternate_name: props.formData.alternate_name,
          };
          handleSave(addedData);
        }}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Divider textAlign="left" sx={{ mt: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "13", "Account Details")}
                  </Typography>
                </Divider>
                <div>
                  <TextField
                    disabled
                    id="user_id"
                    name="user_id"
                    label={getLabel(sysLanguage, "5000", "1", "Employee ID")}
                    value={values.user_id?.toString().padStart(7, "0")}
                    onChange={handleChange("user_id")}
                    size="small"
                    sx={{ width: 250, mb: 1 }}
                  />
                  <TextField
                    disabled
                    id="role_name"
                    name="role_name"
                    label={getLabel(sysLanguage, "5000", "14", "User Type")}
                    value={values.role_name}
                    onChange={handleChange("role_name")}
                    size="small"
                    sx={{ width: 250, mb: 1 }}
                  />
                </div>
                {props.searchOneUser.role_name === "TRAINEE" ? (
                  <TextField
                    // disabled={props.isTrainee && props.canEditTrainee}
                    disabled
                    fullWidth
                    id="traineename"
                    name="traineename"
                    label={getLabel(sysLanguage, "5000", "2", "Name")}
                    value={values.traineename}
                    // onChange={handleChange("fullname")}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                ) : (
                  <TextField
                    disabled={checkAccess()}
                    required
                    fullWidth
                    id="alternate_name"
                    name="alternate_name"
                    label={getLabel(
                      sysLanguage,
                      "5000",
                      "44",
                      "Alternate Name"
                    )}
                    value={props.formData.alternate_name}
                    onChange={(e) => {
                      props.setFormData({
                        ...props.formData,
                        alternate_name: e.target.value,
                      });
                    }}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                )}
                <TextField
                  disabled
                  fullWidth
                  autoComplete="off"
                  id="email_id"
                  name="email_id"
                  label={getLabel(sysLanguage, "5000", "3", "Email ID")}
                  value={values.email_id}
                  onChange={handleChange("email_id")}
                  size="small"
                  sx={{ mb: 1 }}
                />
                {props.searchOneUser.role_name === "OFFICER" && (
                  <TextField
                    disabled={checkAccess()}
                    fullWidth
                    id="position"
                    name="position"
                    label={getLabel(sysLanguage, "5000", "10", "Designation")}
                    value={props.formData.position}
                    onChange={(e) => {
                      props.setFormData({
                        ...props.formData,
                        position: e.target.value,
                      });
                    }}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                )}
                {/*
                <Divider textAlign="left" sx={{ mt: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "20", "Notification")}
                  </Typography>
                </Divider>
                 <Autocomplete
                  disabled={checkAccess()}
                  id="email_notif"
                  name="email_notif"
                  value={props.formData.email_notif}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, email_notif: data })
                  }
                  size="small"
                  options={props.emailNotifOption}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "5000",
                        "21",
                        "Receive Email"
                      )}
                    />
                  )}
                /> */}
                <Divider textAlign="left" sx={{ mt: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "22", "Language")}
                  </Typography>
                </Divider>
                <Autocomplete
                  disabled={checkAccess()}
                  disableClearable
                  id="language"
                  name="language"
                  value={props.formData.language}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, language: data })
                  }
                  size="small"
                  options={props.languageOption}
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={getLabel(sysLanguage, "5000", "23", "Language")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Divider textAlign="left" sx={{ mt: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "15", "Account Status")}
                  </Typography>
                </Divider>
                <Autocomplete
                  disabled={checkAccess()}
                  disableClearable
                  id="status"
                  name="status"
                  value={props.formData.status}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, status: data })
                  }
                  size="small"
                  sx={{ width: 300 }}
                  options={props.statusOption}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={getLabel(sysLanguage, "5000", "11", "Status")}
                    />
                  )}
                />
                <Divider textAlign="left" sx={{ mt: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "16", "Audit Information")}
                  </Typography>
                </Divider>
                <TextField
                  disabled
                  fullWidth
                  id="last_login_dttm"
                  name="last_login_dttm"
                  label={getLabel(sysLanguage, "5000", "17", "Last Login")}
                  value={formatTime(values.last_login_dttm)}
                  size="small"
                  sx={{ width: 300, mb: 1 }}
                />
                <TextField
                  disabled
                  fullWidth
                  id="auditname"
                  name="auditname"
                  label={getLabel(sysLanguage, "5000", "18", "Last Updated By")}
                  value={values.auditname}
                  size="small"
                  sx={{ width: 300, mb: 1 }}
                />
                <TextField
                  disabled
                  fullWidth
                  id="last_update_dttm"
                  name="last_update_dttm"
                  label={getLabel(sysLanguage, "5000", "19", "Last Updated On")}
                  value={formatTime(values.last_update_dttm)}
                  size="small"
                  sx={{ width: 300, mb: 1 }}
                />
                <Divider textAlign="left" sx={{ mt: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "57", "Profile Picture")}
                  </Typography>
                </Divider>
                <Stack direction="row" spacing={2}>
                  {props.searchOneUser.user_id === userData.user_id && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Stack direction="column" spacing={2}>
                        <LoadingButton
                          sx={{ height: 30 }}
                          variant="outlined"
                          component="label"
                          loading={loadingAttach}
                        >
                          {!!myAvatar
                            ? getLabel(
                                sysLanguage,
                                "9000",
                                "30",
                                "Change Picture"
                              )
                            : getLabel(
                                sysLanguage,
                                "9000",
                                "31",
                                "Add Picture"
                              )}
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => changeHandler(e)}
                          />
                        </LoadingButton>
                        {!!myAvatarKey && (
                          <Button
                            sx={{ height: 30 }}
                            color="error"
                            variant="outlined"
                            onClick={() => {
                              deleteFile(myAvatarKey, "delete");
                            }}
                          >
                            {getLabel(
                              sysLanguage,
                              "9000",
                              "32",
                              "Remove Picture"
                            )}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  )}
                  <Avatar
                    alt="Avatar"
                    src={!!myAvatar && myAvatar}
                    sx={{ m: 1, height: "120px", width: "120px" }}
                  />
                  {error.length > 0 && (
                    <div>
                      <Alert severity="error">{error}</Alert>
                    </div>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <div style={{ marginTop: "20px" }}>
                  <LoadingButton
                    disabled={checkAccess()}
                    type="submit"
                    loading={loadingSave}
                    variant="contained"
                    color="primary"
                    sx={{ m: 1 }}
                  >
                    {getLabel(sysLanguage, "9000", "6", "Save")}
                  </LoadingButton>
                  <Button
                    sx={{ m: 1 }}
                    color="warning"
                    variant="contained"
                    onClick={() =>
                      props.isTrainee || props.myAccount
                        ? navigate(`/`, {})
                        : navigate(`/manageUser`, {})
                    }
                  >
                    {getLabel(sysLanguage, "9000", "7", "Cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {loadingAlert && (
        <div>
          <Alert severity="success">
            {getLabel(sysLanguage, "9000", "10", "Changes Saved!")}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Details;
