import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";

import CardMedia from "@mui/material/CardMedia";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import BusinessIcon from "@mui/icons-material/Business";
import SendIcon from "@mui/icons-material/Send";
import CommentIcon from "@mui/icons-material/Comment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Confirmation from "../components/Confirmation";
import Translation from "../components/Translation";

import { LinkItUrl } from "react-linkify-it";

import debounce from "lodash.debounce";

import formatTime from "../../utils/formatTime";

import * as configActions from "../../store/actions/config";
import * as dashboardActions from "../../store/actions/dashboard";

import { selectListFilter } from "../../utils/selectOptions";

import getLabel from "../../utils/getLabel";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const style = {
  overflowY: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  minHeight: 400,
  maxHeight: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BannerGrid = (props) => {
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginX: props.open ? 0.4 : 2 }}
      >
        <Stack direction="row" spacing={1}>
          <Box>
            <Avatar
              src={props.bannerData.avatar}
              sx={{ width: 40, height: 40, mr: 1 }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2" align="left">
              {props.bannerData.last_updated_by}
            </Typography>
            <Typography variant="caption">
              {formatTime(props.bannerData.last_updated_dt)}
            </Typography>
          </Box>
        </Stack>

        {props.open ? (
          <Grid xs>
            <Translation
              title="Translation"
              variant="contained"
              yesFunction={(code) => {
                props.handleTranslate(code, props.bannerData.text);
              }}
            />
          </Grid>
        ) : (
          <Grid xs>
            <AvatarGroup
              total={props.bannerData.user_seen_avatar?.length}
              max={18}
            >
              {props.bannerData.user_seen_avatar?.length > 0 &&
                props.bannerData.user_seen_avatar?.map((item, index) => (
                  <Avatar alt={item} src={item} />
                ))}
            </AvatarGroup>
          </Grid>
        )}
      </Grid>

      {/* <Grid container justifyContent="flex-start" wrap="nowrap"> */}
      <Grid sx={{ marginTop: props.open ? 3 : 1, marginX: !props.open && 2 }}>
        {!props.isTrainee && (
          <Stack direction="row" spacing={1} style={{ flexWrap: "wrap" }}>
            {props.bannerData.company?.length > 0 &&
              props.bannerData.company?.map((item, index) => (
                <Chip
                  icon={<BusinessIcon />}
                  label={selectListFilter(
                    props.companyList,
                    item,
                    "code",
                    "descr",
                    "Y",
                    props.sysLanguage
                  )}
                  variant="outlined"
                  color="success"
                  size="small"
                  sx={{ marginBottom: 1 }}
                />
              ))}
          </Stack>
        )}
        {!props.open && props.bannerData.files[0] !== null && (
          <Box
            sx={{
              marginBottom: 1,
            }}
          >
            {props.bannerData.files?.length > 1 ? (
              <Fragment>
                <Box
                  sx={{
                    width: "100%",
                    overflowY: "scroll",
                    maxHeight: 350,
                  }}
                >
                  <ImageList
                    variant="masonry"
                    cols={props.bannerData.files?.length > 2 ? 3 : 2}
                    gap={8}
                  >
                    {props.bannerData.files?.map((item) => (
                      <Fragment>
                        {/* {item && item.split("||")[1].includes("image") && ( */}
                        <ImageListItem key={item}>
                          {item.split("||")[1].includes("image") ? (
                            <img
                              onClick={() =>
                                window.open(
                                  props.awsKey.URL + item.split("||")[0],
                                  "_blank"
                                )
                              }
                              src={`${
                                props.awsKey.URL + item.split("||")[0]
                              }?w=164&h=164&fit=crop&auto=format`}
                              //   srcSet={`${
                              //     props.awsKey.URL + item.split("||")[0]
                              //   }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              alt={item.split("||")[2]}
                              loading="lazy"
                            />
                          ) : (
                            <Button
                              onClick={() =>
                                window.open(
                                  props.awsKey.URL + item.split("||")[0],
                                  "_blank"
                                )
                              }
                            >
                              {item.split("||")[2]}
                            </Button>
                          )}
                        </ImageListItem>
                        {/* )} */}
                      </Fragment>
                    ))}
                  </ImageList>
                </Box>
              </Fragment>
            ) : (
              <Box sx={{ width: "100%", overflowY: "scroll", maxHeight: 350 }}>
                {props.bannerData.files?.map((item) => (
                  <CardMedia
                    component="img"
                    // height="250"
                    image={props.awsKey.URL + item.split("||")[0]}
                    alt={item.split("||")[2]}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
          <Link
            component="button"
            underline="always"
            onClick={() => props.handleOpen(props.bannerData)}
          >
            <Typography align="left" variant="h6" gutterBottom>
              {!props.open && props.bannerData.title.length > 80
                ? props.bannerData.title.substring(0, 77) + "..."
                : props.bannerData.title}
            </Typography>
          </Link>
          {!props.bannerData.user_seen?.includes(props.userData.user_id) &&
            !props.open && <Chip label="New" color="primary" size="small" />}
        </Stack>

        <Box sx={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
          <Typography
            align="left"
            variant="subtitle2"
            color={!!props.translatedText && "primary"}
          >
            <LinkItUrl>
              {!props.open && props.bannerData.text.length > 500
                ? props.bannerData.text.substring(
                    0,
                    props.bannerData.text.length * 0.2
                  ) + " ... "
                : !!props.translatedText
                ? props.translatedText
                : props.bannerData.text}
            </LinkItUrl>
          </Typography>
        </Box>
      </Grid>
      {/* </Grid> */}
    </Fragment>
  );
};

const BannerDash = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const comments = useSelector((state) => state.config.oneComment);

  const [openComm, setOpenComm] = useState(false);
  const [openSeen, setOpenSeen] = useState(false);
  const [open, setOpen] = useState(false);

  const [bannerData, setBannerData] = useState({});
  const [rowData, setRowData] = useState({});

  const [filterReaction, setFilterReaction] = useState([]);
  const [seenData, setSeenData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [comment, setComment] = useState();

  const [targetComment, setTargetComment] = useState();
  const [translatedText, setTranslatedText] = useState();

  const [admin, setAdmin] = useState(false);

  const [selAction, setSelAction] = useState();

  const handleSend = async (text) => {
    const addedData = {
      source_id: rowData.row_id,
      text: text,
      fullname: userData.alternate_name,
      module: "BANNER",
    };
    try {
      setLoading(true);
      await dispatch(configActions.addComment(addedData));
      setComment("");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    try {
      setLoading(true);
      await dispatch(configActions.deleteComment(formData));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTranslate = async (code, text, comment_id, module) => {
    setTargetComment(comment_id);

    try {
      setLoading(true);
      const translatedValue = await dispatch(
        configActions.translateText(code, text)
      );
      setLoading(false);

      setTranslatedText(translatedValue);
    } catch (error) {
      console.log(error);
    }
  };

  const jumpToNavigate = async (row_id) => {
    const addedData = {
      row_id: row_id,
      title: "",
      company: null,
      show: "A",
    };
    await dispatch(configActions.getAllBanner(addedData));
    await dispatch(configActions.openOneBanner({ row_id: row_id }));
    navigate(`/MainBanner`, {});
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const isReadOnly = () => {
    const isAdmin =
      roleArray &&
      roleArray?.some((item) => item.role_name === "CREATE_ANNOUNCEMENT");
    if (isAdmin) {
      setAdmin(true);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    isReadOnly();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!!props.selRow) {
      try {
        const newLike = props.like?.filter(
          (item) => item.row_id === props.selRow
        );

        const newDislike = props.dislike?.filter(
          (item) => item.row_id === props.selRow
        );

        let rowItem = props.dashBannerList?.filter(
          (item) => item.row_id === props.selRow
        );

        rowItem = {
          row_id: rowItem[0]?.row_id,
          user_liked: newLike[0]?.user_liked,
          user_disliked: newDislike[0]?.user_disliked,
          added_by: userData.user_id,
          company: userData.company,
          action: selAction,
          module: "BANNER",
        };

        debouncedupdateDBase(rowItem);
        props.setSelRow();
      } catch (error) {
        props.setSelRow();
      }
    }
  }, [props.like, props.dislike]);

  const handleLike = (rowItem) => {
    //remove the dislike if any
    const carryOverDL = props.dislike?.filter(
      (item) => item.row_id !== rowItem.row_id
    );

    const roww = props.dislike?.filter(
      (item) => item.row_id === rowItem.row_id
    );

    const dlRow = roww[0].user_disliked?.filter(
      (item) => item !== userData.user_id
    );

    // if (carryOverDL.length > 0) {
    props.setDislike([
      ...carryOverDL,
      {
        row_id: rowItem.row_id,
        user_disliked: dlRow,
      },
    ]);
    // }

    //add Like
    const carryOver = props.like?.filter(
      (item) => item.row_id !== rowItem.row_id
    );

    const rowArray = props.like?.filter(
      (item) => item.row_id === rowItem.row_id
    );
    const isExist = rowArray[0]?.user_liked?.includes(userData.user_id);

    if (isExist) {
      const removedUser = rowArray[0]?.user_liked?.filter(
        (item) => item !== userData.user_id
      );

      props.setLike([
        ...carryOver,
        { row_id: rowItem.row_id, user_liked: removedUser },
      ]);
    } else {
      rowArray[0].user_liked?.push(userData.user_id);
      props.setLike([
        ...carryOver,
        {
          row_id: rowItem.row_id,
          user_liked: !!rowArray[0].user_liked
            ? rowArray[0].user_liked
            : [userData.user_id],
        },
      ]);
    }
  };

  const handleDislike = (rowItem) => {
    //remove the Like if any
    const carryOverLK = props.like?.filter(
      (item) => item.row_id !== rowItem.row_id
    );

    const roww = props.like?.filter((item) => item.row_id === rowItem.row_id);

    const dlRow = roww[0].user_liked?.filter(
      (item) => item !== userData.user_id
    );

    // if (carryOverLK.length > 0) {
    props.setLike([
      ...carryOverLK,
      {
        row_id: rowItem.row_id,
        user_liked: dlRow,
      },
    ]);
    // }

    //add Dislike
    const carryOver = props.dislike?.filter(
      (item) => item.row_id !== rowItem.row_id
    );

    const rowArray = props.dislike?.filter(
      (item) => item.row_id === rowItem.row_id
    );
    const isExist = rowArray[0]?.user_disliked?.includes(userData.user_id);

    if (isExist) {
      const removedUser = rowArray[0]?.user_disliked?.filter(
        (item) => item !== userData.user_id
      );

      props.setDislike([
        ...carryOver,
        { row_id: rowItem.row_id, user_disliked: removedUser },
      ]);
    } else {
      rowArray[0].user_disliked?.push(userData.user_id);
      props.setDislike([
        ...carryOver,
        {
          row_id: rowItem.row_id,
          user_disliked: !!rowArray[0].user_disliked
            ? rowArray[0].user_disliked
            : [userData.user_id],
        },
      ]);
    }
  };

  const updateDBase = async (rowItem) => {
    try {
      await dispatch(dashboardActions.updateReaction(rowItem));
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedupdateDBase = useCallback(debounce(updateDBase, 500), []);

  const handleReaction = (rowItem, reaction) => {
    console.log(rowItem, reaction);
    try {
      props.setSelRow(rowItem.row_id);
      setSelAction(reaction);

      if (reaction === "A") {
        handleLike(rowItem);
      }

      if (reaction === "B") {
        handleDislike(rowItem);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const countReaction = (rowItem, action) => {
    if (action === "A") {
      const rowArray = props.like?.filter(
        (item) => item.row_id === rowItem.row_id
      );

      return rowArray[0]?.user_liked?.length;
    }

    if (action === "B") {
      const rowArray = props.dislike?.filter(
        (item) => item.row_id === rowItem.row_id
      );

      return rowArray[0]?.user_disliked?.length;
    }
  };

  const userReaction = (rowNum, action) => {
    let value;
    if (action === "A") {
      if (props.like.length > 0) {
        const newArray = props.like?.filter((item) => item.row_id === rowNum);
        value = newArray[0]?.user_liked?.includes(userData.user_id);

        return value;
      }
    }

    if (action === "B") {
      if (props.dislike.length > 0) {
        const newArray = props.dislike?.filter(
          (item) => item.row_id === rowNum
        );
        value = newArray[0]?.user_disliked?.includes(userData.user_id);

        return value;
      }
    }
  };

  const handleOpen = (data) => {
    setTranslatedText();
    setBannerData(data);
    setOpen(true);

    const rowItem = {
      row_id: data.row_id,
      added_by: userData.user_id,
      company: userData.company,
      action: "S",
      module: "BANNER",
    };

    debouncedupdateDBase(rowItem);
  };

  const handleClose = () => setOpen(false);

  const handleCloseComm = () => setOpenComm(false);

  const handleOpenComm = async (data) => {
    try {
      setRowData(data);
      await dispatch(configActions.getComment(data.row_id, "BANNER"));
      setOpenComm(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSeen = () => setOpenSeen(false);

  const handleOpenSeen = async (data) => {
    try {
      setRowData(data);

      const formData = { source_id: data.row_id, module: "BANNER" };

      const output = await dispatch(configActions.getSeenDetails(formData));

      setSeenData(output);
      setFilterReaction(output);

      setOpenSeen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterReaction = (action) => {
    setFilterReaction(seenData);
    if (action !== "X")
      setFilterReaction(seenData.filter((item) => item.action === action));
  };

  return (
    <Fragment>
      {/* Open Seen */}
      <Modal
        open={openSeen}
        onClose={handleCloseSeen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "50%", height: 600 }}>
          <Button
            sx={{ mb: 4 }}
            color="warning"
            variant="contained"
            onClick={() => setOpenSeen(false)}
          >
            Close
          </Button>
          <div>
            <Typography
              sx={{ display: "block" }}
              component="span"
              variant="h6"
              color="text"
              gutterBottom
            >
              {rowData.title}
            </Typography>
            <Grid
              container
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                mx: 2,
              }}
            >
              <IconButton
                color="primary"
                aria-label="like"
                component="label"
                onClick={() => handleFilterReaction("X")}
              >
                全
              </IconButton>
              <IconButton
                color="primary"
                aria-label="like"
                component="label"
                onClick={() => handleFilterReaction("A")}
              >
                <ThumbUpIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="like"
                component="label"
                onClick={() => handleFilterReaction("B")}
              >
                <ThumbDownIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="like"
                component="label"
                onClick={() => handleFilterReaction("S")}
              >
                <VisibilityIcon />
              </IconButton>
            </Grid>

            <Box
              sx={{
                overflowY: "auto",
                maxHeight: 400,
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell></TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterReaction.length > 0 &&
                      filterReaction?.map((row, index) => (
                        <TableRow
                          key={row.row_id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell component="th" scope="row">
                            {selectListFilter(
                              props.companyList,
                              row.company,
                              "code",
                              "descr",
                              "Y",
                              sysLanguage
                            )}
                          </TableCell>
                          <TableCell>{row.alternate_name}</TableCell>
                          <TableCell>
                            {row.action === "A" ? (
                              <ThumbUpIcon color="primary" />
                            ) : row.action === "B" ? (
                              <ThumbDownIcon color="primary" />
                            ) : (
                              <VisibilityIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell>{formatTime(row.action_dttm)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </Box>
      </Modal>

      {/* Open Comments */}
      <Modal
        open={openComm}
        onClose={handleCloseComm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            sx={{ mb: 4 }}
            color="warning"
            variant="contained"
            onClick={() => setOpenComm(false)}
          >
            Close
          </Button>
          <div>
            <Typography
              sx={{ display: "block" }}
              component="span"
              variant="h6"
              color="text"
              gutterBottom
            >
              {rowData.title}
            </Typography>
            <TextField
              required
              id="comment"
              name="comment"
              label="Send Comments"
              multiline
              rows={3}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              size="small"
              sx={{ width: "90%" }}
              InputProps={{
                endAdornment: (
                  <>
                    {comment && comment.length > 2 && (
                      <IconButton
                        onClick={() => handleSend(comment)}
                        edge="start"
                      >
                        <SendIcon color="primary" fontSize="large" />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />

            <Box
              sx={{
                overflowY: "auto",
                maxHeight: 400,
              }}
            >
              {!!comments &&
                comments?.map((row, index) => {
                  return (
                    <div>
                      <ListItem
                        key={index}
                        alignItems="flex-start"
                        secondaryAction={
                          <Fragment>
                            <Translation
                              title="Translation"
                              variant="contained"
                              yesFunction={(code) =>
                                handleTranslate(
                                  code,
                                  row.text.toString(),
                                  row.comment_id
                                )
                              }
                            />

                            <>
                              {(admin ||
                                userData.user_id === row.addeduserid) && (
                                <Confirmation
                                  icon={true}
                                  buttonName="Delete"
                                  title="Delete Comment"
                                  context="Are you sure you want to delete this item?"
                                  color="error"
                                  variant="contained"
                                  yesFunction={() => handleDelete(row)}
                                />
                              )}
                            </>
                          </Fragment>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={row.avatar}
                            sx={{ width: 46, height: 46 }}
                          />
                        </ListItemAvatar>

                        <ListItemText
                          sx={{ mr: 5 }}
                          primary={row.addedBy}
                          secondary={
                            <React.Fragment>
                              <LinkItUrl>
                                <Typography
                                  sx={{ display: "block" }}
                                  component="span"
                                  variant="body2"
                                  color="text"
                                >
                                  {row.text}
                                </Typography>
                              </LinkItUrl>
                              {!loading && row.comment_id === targetComment && (
                                <Typography
                                  sx={{ display: "block", mt: 1 }}
                                  component="span"
                                  variant="body2"
                                  color="primary"
                                >
                                  {translatedText}
                                </Typography>
                              )}
                              <Typography
                                sx={{ display: "block", mt: 1 }}
                                component="span"
                                variant="caption"
                                color="text.secondary"
                              >
                                {formatTime(row.added_dttm)}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  );
                })}
            </Box>
          </div>
        </Box>
      </Modal>

      {/* Open Banner */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            sx={{ mb: 4 }}
            color="warning"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            {getLabel(sysLanguage, "9000", "9", "Back")}
          </Button>
          <BannerGrid
            awsKey={props.awsKey}
            bannerData={bannerData}
            handleOpen={handleOpen}
            userData={userData}
            open={open}
            handleTranslate={handleTranslate}
            translatedText={translatedText}
            sysLanguage={sysLanguage}
            companyList={props.companyList}
          />

          {bannerData.files && bannerData.files[0] !== null && (
            <div>
              {bannerData.files?.map((item, index) => (
                <div>
                  {item && item.split("||")[1].includes("image") ? (
                    <CardMedia
                      key={index}
                      component="img"
                      maxHeight="300"
                      image={props.awsKey.URL + item.split("||")[0]}
                      alt={item.title}
                      sx={{
                        padding: "1em 1em 0 1em",
                        objectFit: "contain",
                        border: 1,
                        mb: 1,
                      }}
                    />
                  ) : (
                    <Box sx={{ m: 2 }}>
                      <Link
                        target="_blank"
                        href={props.awsKey.URL + item.split("||")[0]}
                        variant="body2"
                      >
                        <FilePresentIcon />
                        {item?.split("||")[2].length > 40
                          ? item?.split("||")[2].substring(0, 40) +
                            " ....." +
                            item
                              ?.split("||")[2]
                              .substring(item?.split("||")[2].length - 10)
                          : item?.split("||")[2]}
                      </Link>
                    </Box>
                  )}
                </div>
              ))}
            </div>
          )}
        </Box>
      </Modal>
      <Paper elevation={3}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="column" sx={{ margin: 2 }}>
            <Typography
              variant="h5"
              align="left"
              sx={{ mb: 1, color: "#5c5b5b" }}
            >
              {getLabel(sysLanguage, "2000", "5", "Bulletin Board")}
            </Typography>
            {props.dashBannerList?.length === 0 && (
              <Typography variant="body" align="left" sx={{ m: 1 }}>
                {getLabel(sysLanguage, "2000", "21", "No Posting")}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" sx={{ margin: 2 }}>
            {props.dashBannerList?.length > 0 && (
              <>
                <IconButton
                  disabled={props.bulletinNbr === 0}
                  color="primary"
                  aria-label="left"
                  component="label"
                  onClick={() => props.setBulletinNbr(props.bulletinNbr - 1)}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  disabled={
                    props.bulletinNbr + 1 >= props.dashBannerList.length
                  }
                  color="primary"
                  aria-label="right"
                  component="label"
                  onClick={() => props.setBulletinNbr(props.bulletinNbr + 1)}
                >
                  <NavigateNextIcon />
                </IconButton>
                <IconButton disabled>
                  {props.bulletinNbr + 1}
                  {"/"}
                  {props.dashBannerList.length}
                </IconButton>
              </>
            )}
            <IconButton
              color="primary"
              aria-label="refresh"
              component="label"
              onClick={() => props.refreshBanner()}
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Stack>

        {props.dashBannerList.length > 0 && (
          // props.dashBannerList.map((item, index) => (
          <Box key={props.bulletinNbr}>
            <BannerGrid
              awsKey={props.awsKey}
              bannerData={props.dashBannerList[props.bulletinNbr]}
              handleOpen={handleOpen}
              userData={userData}
              isTrainee={isTrainee}
              sysLanguage={sysLanguage}
              companyList={props.companyList}
            />
            <Grid
              container
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mx: 2,
              }}
            >
              {(!isTrainee || (isTrainee && !!userData.company)) && (
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="like"
                    component="label"
                    onClick={() =>
                      handleReaction(
                        props.dashBannerList[props.bulletinNbr],
                        "A"
                      )
                    }
                  >
                    <>
                      {userReaction(
                        props.dashBannerList[props.bulletinNbr].row_id,
                        "A"
                      ) ? (
                        <ThumbUpIcon />
                      ) : (
                        <ThumbUpOffAltIcon />
                      )}
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ mx: "3px" }}
                      >
                        {countReaction(
                          props.dashBannerList[props.bulletinNbr],
                          "A"
                        )}
                      </Typography>
                    </>
                  </IconButton>

                  <IconButton
                    color="primary"
                    aria-label="dislike"
                    component="label"
                    onClick={() =>
                      handleReaction(
                        props.dashBannerList[props.bulletinNbr],
                        "B"
                      )
                    }
                  >
                    <>
                      {userReaction(
                        props.dashBannerList[props.bulletinNbr].row_id,
                        "B"
                      ) ? (
                        <ThumbDownIcon />
                      ) : (
                        <ThumbDownOffAltIcon />
                      )}
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ mx: "3px" }}
                      >
                        {countReaction(
                          props.dashBannerList[props.bulletinNbr],
                          "B"
                        )}
                      </Typography>
                    </>
                  </IconButton>
                  {!isTrainee && (
                    <IconButton
                      color="primary"
                      aria-label="seen"
                      component="label"
                      onClick={() =>
                        handleOpenSeen(props.dashBannerList[props.bulletinNbr])
                      }
                    >
                      <>
                        <VisibilityIcon />
                        <Typography
                          variant="subtitle1"
                          align="left"
                          sx={{ mx: "3px" }}
                        >
                          {props.dashBannerList[props.bulletinNbr]?.seen_count}
                        </Typography>
                      </>
                    </IconButton>
                  )}
                </Box>
              )}
              <Box>
                {(!isTrainee || (isTrainee && !!userData.company)) && (
                  <Fragment>
                    {props.dashBannerList[props.bulletinNbr].comments_on && (
                      <IconButton
                        color="primary"
                        aria-label="comment"
                        component="label"
                        onClick={() =>
                          handleOpenComm(
                            props.dashBannerList[props.bulletinNbr]
                          )
                        }
                      >
                        <>
                          <CommentIcon />
                          <Typography
                            variant="subtitle1"
                            align="left"
                            sx={{ mx: "3px" }}
                          >
                            {
                              props.dashBannerList[props.bulletinNbr]
                                .comment_count
                            }
                          </Typography>
                        </>
                      </IconButton>
                    )}
                    {props.bannerOfficer && (
                      <IconButton
                        color="primary"
                        aria-label="comment"
                        component="label"
                        onClick={() =>
                          jumpToNavigate(
                            props.dashBannerList[props.bulletinNbr].row_id
                          )
                        }
                      >
                        <ModeEditIcon />
                      </IconButton>
                    )}
                  </Fragment>
                )}
              </Box>
            </Grid>
          </Box>
        )}
      </Paper>
    </Fragment>
  );
};

export default BannerDash;
