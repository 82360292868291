import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Link from "@mui/material/Link";

import Confirmation from "../components/Confirmation";

import { Formik, Form } from "formik";

import * as ticketActions from "../../store/actions/ticket";

import { getOptions } from "../../utils/selectOptions";
import formatTime from "../../utils/formatTime";

import getLabel from "../../utils/getLabel";

const Details = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);

  const [groupId, setGroupId] = useState(
    props.groupDetail[0]?.group_id.toString().padStart(7, "0")
  );

  useEffect(() => {
    if (props.modeAdd) {
      setLoading(true);
      setGroupId("New");
      props.setCompany({});
      props.setGrpMember([]);
      setLoading(false);
    }
  }, [loading]);

  const handleDelete = async (formData) => {
    try {
      setLoading(true);
      await dispatch(ticketActions.deleteGroup(formData));
      setLoading(false);

      navigate(`/manageGroup`, {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (formData) => {
    try {
      setLoadingSave(true);
      const output = await dispatch(ticketActions.updateGroup(formData));
      setGroupId(output[0].group_id.toString().padStart(7, "0"));
      setLoadingSave(false);
      props.setModeAdd(false);

      setLoadingAlert(true);
      setTimeout(() => {
        setLoadingAlert(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const jumpToNavigate = async (ticket_id) => {
    console.log("jumpToNavigate: ", ticket_id);
    const ecryptedTicketId = btoa(ticket_id?.toString().padStart(7, "0"));
    navigate(`/updateTicket/${ecryptedTicketId}`, {});
  };

  return (
    <div>
      <Formik
        initialValues={{
          group_name: props.modeAdd ? "" : props.groupDetail[0]?.group_name,
          comments: props.modeAdd ? "" : props.groupDetail[0]?.comments,
          active_flag: props.modeAdd ? true : props.groupDetail[0]?.active_flag,
        }}
        onSubmit={(values) => {
          const members = [];

          !!props.grpMember &&
            props.grpMember.map((item) => {
              members.push(item.value);
            });

          const addedData = {
            ...values,
            group_id: groupId,
            group_members: members,
            company: props.company.value,
            mode: props.modeAdd ? "add" : "edit",
          };
          handleSave(addedData);
        }}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Divider textAlign="left" sx={{ m: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "3000", "75", "Group Details")}
                  </Typography>
                </Divider>
                <TextField
                  disabled
                  id="group_id"
                  name="group_id"
                  label={getLabel(sysLanguage, "3000", "64", "Group ID")}
                  value={groupId}
                  size="small"
                />
                <TextField
                  disabled={!props.canEdit}
                  required
                  fullWidth
                  id="group_name"
                  name="group_name"
                  label={
                    getLabel(sysLanguage, "3000", "65", "Group Name") +
                    " " +
                    values.group_name?.length +
                    "/50"
                  }
                  value={values.group_name}
                  onChange={handleChange("group_name")}
                  size="small"
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <Autocomplete
                  disabled={!props.canEdit}
                  id="company"
                  name="company"
                  value={props.company}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => {
                    props.setCompany(data);
                    props.setGrpMember([]);
                  }}
                  size="small"
                  options={getOptions(sysLanguage, props.companyList)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={getLabel(sysLanguage, "3000", "17", "Company")}
                    />
                  )}
                />
                <Autocomplete
                  disabled={!props.canEdit}
                  multiple
                  id="group_member"
                  name="group_member"
                  value={props.grpMember}
                  getOptionLabel={(option) => option?.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  onChange={(e, data) => props.setGrpMember(data)}
                  size="small"
                  options={
                    !!props.company
                      ? !!props.traineeName &&
                        props.traineeName?.filter(
                          (item) => item.company === props.company?.value
                        )
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={props.grpMember?.length > 0 ? false : true}
                      label={getLabel(
                        sysLanguage,
                        "3000",
                        "69",
                        "Group Members"
                      )}
                    />
                  )}
                />
                <TextField
                  disabled={!props.canEdit}
                  fullWidth
                  required
                  multiline
                  minRows={3}
                  id="comments"
                  name="comments"
                  label={getLabel(sysLanguage, "3000", "70", "Comments")}
                  value={values.comments}
                  onChange={handleChange("comments")}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Divider textAlign="left" sx={{ m: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "3000", "76", "Active Tickets")}
                  </Typography>
                </Divider>
                <TableContainer
                  component={Paper}
                  sx={{
                    overflowY: "auto",
                    maxHeight: 600,
                  }}
                >
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>
                          {getLabel(sysLanguage, "3000", "1", "Ticket ID")}
                        </TableCell>
                        <TableCell>
                          {getLabel(sysLanguage, "3000", "14", "Title")}
                        </TableCell>
                        <TableCell>
                          {getLabel(sysLanguage, "3000", "19", "Assigned To")}
                        </TableCell>
                        <TableCell>
                          {getLabel(sysLanguage, "3000", "58", "Last Update")}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.relatedTicket?.length > 0 &&
                        props.relatedTicket
                          ?.filter((item) => item.status !== "RSV")
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{index + 1}</TableCell>
                              <TableCell component="th" scope="row">
                                <Link
                                  component="button"
                                  underline="always"
                                  onClick={() => jumpToNavigate(row.ticket_id)}
                                >
                                  {row.ticket_id?.toString().padStart(7, "0")}
                                </Link>
                              </TableCell>
                              <TableCell>{row.title}</TableCell>
                              <TableCell>{row.alternate_name}</TableCell>
                              <TableCell>
                                {formatTime(row.last_update_dttm)}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignContent: "flex-end",
                mt: 1,
              }}
            >
              <LoadingButton
                disabled={!props.canEdit}
                type="submit"
                loading={loadingSave}
                variant="contained"
                color="primary"
                sx={{ m: 1 }}
              >
                {getLabel(sysLanguage, "9000", "6", "Save")}
              </LoadingButton>
              <Button
                sx={{ m: 1 }}
                color="warning"
                variant="contained"
                onClick={() => navigate(`/manageGroup`, {})}
              >
                {getLabel(sysLanguage, "9000", "7", "Cancel")}
              </Button>
              {props.relatedTicket?.length === 0 && !props.modeAdd && (
                <Grid container justifyContent="flex-end">
                  <Confirmation
                    buttonName={getLabel(
                      sysLanguage,
                      "3000",
                      "71",
                      "Delete Group"
                    )}
                    title={getLabel(sysLanguage, "3000", "71", "Delete Group")}
                    context={getLabel(
                      sysLanguage,
                      "9000",
                      "5",
                      "Are you sure you want to delete this item?"
                    )}
                    color="error"
                    variant="outlined"
                    yesFunction={() =>
                      handleDelete({
                        mode: "delete",
                        group_id: groupId,
                      })
                    }
                  />
                </Grid>
              )}
            </Box>
          </Form>
        )}
      </Formik>
      {loadingAlert && (
        <div>
          <Alert severity="success">
            {getLabel(sysLanguage, "9000", "10", "Changes Saved!")}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Details;
