import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

import Stack from "@mui/material/Stack";

import * as authActions from "../../store/actions/auth";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  email_id: Yup.string().email("Invalid email").required("Required"),
});

const ErrorText = (props) => {
  return (
    <Typography style={{ color: "#FF0000" }} variant="caption">
      {props.label}
    </Typography>
  );
};

const ContactUs = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [stepTwo, setStepTwo] = useState(false);

  const handleSend = async (data) => {
    setLoading(true);
    try {
      await dispatch(authActions.contactUs(data));

      setError("");
      setStepTwo(true);

      setLoading(false);

      setSuccess(true);

      setTimeout(() => {
        props.handleClose();
      }, 5000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(String(error));
    }
  };

  return (
    <div>
      {!!error && (
        <Alert severity="error" sx={{ m: 1 }}>
          {error}
        </Alert>
      )}
      {!!success && (
        <Alert severity="success" sx={{ m: 1 }}>
          "Your message has been sent."
        </Alert>
      )}
      <DialogTitle>Contact Us</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          Talk to Us. We'd like to hear from you.
        </DialogContentText>

        <Formik
          initialValues={{
            email_id: "",
            message: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            handleSend(values);
          }}
        >
          {({ handleChange, values, setFieldValue, errors, touched }) => (
            <Form>
              <Stack spacing={2}>
                <div>
                  <TextField
                    required
                    disabled={stepTwo}
                    autoComplete="off"
                    id="email_id"
                    label="Your Registered Email Address"
                    type="email_id"
                    fullWidth
                    variant="outlined"
                    value={values.email_id}
                    onChange={handleChange("email_id")}
                  />
                  {errors.email_id && touched.email_id ? (
                    <ErrorText label={errors.email_id} />
                  ) : null}
                </div>
                <div>
                  <TextField
                    required
                    disabled={stepTwo}
                    multiline
                    rows={4}
                    autoComplete="off"
                    id="message"
                    label="What is your concern?"
                    type="message"
                    fullWidth
                    variant="outlined"
                    value={values.message}
                    onChange={handleChange("message")}
                  />
                  {errors.message && touched.message ? (
                    <ErrorText label={errors.message} />
                  ) : null}
                </div>
                <LoadingButton
                  disabled={success}
                  type="submit"
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ p: 1 }}
                >
                  Send my Message
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
      </DialogActions>
    </div>
  );
};

ContactUs.propTypes = {};

export default ContactUs;
