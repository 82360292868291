function formatTime(date) {
  if (date) {
    return new Date(date).toLocaleString("en-ZA", {
      timeZone: "Japan",
    });
  } else {
    return null;
  }
}

export default formatTime;
