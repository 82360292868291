import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form } from "formik";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import getLabel from "../../utils/getLabel";

import * as configActions from "../../store/actions/config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "25%",
  maxWidth: 800,
  minWidth: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const MultiName = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const [loadingSave, setLoadingSave] = useState(false);

  const handleClose = () => props.setOpen(false);

  const handleSave = async (data) => {
    try {
      setLoadingSave(true);

      const formData = {
        lang_code: sysLanguage[0].lang_code,
        msgset: props.msgset,
        msgnbr: props.msgnbr,
        label: data.descr.trim() || props.compLabel,
      };
      await dispatch(configActions.updateLabel(formData));
      await dispatch(configActions.getLabels(sysLanguage[0].lang_code));

      setLoadingSave(false);
      props.setOpen(false);
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
      props.setOpen(false);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography id="modal-modal-title" variant="h6">
              {getLabel(
                sysLanguage,
                props.labelSet,
                props.labelNbr,
                "Description"
              )}
            </Typography>
            <Button
              sx={{ mb: 1, height: "30px" }}
              color="warning"
              variant="contained"
              onClick={() => handleClose()}
            >
              {getLabel(sysLanguage, "2000", "32", "Close")}
            </Button>
          </Box>
          <Divider />
          <Formik
            initialValues={{
              descr: props.compLabel,
            }}
            // validationSchema={TextSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
          >
            {({ handleChange, values, setFieldValue, errors, touched }) => (
              <Form>
                <TextField
                  defaultValue={props.compLabel}
                  margin="normal"
                  required
                  fullWidth
                  id="descr"
                  name="descr"
                  label={getLabel(
                    sysLanguage,
                    props.labelSet,
                    props.labelNbr,
                    "Description"
                  )}
                  autoFocus
                  size="small"
                  onChange={handleChange("descr")}
                  autoComplete="off"
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    type="submit"
                    loading={loadingSave}
                    variant="contained"
                    color="primary"
                  >
                    {getLabel(sysLanguage, "9000", "6", "Save")}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Fragment>
      </Box>
    </Modal>
  );
};

export default MultiName;
