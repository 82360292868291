import React from "react";

import Box from "@mui/material/Box";

const PrivacyandTerms = () => {
  return (
    <Box sx={{ m: 2 }}>
      <strong className="large">Privacy Policy</strong> <p className="my-3"></p>
      <p className="my-3">
        HoRenSo Plus Team built the HoRenSo Plus app. You agree on the Privacy
        Policy and Terms and Conditions by using this service.
      </p>
      <p className="my-3">
        Information will be collected in a manner consistent with all relevant
        laws and regulations, and necessary measures will be taken to ensure
        that such personal information ("Personal Information, etc.") is correct
        and up-to-date.
      </p>
      <p className="my-3">
        Personal information, etc. will not be used beyond the scope of the
        purpose of utilization. The individual number, in particular, will only
        be used in accordance with relevant laws and regulations. Personal
        information, etc. Will not be disclosed to third parties without a
        consent from the individual, unless there are justifiable grounds, such
        as if a disclosure is required under laws and regulations.
      </p>
      <p className="my-3">
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which are accessible at HoRenSo Plus unless
        otherwise defined in this Privacy Policy.
      </p>
      <p className="my-3">
        <strong>Information Collection and Use</strong>
      </p>
      <p>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information. The
        information that we request will be retained by us and used as described
        in this privacy policy.
      </p>
      <p className="my-3">
        The app does not use third-party services that may collect information
        used to identify you.
      </p>
      <p className="my-3">
        <strong>Cookies</strong>
      </p>{" "}
      <p className="my-3">
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>{" "}
      <p className="my-3">
        This Service does not use these “cookies” explicitly.
      </p>{" "}
      <p className="my-3">
        <strong>Service Providers</strong>
      </p>{" "}
      <p className="my-3">
        <strong>Security</strong>
      </p>{" "}
      <p className="my-3">
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>{" "}
      <p className="my-3">
        <strong>Links to Other Sites</strong>
      </p>{" "}
      <p className="my-3">
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>{" "}
      <p className="my-3">
        <strong>Children’s Privacy</strong>
      </p>
      <p className="my-3">
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13 years of age. In the case we discover that a child under 13 has
        provided us with personal information, we immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to do the necessary actions.
      </p>
      <p className="my-3">
        <strong>Changes to This Privacy Policy</strong>
      </p>{" "}
      <p className="my-3">
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </p>{" "}
      <p className="my-3">This policy is effective as of 2023-01-01</p>{" "}
      <p className="my-3">
        <strong>Contact Us</strong>
      </p>{" "}
      <p className="my-3">
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact your representative.
      </p>
      <strong className="large">Terms & Conditions</strong>
      <p className="my-3">
        By downloading or using the app, these terms will automatically apply to
        you – you should make sure therefore that you read them carefully before
        using the app. You’re not allowed to copy or modify the app, any part of
        the app, or our trademarks in any way. You’re not allowed to attempt to
        extract the source code of the app, and you also shouldn’t try to
        translate the app into other languages or make derivative versions. The
        app itself, and all the trademarks, copyright, database rights, and
        other intellectual property rights related to it, still belong to
        HoRenSo Plus Team.
      </p>
      <p className="my-3">
        HoRenSo Plus Team is committed to ensuring that the app is as useful and
        efficient as possible. For that reason, we reserve the right to make
        changes to the app or to charge for its services, at any time and for
        any reason. We will never charge you for the app or its services without
        making it very clear to you exactly what you’re paying for.
      </p>
      <p className="my-3">
        The HoRenSo Plus app stores and processes personal data that you have
        provided to us, to provide our Service. It’s your responsibility to keep
        your phone and access to the app secure. We therefore recommend that you
        do not jailbreak or root your phone, which is the process of removing
        software restrictions and limitations imposed by the official operating
        system of your device. It could make your phone vulnerable to
        malware/viruses/malicious programs, compromise your phone’s security
        features and it could mean that the HoRenSo Plus app won’t work properly
        or at all.
      </p>
      <p className="my-3">
        The app does use third-party services that declare their Terms and
        Conditions.
      </p>
      <p className="my-3">
        You should be aware that there are certain things that HoRenSo Plus Team
        will not take responsibility for. Certain functions of the app will
        require the app to have an active internet connection. The connection
        can be Wi-Fi or provided by your mobile network provider, but HoRenSo
        Plus Team cannot take responsibility for the app not working at full
        functionality if you don’t have access to Wi-Fi, and you don’t have any
        of your data allowance left.
      </p>
      <p className="my-3">
        If you’re using the app outside of an area with Wi-Fi, you should
        remember that the terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the app, or other third-party charges. In using the app,
        you’re accepting responsibility for any such charges, including roaming
        data charges if you use the app outside of your home territory (i.e.
        region or country) without turning off data roaming. If you are not the
        bill payer for the device on which you’re using the app, please be aware
        that we assume that you have received permission from the bill payer for
        using the app.
      </p>{" "}
      <p className="my-3">
        Along the same lines, HoRenSo Plus Team cannot always take
        responsibility for the way you use the app i.e. You need to make sure
        that your device stays charged – if it runs out of battery and you can’t
        turn it on to avail the Service, HoRenSo Plus Team cannot accept
        responsibility.
      </p>{" "}
      <p className="my-3">
        With respect to HoRenSo Plus Team’s responsibility for your use of the
        app, when you’re using the app, it’s important to bear in mind that
        although we endeavor to ensure that it is updated and correct at all
        times, we do rely on third parties to provide information to us so that
        we can make it available to you. HoRenSo Plus Team accepts no liability
        for any loss, direct or indirect, you experience as a result of relying
        wholly on this functionality of the app.
      </p>{" "}
      <p className="my-3">
        At some point, we may wish to update the app. The app is currently
        available on Android &amp; iOS – the requirements for the both
        systems(and for any additional systems we decide to extend the
        availability of the app to) may change, and you’ll need to download the
        updates if you want to keep using the app. HoRenSo Plus Team does not
        promise that it will always update the app so that it is relevant to you
        and/or works with the Android &amp; iOS version that you have installed
        on your device. However, you promise to always accept updates to the
        application when offered to you, We may also wish to stop providing the
        app, and may terminate use of it at any time without giving notice of
        termination to you. Unless we tell you otherwise, upon any termination,
        (a) the rights and licenses granted to you in these terms will end; (b)
        you must stop using the app, and (if needed) delete it from your device.
      </p>{" "}
      <p className="my-3">
        <strong>Changes to This Terms and Conditions</strong>
      </p>{" "}
      <p className="my-3">
        We may update our Terms and Conditions from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms and Conditions on this page.
      </p>{" "}
      <p className="my-3">
        These terms and conditions are effective as of 2023-01-01
      </p>{" "}
      <p className="my-3">
        <strong>Contact Us</strong>
      </p>{" "}
      <p className="my-3">
        If you have any questions or suggestions about our Terms and Conditions,
        do not hesitate to contact your representative.
      </p>
    </Box>
  );
};

export default PrivacyandTerms;
