import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";

import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Formik, Form } from "formik";

import formatDate from "../../utils/formatDate";
import { getOptions, selectListFilter } from "../../utils/selectOptions";
import getLabel from "../../utils/getLabel";

import Confirmation from "../components/Confirmation";

import * as profileActions from "../../store/actions/profile";
import * as configActions from "../../store/actions/config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ErrorAlert = (props) => {
  return <Alert severity="error"> {props.label}</Alert>;
};

const WorkInfo = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const trainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const oneProfile = useSelector((state) => state.profile.searchOneProfile);

  const jobProfile = useSelector((state) => state.profile.jobProfile);

  const [addMode, setAddMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const [sendingOrgOption, setSendingOrgOption] = useState([]);

  const [company, setCompany] = useState({});
  const [sendOrg, setSendOrg] = useState({});
  const [occupation, setOccupation] = useState({});

  const [startDate, setStartDt] = useState(null);
  const [endDate, setEndDt] = useState(null);
  const [tioDate, setTIODate] = useState(null);

  const [open, setOpen] = useState(false);

  const [startDtErr, setStartDtErr] = useState(false);

  const [rowDetail, setRowDetail] = useState({
    business_unit: "",
    user_id: null,
    job_id: null,
    start_dt: null,
    end_dt: null,
    sending_org: "",
    company: "",
    company_address: "",
    occupation: "",
    comment: "",
  });

  useEffect(() => {
    const fetchAPI = async () => {
      setLoading(true);
      const output = await dispatch(
        configActions.getSendingOrg({
          country_origin: oneProfile.country_origin,
        })
      );

      setSendingOrgOption(output);
      setLoading(false);
    };

    fetchAPI();
  }, [dispatch]);

  const handleEdit = (rowData) => {
    setAddMode(false);
    setStartDtErr(false);

    setRowDetail({
      business_unit: rowData.business_unit,
      user_id: rowData.user_id,
      job_id: rowData.job_id,
      batch_no: rowData.batch_no,
      sending_org: rowData.sending_org,
      company: rowData.company,
      company_address: rowData.company_address,
      occupation: rowData.occupation,
      comment: rowData.comment,
    });

    setStartDt(rowData.start_dt);
    setEndDt(rowData.end_dt);
    setTIODate(rowData.tio_date);

    setOpen(true);
  };

  const handleAdd = (rowData) => {
    setAddMode(true);
    setStartDtErr(false);

    setRowDetail({
      business_unit: oneProfile.business_unit,
      user_id: oneProfile.user_id,
      job_id: "New",
      batch_no: "",
      sendOrg: "",
      company: "",
      company_address: "",
      occupation: "",
      comment: "",
    });

    setStartDt(new Date().toLocaleDateString());
    setEndDt(null);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = async (formData) => {
    //is Start Date unique?
    const isExist = jobProfile.filter(
      (item) =>
        new Date(item.start_dt).toLocaleDateString() ===
          new Date(startDate).toLocaleDateString() &&
        item.job_id !== formData.job_id
    );

    setStartDtErr(isExist.length > 0);

    if (isExist.length > 0) {
      throw Error;
    }

    try {
      setLoading(true);
      if (addMode) {
        await dispatch(profileActions.addJob(formData));
      } else {
        await dispatch(profileActions.updateJob(formData));
      }
      setLoading(false);

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    try {
      await dispatch(profileActions.deleteJob(formData));
      setOpen(false);
    } catch (error) {}
  };

  const rows = [];
  jobProfile &&
    jobProfile.map((item) => {
      const companyDescr = selectListFilter(
        props.companyList,
        item.company,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      const occupationDescr = selectListFilter(
        props.occupationList,
        item.occupation,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      const sendingOrgDescr = selectListFilter(
        sendingOrgOption,
        item.sending_org,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      rows.push({
        ...item,
        companyDescr: companyDescr,
        occupationDescr: occupationDescr,
        sendingOrgDescr: sendingOrgDescr,
      });
    });

  useEffect(() => {
    let selRow = [];

    selRow = props.companyList.filter(
      (item) => item.code === rowDetail.company
    );
    setCompany({
      value: rowDetail.company,
      label:
        selRow.length > 0
          ? getLabel(
              sysLanguage,
              selRow[0].msgset,
              selRow[0].msgnbr,
              selRow[0].descr
            )
          : "",
    });

    selRow = sendingOrgOption.filter(
      (item) => item.code === rowDetail.sending_org
    );

    setSendOrg({
      value: rowDetail.sending_org,
      label:
        selRow.length > 0
          ? getLabel(
              sysLanguage,
              selRow[0].msgset,
              selRow[0].msgnbr,
              selRow[0].descr
            )
          : "",
    });

    selRow = props.occupationList.filter(
      (item) => item.code === rowDetail.occupation
    );
    setOccupation({
      value: rowDetail.occupation,
      label:
        selRow.length > 0
          ? getLabel(
              sysLanguage,
              selRow[0].msgset,
              selRow[0].msgnbr,
              selRow[0].descr
            )
          : "",
    });
  }, [rowDetail]);

  const handleStartDt = (newValue) => {
    setStartDt(newValue);
  };

  const handleTIODate = (newValue) => {
    setTIODate(newValue);
  };

  const handleEndDt = (newValue) => {
    setEndDt(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      {!loading ? (
        <Stack spacing={2}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <Box sx={style}>
                <Stack spacing={2}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {getLabel(sysLanguage, "4000", "34", " Work Information")}
                  </Typography>

                  <Formik
                    initialValues={{
                      business_unit: rowDetail.business_unit,
                      user_id: rowDetail.user_id,
                      job_id: rowDetail.job_id,
                      company_address: rowDetail.company_address,
                      batch_no: rowDetail.batch_no,
                      comment: rowDetail.comment,
                    }}
                    // validationSchema={AddSchema}
                    onSubmit={(values) => {
                      const addedData = {
                        ...values,
                        sendOrg: sendOrg.value,
                        company: company.value,
                        occupation: occupation.value,
                        start_dt: new Date(startDate).toLocaleDateString(),
                        end_dt: endDate
                          ? new Date(endDate).toLocaleDateString()
                          : null,
                        tio_date: tioDate
                          ? new Date(tioDate).toLocaleDateString()
                          : null,
                      };
                      handleSave(addedData);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <Stack spacing={2}>
                          {startDtErr ? (
                            <ErrorAlert
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "71",
                                "Start of Employment already exists for a different job."
                              )}
                            />
                          ) : null}
                          <TextField
                            disabled
                            id="job_id"
                            name="job_id"
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "48",
                              "Job ID"
                            )}
                            value={values.job_id}
                            size="small"
                            sx={{ width: 100 }}
                          />
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Autocomplete
                              disablePortal
                              id="sending_org"
                              name="sending_org"
                              value={sendOrg}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={(e, data) => setSendOrg(data)}
                              options={getOptions(
                                sysLanguage,
                                sendingOrgOption
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "69",
                                    "Sending Agency"
                                  )}
                                />
                              )}
                              size="small"
                              sx={{ width: 500 }}
                            />
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "39",
                                  "TIO Date"
                                )}
                                inputFormat="yyyy/MM/dd"
                                value={tioDate}
                                onChange={handleTIODate}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    sx={{ width: 250 }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                id="start_dt"
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "40",
                                  "Start of Employment"
                                )}
                                inputFormat="yyyy/MM/dd"
                                value={startDate}
                                onChange={handleStartDt}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    size="small"
                                    {...params}
                                    sx={{ width: 250 }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "41",
                                  "End of Employment"
                                )}
                                inputFormat="yyyy/MM/dd"
                                value={endDate}
                                onChange={handleEndDt}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    sx={{ width: 250 }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Autocomplete
                              disablePortal
                              id="company"
                              name="company"
                              value={company}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={(e, data) => setCompany(data)}
                              options={getOptions(
                                sysLanguage,
                                props.companyList
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "14",
                                    "Company"
                                  )}
                                />
                              )}
                              size="small"
                              sx={{ width: 400 }}
                            />
                            <TextField
                              id="batch_no"
                              name="batch_no"
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "2",
                                "Batch No."
                              )}
                              value={values.batch_no}
                              onChange={handleChange("batch_no")}
                              size="small"
                              sx={{ width: 100 }}
                            />
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <TextField
                              id="company_address"
                              name="company_address"
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "70",
                                "Company Address"
                              )}
                              value={values.company_address}
                              onChange={handleChange("company_address")}
                              size="small"
                              sx={{ width: 500 }}
                            />
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Autocomplete
                              id="occupation"
                              name="occupation"
                              value={occupation}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={(e, data) => setOccupation(data)}
                              options={getOptions(
                                sysLanguage,
                                props.occupationList
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "38",
                                    "Occupation"
                                  )}
                                />
                              )}
                              size="small"
                              sx={{ width: 500 }}
                            />
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <TextField
                              id="comment"
                              name="comment"
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "61",
                                "Comment"
                              )}
                              value={values.comment}
                              onChange={handleChange("comment")}
                              size="small"
                              multiline
                              rows={2}
                              sx={{ width: 500 }}
                            />
                          </Grid>
                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "flex-end",
                              mt: 3,
                            }}
                          >
                            <LoadingButton
                              disabled={!props.canEdit}
                              type="submit"
                              loading={loading}
                              variant="contained"
                              color="primary"
                              sx={{ m: 1 }}
                            >
                              {getLabel(sysLanguage, "9000", "6", "Save")}
                            </LoadingButton>
                            <Button
                              sx={{ m: 1, width: 150 }}
                              color="warning"
                              variant="contained"
                              onClick={() => setOpen(false)}
                            >
                              {getLabel(sysLanguage, "9000", "7", "Cancel")}
                            </Button>

                            {!addMode && (
                              <>
                                <Grid container justifyContent="flex-end">
                                  <Confirmation
                                    disabled={!props.canEdit}
                                    buttonName={getLabel(
                                      sysLanguage,
                                      "4000",
                                      "49",
                                      "Delete"
                                    )}
                                    title={getLabel(
                                      sysLanguage,
                                      "4000",
                                      "49",
                                      "Delete"
                                    )}
                                    context={getLabel(
                                      sysLanguage,
                                      "9000",
                                      "5",
                                      "Are you sure you want to delete this item?"
                                    )}
                                    color="error"
                                    variant="outlined"
                                    yesFunction={() => handleDelete(values)}
                                  />
                                </Grid>
                              </>
                            )}
                          </Box>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Stack>
              </Box>
            </Fade>
          </Modal>
          <Grid container justifyContent="flex-start">
            <Button
              disabled={!props.canEdit}
              color="primary"
              variant="contained"
              onClick={handleAdd}
            >
              {getLabel(sysLanguage, "4000", "35", "Add New Job")}
            </Button>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                    {getLabel(sysLanguage, "4000", "36", "Effective Date")}
                  </TableCell> */}
                  <TableCell align="left">
                    {getLabel(sysLanguage, "4000", "37", "Sending Org")}
                  </TableCell>
                  <TableCell align="left">
                    {getLabel(sysLanguage, "4000", "14", "Company")}
                  </TableCell>
                  <TableCell align="left">
                    {getLabel(sysLanguage, "4000", "38", "Occupation")}
                  </TableCell>
                  <TableCell align="right">
                    {getLabel(sysLanguage, "4000", "39", "TIO Date")}
                  </TableCell>
                  <TableCell align="right">
                    {getLabel(sysLanguage, "4000", "40", "Start of Employment")}
                  </TableCell>
                  <TableCell align="right">
                    {getLabel(sysLanguage, "4000", "41", "End of Employment")}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.effdt}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {/* <TableCell component="th" scope="row">
                      {formatDate(row.effdt)}
                    </TableCell> */}
                    <TableCell align="left">{row.sendingOrgDescr}</TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {row.companyDescr}
                    </TableCell>
                    <TableCell align="left">{row.occupationDescr}</TableCell>
                    <TableCell align="right">
                      {formatDate(row.tio_date)}
                    </TableCell>
                    <TableCell align="right">
                      {formatDate(row.start_dt)}
                    </TableCell>
                    <TableCell align="right">
                      {formatDate(row.end_dt)}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton color="primary" sx={{ p: 0.5 }}>
                        <ModeEditIcon onClick={() => handleEdit(row)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              color="warning"
              variant="contained"
              onClick={() =>
                trainee
                  ? navigate(`/`, { replace: true })
                  : navigate(`/traineeProfile`, { replace: true })
              }
            >
              {getLabel(sysLanguage, "9000", "9", "Back")}
            </Button>
          </Grid>
        </Stack>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

export default WorkInfo;
