import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import GridOnIcon from "@mui/icons-material/GridOn";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Formik, Form } from "formik";

import AddTicket from "./AddTicket";
import TableOne from "../components/TableOne";

import formatDate from "../../utils/formatDate";

import getLabel from "../../utils/getLabel";

import {
  getOptions,
  ticketStatus,
  ticketPriority,
  selectListFilter,
} from "../../utils/selectOptions";

import * as ticketActions from "../../store/actions/ticket";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SearchTicket = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const searchResultTicket = useSelector((state) => state.ticket.searchTicket);

  const [stat, setStat] = useState([]);
  const [company, setCompany] = useState(null);
  const [trainee, setTrainee] = useState(null);
  const [officer, setOfficer] = useState(null);
  const [issue, setIssue] = useState(null);
  const [pri, setPri] = useState(null);

  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState([]);
  const [group, setGroup] = useState({ value: false, label: "No" });
  const [isGroup, setIsGroup] = useState(false);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [issueDtFrom, setIssueDtFrom] = useState(null);
  const [issueDtTo, setIssueDtTo] = useState(new Date());

  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [searchField, setSearchField] = useState({ value: "", label: "" });

  let rows = [];

  const noFunction = (value) => {
    return value;
  };

  const headCells = [
    {
      id: "ticket_id",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      link: true,
      label: getLabel(sysLanguage, "3000", "1", "Ticket ID"),
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "14", "Title"),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "15", "Issue"),
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "16", "Status"),
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "17", "Company"),
    },
    {
      id: "fullName",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      linkVal: "owner_id",
      link: isGroup ? false : true,
      label: isGroup
        ? getLabel(sysLanguage, "3000", "65", "Group Name")
        : getLabel(sysLanguage, "3000", "18", "Name"),
    },
    {
      id: "alternate_name",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "19", "Assigned To"),
    },
    {
      id: "open_dt",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "3000", "20", "Opened Date"),
    },
    {
      id: "closed_dt",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "3000", "21", "Closed Date"),
    },
  ];

  const handleSearch = async (data) => {
    setLoading(true);
    try {
      rows = [];
      setIsGroup(data.group_ticket);
      await dispatch(ticketActions.searchTicket(data));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const jumpToProfile = async (user_id) => {
    console.log("jumpToProfile: ", user_id);
    const ecryptedTicketId = btoa(user_id?.toString().padStart(7, "0"));
    navigate(`/MainProfile/${ecryptedTicketId}`, {});
  };

  const jumpToNavigate = async (ticket_id) => {
    console.log("jumpToNavigate: ", ticket_id);
    const ecryptedTicketId = btoa(ticket_id?.toString().padStart(7, "0"));
    navigate(`/updateTicket/${ecryptedTicketId}`, {});
  };

  let csvHeader = [
    { label: "Ticket Number", key: "ticket_id" },
    { label: "Title/Summary", key: "title" },
    { label: "Description", key: "description" },
    { label: "Issue Classification", key: "type" },
    { label: "Company", key: "company" },
    { label: "Trainee / Group Name", key: "fullName" },
    { label: "Status", key: "status" },
    { label: "Assigned To", key: "alternate_name" },
    { label: "Submit Date", key: "open_dt" },
    { label: "Last Updated by", key: "last_update_dttm" },
    { label: "Resolution", key: "resolution" },
    { label: "Closed Date", key: "closed_dt" },
  ];

  let csvData = [];

  searchResultTicket &&
    searchResultTicket.map((item) => {
      const type = selectListFilter(
        props.issueList,
        item.type,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      const companyDescr = selectListFilter(
        props.companyList,
        item.company,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      const status = selectListFilter(
        ticketStatus(sysLanguage),
        item.status,
        "value",
        "label",
        "Y",
        sysLanguage
      );

      rows.push({ ...item, type: type, company: companyDescr, status: status });

      const forCSV = {
        ticket_id: item.ticket_id,
        title: item.title,
        description: item.description,
        type: type,
        company: companyDescr,
        fullName: item.fullName,
        status: status,
        alternate_name: item.alternate_name,
        open_dt: formatDate(item.open_dt),
        last_update_dttm: formatDate(item.last_update_dttm),
        resolution: item.resolution,
        closed_dt: formatDate(item.closed_dt),
      };

      csvData.push(forCSV);
    });

  // const SearchTextField = (props) => {
  //   let fieldName;

  //   switch (searchField) {
  //     case 1:
  //       fieldName = "ticket_id";
  //       break;

  //     default:
  //       break;
  //   }

  //   return (
  //     <TextField
  //       id={fieldName}
  //       name={fieldName}
  //       // label={getLabel(sysLanguage, msgset, msgnbr, "Search")}
  //       // value={props.values.[fieldName]}
  //       // onChange={props.handleChange(fieldName)}
  //       size="small"
  //       sx={{ width: 200 }}
  //     />
  //   );
  // };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack spacing={2}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {getLabel(sysLanguage, "3000", "11", "Create Ticket")}
              </Typography>
              <AddTicket
                handleClose={handleClose}
                companyList={props.companyList}
                issueList={props.issueList}
                traineeName={props.traineeName}
                officerName={props.officerName}
                userData={userData}
                isTrainee={isTrainee}
              />
            </Stack>
          </Box>
        </Fade>
      </Modal>
      <Box sx={{ p: 1 }}>
        <Formik
          initialValues={{
            ticket_id: "",
            title: "",
          }}
          onSubmit={(values) => {
            const addedData = {
              ...values,
              status: !!stat ? stat : [],
              group_ticket: group.value ? true : false,
              company: isTrainee
                ? userData.company
                : !!company
                ? company.value
                : null,
              owner_id: isTrainee
                ? userData.user_id
                : !!trainee
                ? trainee.value
                : group?.value
                ? groupId?.value
                : null,
              assigned_to: !!officer ? officer.value : null,
              issueType: !!issue ? issue.value : null,
              priority: !!pri ? pri.value : null,
              issueDtFrom: issueDtFrom,
              issueDtTo: issueDtTo,
              isTrainee: isTrainee,
            };
            handleSearch(addedData);
          }}
        >
          {({ handleChange, values, setFieldValue, resetForm }) => (
            <Form>
              {!advanceSearch ? (
                <Stack spacing={1}>
                  <Grid container>
                    <Autocomplete
                      disableClearable
                      id="searchField"
                      name="searchField"
                      value={searchField}
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, data) => {
                        setSearchField(data);
                      }}
                      size="small"
                      sx={{ width: 200 }}
                      options={[
                        {
                          value: 1,
                          label: getLabel(
                            sysLanguage,
                            "3000",
                            "1",
                            "Ticket ID"
                          ),
                        },
                        {
                          value: 2,
                          label: getLabel(
                            sysLanguage,
                            "3000",
                            "2",
                            "Title Keyword"
                          ),
                        },
                        {
                          value: 3,
                          label: getLabel(
                            sysLanguage,
                            "3000",
                            "5",
                            "Select Company"
                          ),
                        },
                        {
                          value: 4,
                          label: getLabel(
                            sysLanguage,
                            "3000",
                            "7",
                            "Select Issue Type"
                          ),
                        },
                        {
                          value: 5,
                          label: getLabel(
                            sysLanguage,
                            "3000",
                            "3",
                            "Select Ticket Status"
                          ),
                        },
                        {
                          value: 6,
                          label: getLabel(
                            sysLanguage,
                            "3000",
                            "4",
                            "In-Charge Officer"
                          ),
                        },
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getLabel(
                            sysLanguage,
                            "9000",
                            "60",
                            "Select Item"
                          )}
                        />
                      )}
                    />
                    <Fragment>
                      {searchField.value === 1 && (
                        <TextField
                          id="ticket_id"
                          name="ticket_id"
                          label={getLabel(
                            sysLanguage,
                            "3000",
                            "1",
                            "Ticket ID"
                          )}
                          value={values.ticket_id}
                          onChange={handleChange("ticket_id")}
                          size="small"
                          sx={{ width: 200 }}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      {searchField.value === 2 && (
                        <TextField
                          id="title"
                          name="title"
                          label={getLabel(
                            sysLanguage,
                            "3000",
                            "2",
                            "Title Keyword"
                          )}
                          value={values.title}
                          onChange={handleChange("title")}
                          size="small"
                          sx={{ width: 350 }}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      {searchField.value === 3 && (
                        <Stack direction={"row"}>
                          <Autocomplete
                            value={company}
                            id="company"
                            name="company"
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(e, data) => {
                              const fetchApi = async () => {
                                const output = await dispatch(
                                  ticketActions.getGroupName({
                                    company: !!data ? data.value : null,
                                  })
                                );
                                setGroupName(output);
                              };
                              if (!!data) fetchApi();
                              setCompany(data);
                              setTrainee(null);
                              setGroupId(null);
                            }}
                            size="small"
                            options={getOptions(sysLanguage, props.companyList)}
                            sx={{ width: 250 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={getLabel(
                                  sysLanguage,
                                  "3000",
                                  "5",
                                  "Select Company"
                                )}
                              />
                            )}
                          />
                          <Autocomplete
                            id="owner_id"
                            name="owner_id"
                            value={trainee}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(e, data) => setTrainee(data)}
                            size="small"
                            options={
                              !!company
                                ? props.traineeName.filter(
                                    (item) => item.company === company.value
                                  )
                                : []
                            }
                            sx={{ width: 250 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={getLabel(
                                  sysLanguage,
                                  "3000",
                                  "6",
                                  "Select Trainee"
                                )}
                              />
                            )}
                          />
                        </Stack>
                      )}
                    </Fragment>
                    <Fragment>
                      {searchField.value === 4 && (
                        <Autocomplete
                          id="issueType"
                          name="issueType"
                          value={issue}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => setIssue(data)}
                          size="small"
                          options={getOptions(sysLanguage, props.issueList)}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "7",
                                "Select Issue Type"
                              )}
                            />
                          )}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      {searchField.value === 5 && (
                        <Autocomplete
                          multiple
                          id="status"
                          name="status"
                          value={stat}
                          getOptionLabel={(option) => option.label || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, data) => setStat(data)}
                          size="small"
                          options={ticketStatus(sysLanguage)}
                          sx={{ width: 350 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "3",
                                "Select Ticket Status"
                              )}
                            />
                          )}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      {searchField.value === 6 && (
                        <Autocomplete
                          id="assigned_to"
                          name="assigned_to"
                          value={officer}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => setOfficer(data)}
                          size="small"
                          options={props.officerName}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "4",
                                "In-Charge Officer"
                              )}
                            />
                          )}
                        />
                      )}
                    </Fragment>
                  </Grid>
                </Stack>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <TextField
                          id="ticket_id"
                          name="ticket_id"
                          label={getLabel(
                            sysLanguage,
                            "3000",
                            "1",
                            "Ticket ID"
                          )}
                          value={values.ticket_id}
                          onChange={handleChange("ticket_id")}
                          size="small"
                          sx={{ width: 200 }}
                        />
                        <TextField
                          id="title"
                          name="title"
                          label={getLabel(
                            sysLanguage,
                            "3000",
                            "2",
                            "Title Keyword"
                          )}
                          value={values.title}
                          onChange={handleChange("title")}
                          size="small"
                          sx={{ width: 350 }}
                        />
                      </Grid>
                      {isTrainee && (
                        <>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Autocomplete
                              disableClearable
                              id="group"
                              name="group"
                              value={group}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={(e, data) => {
                                setGroup(data);
                              }}
                              size="small"
                              sx={{ width: 100 }}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={getLabel(
                                    sysLanguage,
                                    "3000",
                                    "72",
                                    "Group Ticket"
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      {!isTrainee && (
                        <>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Autocomplete
                              disableClearable
                              id="group"
                              name="group"
                              value={group}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={(e, data) => {
                                setGroup(data);
                              }}
                              size="small"
                              sx={{ width: 100 }}
                              options={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                              ]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={getLabel(
                                    sysLanguage,
                                    "3000",
                                    "72",
                                    "Group Ticket"
                                  )}
                                />
                              )}
                            />
                            <Autocomplete
                              value={company}
                              id="company"
                              name="company"
                              getOptionLabel={(option) => option.label || ""}
                              onChange={(e, data) => {
                                const fetchApi = async () => {
                                  const output = await dispatch(
                                    ticketActions.getGroupName({
                                      company: !!data ? data.value : null,
                                    })
                                  );
                                  setGroupName(output);
                                };
                                if (!!data) fetchApi();
                                setCompany(data);
                                setTrainee(null);
                                setGroupId(null);
                              }}
                              size="small"
                              options={getOptions(
                                sysLanguage,
                                props.companyList
                              )}
                              sx={{ width: 200 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={getLabel(
                                    sysLanguage,
                                    "3000",
                                    "5",
                                    "Select Company"
                                  )}
                                />
                              )}
                            />

                            {!!group?.value ? (
                              <Autocomplete
                                id="group_id"
                                name="group_id"
                                value={groupId}
                                getOptionLabel={(option) => option.label || ""}
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                onChange={(e, data) => setGroupId(data)}
                                sx={{ width: 250 }}
                                size="small"
                                options={
                                  !!company
                                    ? groupName?.filter(
                                        (item) => item.company === company.value
                                      )
                                    : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={getLabel(
                                      sysLanguage,
                                      "3000",
                                      "73",
                                      "Select Group"
                                    )}
                                  />
                                )}
                              />
                            ) : (
                              <Autocomplete
                                id="owner_id"
                                name="owner_id"
                                value={trainee}
                                getOptionLabel={(option) => option.label || ""}
                                onChange={(e, data) => setTrainee(data)}
                                size="small"
                                options={
                                  !!company
                                    ? props.traineeName.filter(
                                        (item) => item.company === company.value
                                      )
                                    : []
                                }
                                sx={{ width: 250 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={getLabel(
                                      sysLanguage,
                                      "3000",
                                      "6",
                                      "Select Trainee"
                                    )}
                                  />
                                )}
                              />
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Autocomplete
                          id="issueType"
                          name="issueType"
                          value={issue}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => setIssue(data)}
                          size="small"
                          options={getOptions(sysLanguage, props.issueList)}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "7",
                                "Select Issue Type"
                              )}
                            />
                          )}
                        />
                        <Autocomplete
                          id="priority"
                          name="priority"
                          value={pri}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => setPri(data)}
                          size="small"
                          options={ticketPriority(sysLanguage)}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "8",
                                "Select Priority"
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Autocomplete
                          multiple
                          id="status"
                          name="status"
                          value={stat}
                          getOptionLabel={(option) => option.label || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, data) => setStat(data)}
                          size="small"
                          options={ticketStatus(sysLanguage)}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "3",
                                "Select Ticket Status"
                              )}
                            />
                          )}
                        />
                        <Autocomplete
                          id="assigned_to"
                          name="assigned_to"
                          value={officer}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => setOfficer(data)}
                          size="small"
                          options={props.officerName}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "3000",
                                "4",
                                "In-Charge Officer"
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label={getLabel(
                              sysLanguage,
                              "3000",
                              "9",
                              "Create Date (From)"
                            )}
                            inputFormat="yyyy/MM/dd"
                            value={issueDtFrom}
                            onChange={(data) =>
                              setIssueDtFrom(
                                !!data ? data.toLocaleDateString() : null
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                sx={{ width: 250 }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label={getLabel(
                              sysLanguage,
                              "3000",
                              "10",
                              "Create Date (To)"
                            )}
                            inputFormat="yyyy/MM/dd"
                            value={issueDtTo}
                            onChange={(data) =>
                              setIssueDtTo(
                                !!data ? data.toLocaleDateString() : null
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                sx={{ width: 250 }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              )}

              <Box
                sx={{
                  display: "flex",
                  alignContent: "flex-end",
                  mt: 1,
                }}
              >
                <LoadingButton
                  sx={{ m: 1, width: 150 }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  {getLabel(sysLanguage, "9000", "1", "Search")}
                </LoadingButton>
                <Button
                  sx={{ m: 1, width: 150 }}
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    setCompany(null);
                    setGroup({
                      value: false,
                      label: "No",
                    });
                    setIsGroup(false);
                    setStat([]);
                    setTrainee(null);
                    setOfficer(null);
                    setIssue(null);
                    setPri(null);
                    setIssueDtFrom(null);
                    setIssueDtTo(new Date());
                    resetForm();
                  }}
                >
                  {getLabel(sysLanguage, "9000", "2", "Clear")}
                </Button>
                {isTrainee && !!userData.company && (
                  <Grid container justifyContent="flex-end">
                    <Button
                      sx={{ m: 1 }}
                      color="primary"
                      variant="contained"
                      onClick={handleOpen}
                    >
                      {getLabel(sysLanguage, "3000", "11", "Create Ticket")}
                    </Button>
                  </Grid>
                )}
                {!isTrainee && (
                  <Grid container justifyContent="flex-end">
                    <Button
                      sx={{ m: 1 }}
                      color="success"
                      variant="outlined"
                      onClick={() => setAdvanceSearch(!advanceSearch)}
                    >
                      {advanceSearch
                        ? getLabel(sysLanguage, "9000", "58", "Basic Search")
                        : getLabel(
                            sysLanguage,
                            "9000",
                            "59",
                            "Extended Search"
                          )}
                    </Button>
                    <Button
                      sx={{ m: 1 }}
                      color="primary"
                      variant="outlined"
                      onClick={handleOpen}
                    >
                      {getLabel(sysLanguage, "3000", "11", "Create Ticket")}
                    </Button>
                  </Grid>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        {rows.length > 0 && (
          <Box>
            <TableOne
              headCells={headCells}
              rows={rows}
              jumpToNavigate={jumpToNavigate}
              jumpToProfile={jumpToProfile}
            />
            <Grid
              container
              justifyContent="flex-end"
              style={{ paddingRight: 5 }}
            >
              <CSVLink
                filename="searchtickets.csv"
                target="_blank"
                data={csvData}
                headers={csvHeader}
              >
                <Button
                  size="large"
                  variant="outlined"
                  endIcon={<GridOnIcon />}
                >
                  {getLabel(sysLanguage, "9000", "54", "Export")}
                </Button>
              </CSVLink>
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SearchTicket;
