import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Details from "./Details";
import PasswordChange from "./PasswordChange";
import RoleInfo from "./RoleInfo";
import DeleteUser from "./DeleteUser";

import Navigator from "../components/Navigator";
import Header from "../components/Header";
import Footer from "../components/Footer";

import LinearProgress from "@mui/material/LinearProgress";

import * as configActions from "../../store/actions/config";
import * as userActions from "../../store/actions/user";

import getLabel from "../../utils/getLabel";

// import UltraTheme from "../components/UltraTheme";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainUser() {
  const dispatch = useDispatch();

  let { user_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [canEditTrainee, setCanEditTrainee] = useState(true);
  const [canEditOfficer, setEditOfficer] = useState(true);
  const [canDeleteUser, setDeleteUser] = useState(true);

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const searchOneUser = useSelector((state) => state.user.searchOneUser);
  const userRoles = useSelector((state) => state.user.userRoles);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const canViewProfile =
    roleArray &&
    roleArray?.some(
      (item) =>
        item.role_name === "SYSUSERS_UPDATE_TRAINEE" ||
        item.role_name === "SYSUSERS_UPDATE_ADMIN" ||
        item.role_name === "SYSUSERS_DELETE"
    );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const languageOption = [
    {
      value: "JPN",
      label: "日本語",
    },
    {
      value: "ENG",
      label: "English",
    },
    {
      value: "VNM",
      label: "Tiếng Việt",
    },
    {
      value: "TAI",
      label: "แบบไทย",
    },
    {
      value: "IDN",
      label: "bahasa Indonesia",
    },
  ];

  const emailNotifOption = [
    {
      value: true,
      label: getLabel(sysLanguage, "9000", "26", "Yes"),
    },
    {
      value: false,
      label: getLabel(
        sysLanguage,
        "9000",
        "27",
        "No, I don't want to receive email."
      ),
    },
  ];

  const statusOption = [
    {
      label: getLabel(sysLanguage, "9000", "28", "Active"),
      value: false,
    },
    {
      label: getLabel(sysLanguage, "9000", "29", "Locked Out"),

      value: true,
    },
  ];

  const [formData, setFormData] = useState({
    alternate_name: "",
    position: "",
    language: {},
    email_notif: {},
    status: {},
  });

  const loadUSer = async () => {
    await dispatch(configActions.getRoles());
    await dispatch(configActions.getCompany());

    const decryptedTicketId =
      isTrainee || !canViewProfile
        ? userData.user_id
        : atob(user_id?.toString().padStart(7, "0"));
    await dispatch(userActions.getOneAccount(decryptedTicketId));
    setLoading(false);
  };

  const loadData = async () => {
    const selRowLang = languageOption.filter(
      (item) => item.value === searchOneUser.language
    );
    const selRowNotif = emailNotifOption.filter(
      (item) => item.value === searchOneUser.email_notif
    );
    const selRowStatus = statusOption.filter(
      (item) => item.value === searchOneUser.account_locked
    );

    setFormData({
      ...formData,
      alternate_name: searchOneUser.fullname,
      position: searchOneUser.position,
      language: {
        value: searchOneUser.language,
        label: selRowLang.length > 0 ? selRowLang[0].label : "",
      },
      email_notif: {
        value: searchOneUser.email_notif,
        label: selRowNotif.length > 0 ? selRowNotif[0].label : "",
      },
      status: {
        value: searchOneUser.account_locked,
        label: selRowStatus.length > 0 ? selRowStatus[0].label : "",
      },
    });
  };

  const readAccess = () => {
    const editOne =
      roleArray &&
      roleArray?.some((item) => item.role_name === "SYSUSERS_UPDATE_TRAINEE");
    if (editOne) {
      setCanEditTrainee(true);
    } else {
      setCanEditTrainee(false);
    }

    const editTwo =
      roleArray &&
      roleArray?.some((item) => item.role_name === "SYSUSERS_UPDATE_ADMIN");
    if (editTwo) {
      setCanEditTrainee(true);
      setEditOfficer(true);
    } else {
      setEditOfficer(false);
    }

    const editThree =
      roleArray &&
      roleArray?.some((item) => item.role_name === "SYSUSERS_DELETE");
    if (editThree && userData.user_id !== searchOneUser.user_id) {
      setDeleteUser(true);
      // setCanEditTrainee(true);
      // setEditOfficer(true);
    } else {
      setDeleteUser(false);
    }
  };

  useEffect(() => {
    loadUSer();
    loadData();

    readAccess();
  }, [loading, searchOneUser.user_id]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {/* {isSmUp ? null : ( */}
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          {/* )} */}

          {/* <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          /> */}
        </Box>

        <Box sx={{ width: "100%" }}>
          {!loading ? (
            <div>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Header onDrawerToggle={handleDrawerToggle} />

                <Tabs
                  value={value}
                  onChange={handleChange}
                  //   aria-label="basic tabs example"
                >
                  <Tab
                    label={getLabel(sysLanguage, "5000", "12", "Details")}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={getLabel(
                      sysLanguage,
                      "5000",
                      "24",
                      "Password Change"
                    )}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={getLabel(sysLanguage, "5000", "30", "Roles")}
                    {...a11yProps(2)}
                  />
                  {canDeleteUser && (
                    <Tab
                      label={getLabel(sysLanguage, "5000", "48", "Delete User")}
                      {...a11yProps(3)}
                    />
                  )}
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                    "& .MuiAutocomplete-root": { mr: 0.7 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Details
                    searchOneUser={searchOneUser}
                    setFormData={setFormData}
                    formData={formData}
                    languageOption={languageOption}
                    statusOption={statusOption}
                    emailNotifOption={emailNotifOption}
                    isTrainee={isTrainee}
                    canEditTrainee={canEditTrainee}
                    canEditOfficer={canEditOfficer}
                    canDeleteUser={canDeleteUser}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                    "& .MuiAutocomplete-root": { mr: 0.7 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <PasswordChange
                    searchOneUser={searchOneUser}
                    isTrainee={isTrainee}
                    canEditTrainee={canEditTrainee}
                    canEditOfficer={canEditOfficer}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                    "& .MuiAutocomplete-root": { mr: 0.7 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <RoleInfo
                    searchOneUser={searchOneUser}
                    userRoles={userRoles}
                    isTrainee={isTrainee}
                    canEditOfficer={canEditOfficer}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                    "& .MuiAutocomplete-root": { mr: 0.7 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <DeleteUser
                    searchOneUser={searchOneUser}
                    canDeleteUser={canDeleteUser}
                  />
                </Box>
              </TabPanel>
              <Footer />
            </div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
