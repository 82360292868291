import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import * as authActions from "../../store/actions/auth";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  email_id: Yup.string().email("Invalid email").required("Required"),
});

const ErrorText = (props) => {
  return (
    <Typography style={{ color: "#FF0000" }} variant="caption">
      {props.label}
    </Typography>
  );
};

let resetCode;

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [stepTwo, setStepTwo] = useState(false);

  const handleResetPassword = async (data) => {
    setLoading(true);
    try {
      if (stepTwo) {
        const bCode = String(resetCode["resetCode"]);
        if (bCode !== data.code) {
          setError(String(error));
          throw new Error("Invalid code!");
        } else {
          //password reset Success
          await dispatch(authActions.resetPasswordOne(data));
          setLoading(false);
          setError("");
          setSuccess(true);

          setTimeout(() => {
            props.handleClose();
          }, 5000);
        }
      } else {
        resetCode = await dispatch(authActions.resetPasswordOne(data));

        setError("");
        setStepTwo(true);

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(String(error));
    }
  };

  return (
    <div>
      {!!error && (
        <Alert severity="error" sx={{ m: 1 }}>
          {error}
        </Alert>
      )}
      {!!success && (
        <Alert severity="success" sx={{ m: 1 }}>
          "Your new password has been sent to your email."
        </Alert>
      )}
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          Enter the Email Id associated with your account, and we’ll email you
          the code.
        </DialogContentText>

        <Formik
          initialValues={{
            email_id: "",
            code: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            handleResetPassword(values);
          }}
        >
          {({ handleChange, values, setFieldValue, errors, touched }) => (
            <Form>
              <Stack spacing={2}>
                <div>
                  <TextField
                    required
                    disabled={stepTwo}
                    autoComplete="off"
                    id="email_id"
                    label="Email Address"
                    type="email_id"
                    fullWidth
                    variant="outlined"
                    value={values.email_id}
                    onChange={handleChange("email_id")}
                  />
                  {errors.email_id && touched.email_id ? (
                    <ErrorText label={errors.email_id} />
                  ) : null}
                </div>
                {stepTwo && (
                  <div>
                    <DialogContentText sx={{ pb: 2 }}>
                      We've sent the code. Check your Email.
                    </DialogContentText>
                    <TextField
                      required
                      disabled={success}
                      autoComplete="off"
                      id="code"
                      label="Code"
                      type="code"
                      fullWidth
                      variant="outlined"
                      value={values.code}
                      onChange={handleChange("code")}
                    />
                    {errors.code && touched.code ? (
                      <ErrorText label={errors.code} />
                    ) : null}
                  </div>
                )}
                <LoadingButton
                  disabled={success}
                  type="submit"
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ p: 1 }}
                >
                  {stepTwo ? "Send my New Password" : "Send Code"}
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
      </DialogActions>
    </div>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
