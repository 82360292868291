import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import * as authActions from "../../store/actions/auth";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  code: Yup.string()
    .min(5, "Invalid code!")
    .max(11, "Invalid code!")
    .required("Required"),
  fname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
    .max(50, "Too Long!"),
  email2: Yup.string()
    .required("Confirm Email is required")
    .oneOf([Yup.ref("email"), null], "Email must match"),
  // password1: Yup.string()
  //   .required("Password is required")
  //   .min(6, "Your password is too short.")
  //   .max(50, "Too Long!"),
  // password2: Yup.string()
  //   .required("Confirm Password is required")
  //   .oneOf([Yup.ref("password1"), null], "Password must match"),
});

const ErrorText = (props) => {
  return (
    <Typography style={{ color: "#FF0000" }} variant="caption">
      {props.label}
    </Typography>
  );
};

const RegisterScreen = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const handleRegister = async (data) => {
    setLoading(true);
    setError();
    try {
      await dispatch(authActions.registerUser(data));
      setSuccess(true);

      setTimeout(() => {
        props.handleClose();
      }, 5000);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(String(error));
    }
  };

  return (
    <div>
      {!!success && (
        <Alert severity="success" sx={{ m: 1 }}>
          "Success! Your password has been sent to your email."
        </Alert>
      )}
      {!!error && (
        <Alert severity="error" sx={{ m: 1 }}>
          {error}
        </Alert>
      )}
      <DialogTitle>New User</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          Please get your valid Kumiai Code from your respective Kumiai
          Officers.
        </DialogContentText>

        <Formik
          initialValues={{
            code: "",
            email: "",
            email2: "",
            fname: "",
            mname: "",
            lname: "",
            // password1: "",
            // password2: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            handleRegister(values);
          }}
        >
          {({ handleChange, values, setFieldValue, errors, touched }) => (
            <Form>
              <Stack spacing={2}>
                <div>
                  <TextField
                    required
                    autoComplete="off"
                    id="code"
                    variant="outlined"
                    label="Kumiai Code"
                    type="text"
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={values.code.toUpperCase()}
                    onChange={handleChange("code")}
                  />
                  {errors.code && touched.code ? (
                    <ErrorText label={errors.code} />
                  ) : null}
                </div>
                <div>
                  <TextField
                    required
                    autoComplete="off"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange("email")}
                  />
                  {errors.email && touched.email ? (
                    <ErrorText label={errors.email} />
                  ) : null}
                </div>
                <div>
                  <TextField
                    required
                    autoComplete="off"
                    id="email2"
                    label="Confirm Email Address"
                    type="email2"
                    fullWidth
                    variant="outlined"
                    value={values.email2}
                    onChange={handleChange("email2")}
                  />
                  {errors.email2 && touched.email2 ? (
                    <ErrorText label={errors.email2} />
                  ) : null}
                </div>

                <div>
                  <TextField
                    required
                    id="fname"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.fname}
                    onChange={handleChange("fname")}
                  />
                  {errors.fname && touched.fname ? (
                    <ErrorText label={errors.fname} />
                  ) : null}
                </div>
                <TextField
                  id="mname"
                  label="Middle Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.mname}
                  onChange={handleChange("mname")}
                />
                <div>
                  <TextField
                    required
                    id="lname"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.lname}
                    onChange={handleChange("lname")}
                  />
                  {errors.lname && touched.lname ? (
                    <ErrorText label={errors.lname} />
                  ) : null}
                </div>
                {/* <Grid container>
                  <Grid item xs>
                    <TextField
                      required
                      autoComplete="off"
                      id="password1"
                      label="Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={values.password1}
                      onChange={handleChange("password1")}
                    />
                    {errors.password1 && touched.password1 ? (
                      <ErrorText label={errors.password1} />
                    ) : null}
                  </Grid>
                  <Grid item xs>
                    <TextField
                      autoComplete="off"
                      id="password2"
                      label="Confirm Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={values.password2}
                      onChange={handleChange("password2")}
                    />
                    {errors.password2 && touched.password2 ? (
                      <ErrorText label={errors.password2} />
                    ) : null}
                  </Grid>
                </Grid> */}
                <LoadingButton
                  disabled={success}
                  type="submit"
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ p: 1 }}
                >
                  Register
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
      </DialogActions>
    </div>
  );
};

RegisterScreen.propTypes = {};

export default RegisterScreen;
