import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";

import IconButton from "@mui/material/IconButton";

import Stack from "@mui/material/Stack";

import { Formik, Form } from "formik";

import S3 from "react-aws-s3";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

import CreateIcon from "@mui/icons-material/Create";

import formatTime from "../../utils/formatTime";
import { selectListFilter, attachmentType } from "../../utils/selectOptions";

import Confirmation from "../components/Confirmation";

import * as configActions from "../../store/actions/config";
import getLabel from "../../utils/getLabel";

const fileSizeLimit = 5242880;

const FileAttachment = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const awsKey = useSelector((state) => state.config.awsKey);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const trainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const userData = useSelector((state) => state.auth.userData[0]);

  const fileList = useSelector((state) => state.config.attachments);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingAttach, setLoadingAttach] = useState(false);
  const [error, setError] = useState("");

  const [fileDetails, setFileDetails] = useState();
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [rowLines, setRowLines] = useState([]);
  const [listOfFiles, setListOfFiles] = useState(fileList);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 3000);
  }, [error]);

  useEffect(() => {
    setListOfFiles(fileList);
    setRowLines(fileList);
  }, [loadingAttach, loadingDelete]);

  useEffect(() => {
    setRowLines(listOfFiles);
  }, [loadingEdit]);

  // let rows = [];
  // fileList &&
  //   fileList.map((item) => {
  //     rows.push({ ...item });
  //   });

  //START Attachment
  const changeHandler = (event) => {
    if (!!event.target.files[0]) {
      uploadFile(event);
    }
  };

  const uploadFile = (event) => {
    const config = {
      bucketName: awsKey.bucket,
      dirName: props.module,
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    };

    const ReactS3Client = new S3(config);

    const random = uuidv4();
    const newFileName =
      props.company + "_" + random + "_" + event.target.files[0].name;

    setLoadingAttach(true);

    if (event.target.files[0].size <= fileSizeLimit) {
      ReactS3Client.uploadFile(event.target.files[0], newFileName)
        .then((data) => {
          if (data.status === 204) {
            addAttachment({
              key: data.key,
              location: data.location,
              bucket: data.bucket,
              size_bytes: event.target.files[0].size,
              originalname: event.target.files[0].name,
              mimetype: event.target.files[0].type,
              source_id: props.source_id,
              assigned_to: props.assigned_to,
              watcher: props.watcher,
              module: props.module,
              group_ticket:
                props.module === "tickets" ? props.group_ticket : null,
              group_id: props.module === "tickets" ? props.group_id : null,
              owner_id: props.owner_id,
              mode: "add",
            });
          } else {
            setError("File upload error-connection refused!");
            setLoadingAttach(false);
          }
        })
        .catch((err) => {
          setError("File upload error-connection refused!");
          setLoadingAttach(false);
          console.error(err);
        });
    } else {
      setError(
        "File size must be less than " + formatBytes(fileSizeLimit) + "."
      );
      setLoadingAttach(false);
    }
  };

  const deleteFile = async (row_id, source_id, keyName, originalname) => {
    setLoadingDelete(true);

    AWS.config.update({
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    });
    const s3 = new AWS.S3();

    const deleteParam = {
      Bucket: awsKey.bucket,
      Key: props.module + "/" + keyName,
    };

    await s3.deleteObject(deleteParam, function (err, data) {
      if (err) {
        // an error occurred
        setLoadingDelete(false);
        console.log(err, err.stack);
      } else {
        // successful response

        const delAtt = async () => {
          const data = {
            row_id: row_id,
            source_id: source_id,
            module: props.module,
            originalname: originalname,
            assigned_to: props.assigned_to,
            watcher: props.watcher,
            owner_id: props.owner_id,
            mode: "delete",
          };

          await dispatch(configActions.addDeleteAttachment(data));
          setLoadingDelete(false);
        };

        delAtt();
        console.log("Deleted Sucessfully!");
      }
    });
  };

  const addAttachment = async (data) => {
    try {
      await dispatch(configActions.addDeleteAttachment(data));
      setLoadingAttach(false);
    } catch (err) {
      setError("File upload error-cannot connect to server!");
      setLoadingAttach(false);
      console.log(err.message);
    }
  };

  //END Attachment

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const handleFileEdit = (details) => {
    setOpenEdit(true);

    const origFileName =
      details.originalname.substring(
        0,
        details.originalname.lastIndexOf(".")
      ) || details.originalname;

    const fileExtension = details.originalname.split(".").pop();

    let selRow = [];

    selRow = attachmentType(sysLanguage).filter(
      (item) => item.value === details.attachmenttype
    );

    setFileDetails({
      ...details,
      fileNameOnly: origFileName,
      extension: fileExtension,
      typeDescr: selRow.length > 0 ? selRow[0].label : "",
    });
  };

  const handleSave = async (values) => {
    try {
      setLoadingEdit(true);
      await dispatch(configActions.updateFileName(values));
      setLoadingEdit(false);

      const foundIndex = listOfFiles?.findIndex(
        (item) => item.row_id === values.row_id
      );

      // const newArray = [...listOfFiles];
      // newArray[foundIndex].originalname = values.originalname;
      // // newArray[foundIndex].attachmenttype = values.attachmenttype.value;

      const newArray = [];

      listOfFiles?.map((item) => {
        if (item.row_id === values.row_id) {
          const obj = {
            ...item,
            originalname: values.originalname,
            attachmenttype: values.attachmenttype.value,
          };
          newArray.push(obj);
        }
      });

      const otherArray = listOfFiles?.filter(
        (item) => item.row_id !== values.row_id
      );

      const finalArray = [...newArray, ...otherArray];
      setListOfFiles(finalArray);

      setOpenEdit(false);
    } catch (error) {
      setOpenEdit(false);
      setLoadingEdit(false);

      console.log(error);
    }
  };

  const EditFileName = () => {
    return (
      <Modal
        open={openEdit}
        // onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 1 }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {getLabel(sysLanguage, "9000", "56", "File Information")}
            </Typography>
            <IconButton color="primary" sx={{ paddingRight: 0.5 }}>
              <CloseIcon
                color="error"
                fontSize="large"
                onClick={() => setOpenEdit(!openEdit)}
              />
            </IconButton>
          </Grid>
          <Formik
            initialValues={{
              row_id: fileDetails?.row_id,
              extension: fileDetails?.extension,
              originalname: fileDetails?.fileNameOnly,
              attachmenttype:
                props.module === "profile"
                  ? {
                      value: fileDetails?.attachmenttype,
                      label: fileDetails?.typeDescr,
                    }
                  : "99",
            }}
            // validationSchema={SignupSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
          >
            {({ handleChange, values, setFieldValue, errors, touched }) => (
              <Form>
                {props.module === "profile" && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Autocomplete
                      disableClearable
                      sx={{ margin: 1, width: 350 }}
                      id="fileType"
                      name="fileType"
                      value={values.attachmenttype}
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      options={attachmentType(sysLanguage)}
                      onChange={(e, data) => {
                        values.attachmenttype.value = data.value;
                        values.attachmenttype.label = data.label;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={getLabel(sysLanguage, "4000", "58", "Type")}
                        />
                      )}
                      size="small"
                    />
                  </Grid>
                )}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <TextField
                    required
                    label={getLabel(sysLanguage, "9000", "20", "Filename")}
                    size="small"
                    value={values.originalname}
                    onChange={handleChange("originalname")}
                    sx={{ margin: 1, width: "100%" }}
                  />
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    alignContent: "flex-end",
                    mt: 3,
                  }}
                >
                  <LoadingButton
                    sx={{ m: 1, width: 150 }}
                    type="submit"
                    loading={loadingEdit}
                    variant="contained"
                    color="primary"
                  >
                    {getLabel(sysLanguage, "9000", "6", "Save")}
                  </LoadingButton>
                  <Button
                    sx={{ m: 1, width: 150 }}
                    color="warning"
                    variant="contained"
                    onClick={() => setOpenEdit(false)}
                  >
                    {getLabel(sysLanguage, "9000", "7", "Cancel")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Grid container justifyContent="flex-start">
          <Stack direction="row" spacing={2}>
            {!props.isLocked && (
              <LoadingButton
                variant="contained"
                component="label"
                loading={loadingAttach}
              >
                {getLabel(sysLanguage, "9000", "8", "Upload")}
                <input
                  hidden
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  type="file"
                  onChange={changeHandler}
                />
              </LoadingButton>
            )}
            {error.length > 0 && (
              <div>
                <Alert severity="error">{error}</Alert>
              </div>
            )}
          </Stack>
        </Grid>
        <EditFileName />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {getLabel(sysLanguage, "9000", "20", "Filename")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "9000", "55", "Type")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "9000", "21", "Size")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "9000", "22", "Added Date")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "9000", "23", "Added By")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowLines.map((row) => (
                <TableRow
                  key={row.row_id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <IconButton color="primary" sx={{ paddingRight: 1.5 }}>
                      {(props.canEdit || userData.user_id === row.added_by) && (
                        <CreateIcon onClick={() => handleFileEdit(row)} />
                      )}
                    </IconButton>

                    <Link
                      target="_blank"
                      href={awsKey.URL + row.location}
                      variant="body2"
                    >
                      {row.originalname.length > 40
                        ? row.originalname.substring(0, 30) +
                          " ...... " +
                          row.originalname.substring(
                            row.originalname.length - 10
                          )
                        : row.originalname}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    {selectListFilter(
                      attachmentType(sysLanguage),
                      row.attachmenttype,
                      "value",
                      "label",
                      "Y",
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {formatBytes(row.size_bytes)}
                  </TableCell>
                  <TableCell align="right">
                    {formatTime(row.added_dttm)}
                  </TableCell>
                  <TableCell align="right">{row.addedBy}</TableCell>
                  <TableCell align="right">
                    {!props.isLocked && (
                      <>
                        {(props.canEdit ||
                          userData.user_id === row.added_by) && (
                          <Confirmation
                            icon={true}
                            buttonName={getLabel(
                              sysLanguage,
                              "3000",
                              "50",
                              "Delete Attachment"
                            )}
                            title={getLabel(
                              sysLanguage,
                              "3000",
                              "50",
                              "Delete Attachment"
                            )}
                            context={getLabel(
                              sysLanguage,
                              "9000",
                              "5",
                              "Are you sure you want to delete this item?"
                            )}
                            color="error"
                            variant="outlined"
                            yesFunction={() =>
                              deleteFile(
                                row.row_id,
                                row.source_id,
                                row.key,
                                row.originalname
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() =>
              trainee ? navigate(`/`, {}) : navigate(props.back, {})
            }
          >
            {getLabel(sysLanguage, "9000", "9", "Back")}
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

export default FileAttachment;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: 300,
  maxHeight: 300,
};
