import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { io } from "socket.io-client";

import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";

import { v4 as uuid } from "uuid";

import getEnvironment from "../../utils/environments";
import getLabel from "../../utils/getLabel";

import * as chatActions from "../../store/actions/chat";

const url = getEnvironment().apiKey;
const socket = io.connect(url);

const NewChatGroup = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);
  const userData = useSelector((state) => state.auth.userData[0]);

  const companyList = useSelector((state) => state.config.companyList);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const allNames = useSelector((state) =>
    isTrainee
      ? state.config.allUsers?.filter(
          (item) =>
            item.code !== userData.user_id &&
            (item.company === userData.company || item.role_name === "OFFICER")
        )
      : state.config.allUsers?.filter((item) => item.code !== userData.user_id)
  );

  const [selectedNames, setSelectedNames] = useState([]);
  const [groupName, setGroupName] = useState("");

  useEffect(() => {
    if (props.mode === "edit") {
      setSelectedNames(props.participantsList);
      setGroupName(props.chatName);
    }
  }, [props.newGroup]);

  const [error, setError] = useState({ groupName: false, selOption: false });

  const addInfoToName = (name, user_id) => {
    const result =
      !!allNames && allNames.filter((item) => item.code === user_id);

    const companyName = companyList.filter(
      (item) => item.code === result[0]?.company
    );

    if (result[0]?.role_name === "OFFICER") {
      return name + " [" + result[0]?.position + "] ";
    } else {
      return !!companyName[0]?.code
        ? name + " [" + companyName[0]?.code + "] " + companyName[0]?.descr
        : name + null;
    }
  };

  const getCompanyName = (value) => {
    const result =
      !!companyList && companyList.filter((item) => item.code === value);
    return !!result[0]?.descr ? result[0]?.descr : "No Company";
  };

  const autoComp = [];

  allNames?.map((item) => {
    autoComp.push({
      value: item.code,
      label: addInfoToName(item.label, item.code),
      company: item.position || getCompanyName(item.company),
    });
  });

  const closeModal = () => props.setNewGroup(false);

  const handleSubmit = async () => {
    setError({
      groupName: !!groupName ? false : true,
      selOption: selectedNames?.length > 0 ? false : true,
    });

    if (!!groupName && selectedNames?.length > 0) {
      const newChatId = uuid();

      const participantList = [];
      selectedNames.map((item) => participantList.push(item.value));

      const data = {
        chat_hdr_id:
          props.mode === "edit" ? props.chatInfo.chat_hdr_id : newChatId,
        chat_type: "G",
        chat_name: groupName,
        participants:
          props.mode === "edit"
            ? participantList
            : [userData.user_id, ...participantList],
        mode: props.mode,
      };

      const chatDetails = await dispatch(chatActions.createChat(data));

      if (props.mode === "edit") {
        props.setChatGrpName(groupName);

        props.origParticipants?.map((item) => {
          const data = {
            recipient: item,
            sender: userData.user_id,
          };
          socket.emit("refreshChat", data);
        });

        selectedNames?.map((item) => {
          const data = {
            recipient: item,
            sender: userData.user_id,
          };
          socket.emit("refreshChat", data);
        });

        props.setNewGroup(false);
      } else {
        props.setChatInfo(chatDetails);

        props.setNewGroup(false);

        selectedNames?.map((item) => {
          const data = {
            recipient: item,
            sender: userData.user_id,
          };
          socket.emit("refreshChat", data);
        });
      }
    }
  };

  return (
    <div>
      <Modal
        open={props.newGroup}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          position: "absolute",
          top: "10%",
          overflow: "scroll",
          maxHeight: "200",
        }}
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 1 }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {getLabel(sysLanguage, "6000", "31", "Group Chat")}
            </Typography>
            <CloseIcon
              color="error"
              fontSize="large"
              onClick={() => props.setNewGroup(false)}
            />
          </Grid>

          <Divider variant="left" />

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline"
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            <TextField
              error={error.groupName}
              helperText={
                error.groupName
                  ? getLabel(sysLanguage, "9000", "34", "Field is required.")
                  : ""
              }
              required
              id="outlined-required"
              label={getLabel(sysLanguage, "6000", "27", "Group Name")}
              sx={{ margin: 0.5, width: "60%" }}
              size="small"
              onChange={(event) => setGroupName(event.target.value)}
              defaultValue={props.mode === "edit" ? props.chatName : ""}
            />
            <Button
              sx={{ marginLeft: 2 }}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {getLabel(sysLanguage, "9000", "6", "Save")}
            </Button>
          </Grid>
          <Box
            style={{
              margin: ".2rem",
            }}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="participants"
              name="participants"
              value={selectedNames}
              groupBy={(option) => option.company}
              getOptionLabel={(option) => option?.label || ""}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              onChange={(e, data) => setSelectedNames(data)}
              size="small"
              options={autoComp}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={getLabel(sysLanguage, "6000", "30", "Chat Members")}
                />
              )}
            />
            {error.selOption && (
              <Box
                sx={{
                  color: "#f50057",
                  fontSize: 12,
                  marginLeft: ".9rem",
                  marginTop: ".3rem",
                }}
              >
                Field is required.
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NewChatGroup;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: 650,
  maxHeight: 750,
  overflowY: "auto",
};
