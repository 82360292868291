import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import Confirmation from "../components/Confirmation";

import * as userActions from "../../store/actions/user";

import getLabel from "../../utils/getLabel";

const DeleteUser = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.auth.userData[0]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const sysLanguage = useSelector((state) => state.config.labels);

  const checkAccess = () => {
    if (props.searchOneUser.role_name === "TRAINEE") {
      if (
        userData.user_id === props.searchOneUser.user_id ||
        props.canEditTrainee ||
        props.canEditOfficer
      )
        return false;
      else {
        return true;
      }
    } else {
      if (
        userData.user_id === props.searchOneUser.user_id ||
        props.canEditOfficer
      )
        return false;
      else {
        return true;
      }
    }
  };

  const handleDelete = async (formData) => {
    try {
      setLoading(true);
      await dispatch(userActions.deleteAccount(formData));
      setLoading(false);
      navigate(`/manageUser`, {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {props.canDeleteUser && (
        <Button
          sx={{ m: 1 }}
          color="error"
          variant="outlined"
          onClick={() => setOpen(!open)}
        >
          {getLabel(sysLanguage, "5000", "50", "Delete this User")}
        </Button>
      )}
      {open && (
        <Confirmation
          buttonName={getLabel(
            sysLanguage,
            "5000",
            "50",
            "Confirm Delete this User"
          )}
          title={getLabel(
            sysLanguage,
            "5000",
            "51",
            "WARNING: This cannot be undone."
          )}
          context={getLabel(
            sysLanguage,
            "5000",
            "52",
            "Are you sure you want to delete this User?"
          )}
          color="error"
          variant="contained"
          yesFunction={() =>
            handleDelete({
              user_id: props.searchOneUser?.user_id,
              email_id: props.searchOneUser?.email_id,
            })
          }
        />
      )}
    </div>
  );
};

export default DeleteUser;
