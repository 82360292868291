let instance = "prod";

function getEnvironment() {
  if (instance.startsWith("prod")) {
    return {
      envName: "PRODUCTION",
      // apiKey: "https://production-hp-ultra.herokuapp.com", version 1
      apiKey: "https://hp-ultra-prod-v2-193d36b06251.herokuapp.com",
    }; // prod env settings
  } else if (instance.startsWith("staging")) {
    // matches staging-v1, staging-v2
    return {
      envName: "STAGING",
      apiKey: "https://hp-ultra-dev.herokuapp.com",
    }; // stage env settings
  } else {
    // assume any other release channel is development
    return {
      envName: "DEVELOPMENT",
      // apiKey: "http://localhost:8010",
      apiKey: "https://hp-ultra-dev-v2-93dc7915e256.herokuapp.com",
    }; // dev env settings
  }
}

export default getEnvironment;
