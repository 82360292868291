import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Details from "./Details";
import FileAttachment from "../components/FileAttachment";

import Navigator from "../components/Navigator";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { getOptions, yesOrNo } from "../../utils/selectOptions";

import getLabel from "../../utils/getLabel";

// import UltraTheme from "../components/UltraTheme";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainBanner() {
  const sysLanguage = useSelector((state) => state.config.labels);

  const searchOneBanner = useSelector((state) => state.config.openOneBanner);

  const companyList = useSelector((state) => state.config.companyList);

  // const userData = useSelector((state) => state.auth.userData[0]);
  // const userRoles = useSelector((state) => state.user.userRoles);
  const roleArray = useSelector((state) => state.auth.userRoles);

  const [value, setValue] = useState(0);
  const [canEdit, setCanEdit] = useState(false);

  const [bannerID, setBannerID] = useState();

  const [formData, setFormData] = useState({
    mode: !!searchOneBanner ? "edit" : "add",
    row_id: !!searchOneBanner ? searchOneBanner.row_id : null,
    validFrom: !!searchOneBanner ? searchOneBanner.datefrom : null,
    validTo: !!searchOneBanner ? searchOneBanner.dateto : null,
    title: !!searchOneBanner ? searchOneBanner.title : "",
    text: !!searchOneBanner ? searchOneBanner.text : "",
    commenting: {},
    company: [],
  });

  const checkAccess = () => {
    const editOne =
      roleArray &&
      roleArray?.some((item) => item.role_name === "CREATE_ANNOUNCEMENT");
    if (editOne) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  };

  useEffect(() => {
    const companyStaging = [];

    !!searchOneBanner?.company &&
      searchOneBanner?.company.map((company) => {
        const compDetails = companyList.filter((item) => item.code === company);
        companyStaging.push({
          value: compDetails[0].code,
          label: compDetails[0].descr,
        });
      });

    // setCompany(!!companyStaging ? companyStaging : []);

    let selRow = [];

    selRow = yesOrNo(sysLanguage).filter(
      (item) => item.value === searchOneBanner?.comments_on
    );

    setFormData({
      ...formData,
      commenting: {
        value: searchOneBanner?.comments_on,
        label: selRow.length > 0 ? selRow[0].label : "",
      },
      company: !!companyStaging ? companyStaging : [],
    });

    checkAccess();
  }, [searchOneBanner]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [currentPage, setCurrentPage] = useState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {/* {isSmUp ? null : ( */}
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          {/* )} */}

          {/* <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          
          /> */}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Header onDrawerToggle={handleDrawerToggle} />

            <Tabs
              value={value}
              onChange={handleChange}
              //   aria-label="basic tabs example"
            >
              <Tab
                label={getLabel(sysLanguage, "6000", "7", "Details")}
                {...a11yProps(0)}
              />
              {formData.mode === "edit" && (
                <Tab
                  label={getLabel(sysLanguage, "6000", "19", "Files")}
                  {...a11yProps(1)}
                />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box
              sx={{
                "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                "& .MuiAutocomplete-root": { mr: 0.7 },
              }}
              noValidate
              autoComplete="off"
            >
              <Details
                searchOneBanner={searchOneBanner}
                companyList={companyList}
                setFormData={setFormData}
                formData={formData}
                canEdit={!canEdit}
                setBannerID={setBannerID}
                bannerID={bannerID || formData.row_id}
                // setCompany={setCompany}
                // company={company}
              />
            </Box>
          </TabPanel>
          {formData.mode === "edit" && (
            <TabPanel value={value} index={1}>
              <Box
                sx={{
                  "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                  "& .MuiAutocomplete-root": { mr: 0.7 },
                }}
                noValidate
                autoComplete="off"
              >
                <FileAttachment
                  module="announcements"
                  isLocked={!canEdit}
                  canEdit={canEdit}
                  source_id={formData.row_id || bannerID}
                  company={formData.row_id}
                  back={"/manageBanner"}
                />
              </Box>
            </TabPanel>
          )}
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
