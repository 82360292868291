import forceLogOut from "../../utils/forceLogOut";

import {
  SEARCHUSER,
  SEARCH_ONE_USER,
  UPDATE_ONE_USER,
  SEARCH_USERROLES,
  DELETEUSER,
} from "../reducers/user";

import { UPDATE_AVATAR } from "../reducers/auth";

import { getLabels } from "./config";
import { loadUser } from "./auth";

import getEnvironment from "../../utils/environments";
const url = getEnvironment().apiKey;

//search Account
export const searchAccount = (values) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/getAllAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Search Users";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    dispatch({
      type: SEARCHUSER,
      userResult: resData,
    });
  };
};

//search One Account
export const getOneAccount = (user_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/getOneAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    });

    if (!response.ok) {
      let message = "Cannot Search One Account";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    dispatch({
      type: SEARCH_ONE_USER,
      userResult: resData,
    });

    //get Role Details
    await dispatch(loadRoles(resData[0]?.user_id, resData[0]?.business_unit));
  };
};

//update Account
export const updateAccount = (formData, currentUser) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/updateAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Update Account Details";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get one account
    await dispatch(getOneAccount(formData.user_id));

    //update language as needed
    if (currentUser === resData[0].user_id) {
      await dispatch(getLabels(resData[0].language));
      await dispatch(loadUser(resData[0].user_id, resData[0].business_unit));
    }
  };
};

//update Password
export const updatePassword = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/updatePassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Update Password";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
  };
};

//update Password
export const updateAvatar = (formData) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/updateAvatar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Update Avatar";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: UPDATE_ONE_USER,
      userData: resData,
    });

    dispatch({
      type: UPDATE_AVATAR,
      avatar: formData.avatar,
      user_id: formData.user_id,
    });
  };
};

//delete Account
export const deleteAccount = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/deleteAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Account";
      forceLogOut(response.status, message);
    }

    // const resData = await response.json();

    // await dispatch(searchAccount(resData));

    dispatch({
      type: DELETEUSER,
      userId: formData.user_id,
    });
  };
};

//Load Roles for User
export const loadRoles = (user_id, business_unit) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/access/getRole`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
        business_unit: business_unit,
      }),
    });

    if (!response.ok) {
      throw new Error("Cannot Load Roles...");
    }

    const resData = await response.json();

    dispatch({
      type: SEARCH_USERROLES,
      accessRoles: resData,
    });
  };
};

//add Role
export const addRole = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/access/addRole`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Add Role";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Role Details
    await dispatch(loadRoles(formData.user_id, formData.business_unit));
  };
};

//add Role
export const deleteRole = (formData) => {
  console.log(formData);
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/access/deleteRole`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Role";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Role Details
    await dispatch(loadRoles(formData.user_id, formData.business_unit));
  };
};
