import { LOADUSER, USERROLES, LOGOUT } from "../reducers/auth";
import { CLEAR_PROFILE } from "../reducers/profile";
import { CLEAR_USER } from "../reducers/user";
import { CLEAR_TICKET } from "../reducers/ticket";
import { CLEAR_CONFIG } from "../reducers/config";
import { CLEAR_DASHBOARD } from "../reducers/dashboard";

import forceLogOut from "../../utils/forceLogOut";

import {
  getAWS,
  getLabels,
  getCompany,
  getOccupation,
  getVisaList,
  getIssue,
  getCountry,
  getAllUsers,
} from "./config";

// import setAuthToken from "../../utils/setAuthToken";

import getEnvironment from "../../utils/environments";
const url = getEnvironment().apiKey;

export const loginUser = (values) => {
  const { email, password } = values;

  return async (dispatch) => {
    const response = await fetch(`${url}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email_id: email,
        password: password,
      }),
    });
    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.errors.msg;

      let message = "Cannot Login user";

      if (errorId === "Invalid Credentials!") {
        message = "Invalid Email or Password";
      }

      throw new Error(message);
    }

    const resData = await response.json();

    //09July2023 removed sessionStorage - added localStorage
    // Assign token
    // sessionStorage.setItem("token", resData.token);
    localStorage.setItem("token", resData.token);

    //Login the user
    await dispatch(
      loadUser(resData.userData.user_id, resData.userData.business_unit)
    );
  };
};

//register User
export const registerUser = (values) => {
  const { code, email, fname, mname, lname } = values;
  return async (dispatch) => {
    const response = await fetch(`${url}/auth/addAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_id: email,
        email_id: email,
        unique_code: code,
        fname: fname,
        mname: mname,
        lname: lname,
      }),
    });
    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.errors.msg;

      let message = "Cannot Register User";

      if (errorId === "KUMIAI") {
        message = "Kumiai Code is Invalid!";
      }

      if (errorId === "DUPLICATE_EMAIL") {
        message = "Email already registered under the same Kumiai!";
      }

      throw new Error(message);
    }

    // const resData = await response.json();
  };
};

//register User Profile
export const registerProfile = (values) => {
  const { user_id, business_unit, first_name, middle_name, last_name } = values;

  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/addProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
        business_unit: business_unit,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
      }),
    });
    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.errors.msg;

      let message = "Cannot Login user";

      if (errorId === "INVALID") {
        message = "Cannot Register User";
      }

      throw new Error(message);
    }
    const resData = await response.json();
  };
};

// add Access
export const addDefaultAccess = (values) => {
  const { user_id, business_unit, role_name } = values;

  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/access/addRole`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
        business_unit: business_unit,
        role_name: role_name,
      }),
    });

    if (!response.ok) {
      throw new Error("Cannot Add Access");
    }

    // const resData = await response.json();
  };
};

// Load User
export const loadUser = (userId, business_unit) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/auth/getAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: userId,
        business_unit: business_unit,
      }),
    });

    if (!response.ok) {
      throw new Error("Invalid Credentials!");
      // forceLogOut(response.status, "Cannot load user...");
    } else {
      const resData = await response.json();
      dispatch({
        type: LOADUSER,
        authData: resData,
        token: token,
      });

      //Load user Permission / Access
      // dispatch(loadAccessUser(resData.user_id, resData.business_unit));
      await dispatch(loadAccessUser());
      await dispatch(getLabels(resData[0].language));
      await dispatch(getAWS());
      await dispatch(getCompany());
      await dispatch(getOccupation());
      await dispatch(getCountry());
      await dispatch(getVisaList());
      await dispatch(getIssue());
      await dispatch(getAllUsers());
    }
  };
};

//Load Access/Roles for User
export const loadAccessUser = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/access/access`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      // throw new Error("Cannot Load Roles...");
      forceLogOut(response.status, "Cannot load Roles...");
    }

    const resData = await response.json();

    dispatch({
      type: USERROLES,
      accessRoles: resData,
    });
  };
};

//resetPasswordOne
export const resetPasswordOne = (formData) => {
  return async (dispatch) => {
    const response = await fetch(`${url}/auth/forgotPasswordOne`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.errors.msg;

      let message = "Cannot Reset password";

      if (errorId === "INVALID_EMAIL") {
        message = "Inputted Email Id is not registered.";
      }

      throw new Error(message);
    }

    const resData = await response.json();
    return resData;
  };
};

//contactUs
export const contactUs = (formData) => {
  return async (dispatch) => {
    const response = await fetch(`${url}/auth/contactUs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.errors.msg;

      let message = "Cannot Reset password";

      if (errorId === "INVALID_EMAIL") {
        message = "Inputted Email Id is not registered.";
      }

      throw new Error(message);
    }

    const resData = await response.json();
    return resData;
  };
};

// Logout user
export const logOut = () => {
  return async (dispatch) => {
    console.log("dispatch LOGOUT");

    dispatch({
      type: CLEAR_TICKET,
    });
    dispatch({
      type: CLEAR_PROFILE,
    });
    dispatch({
      type: CLEAR_USER,
    });
    dispatch({
      type: CLEAR_CONFIG,
    });
    dispatch({
      type: CLEAR_DASHBOARD,
    });

    dispatch({
      type: LOGOUT,
    });
  };
};
