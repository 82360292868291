import forceLogOut from "../../utils/forceLogOut";

import getEnvironment from "../../utils/environments";
const url = getEnvironment().apiKey;

//Create Chat Item
export const createChat = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/createChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        let message = "Cannot Save Chat";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      return resData[0];
    } catch (error) {
      console.log(error);
    }
  };
};

//get Chat List or Chat Mates os the user
export const getChat = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/getChat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (!response.ok) {
        let message = "Cannot Get Chat";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      return resData;
    } catch (error) {
      console.log(error);
    }
  };
};

//Load Messages
export const getMessages = (chat_hdr_id) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/getMessages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
        }),
      });

      if (!response.ok) {
        let message = "Cannot load chat messages..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      return resData;
    } catch (err) {
      console.log(err);
    }
  };
};

//get Chat details
export const getChatDetails = (chat_hdr_id) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/getChatDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
        }),
      });

      if (!response.ok) {
        let message = "Cannot load chat details..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      return resData[0];
    } catch (err) {
      console.log(err);
    }
  };
};

//Load User Chats
export const getChatIdNewChat = (receiver, sender) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/getChatIdNewChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          receiver: receiver,
          sender: sender,
        }),
      });

      if (!response.ok) {
        let message = "Cannot get Chat Id for new chat..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      return resData[0];
    } catch (err) {
      console.log(err);
    }
  };
};

//Delete Chat Group
export const deleteChatGroup = (chat_hdr_id) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/deleteChatGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
        }),
      });

      if (!response.ok) {
        let message = "Cannot get delete chat..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
    } catch (err) {
      console.log(err);
    }
  };
};

//Leave Personal Chat
export const leavePersonalChat = (chat_hdr_id, participants) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/leavePersonalChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
          participants: participants,
        }),
      });

      if (!response.ok) {
        let message = "Cannot leave Personal Chat..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
    } catch (err) {
      console.log(err);
    }
  };
};

//deleteMessage
export const deleteMessage = (chat_hdr_id, message_id, key) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/deleteMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
          message_id: message_id,
          key: key,
        }),
      });

      if (!response.ok) {
        let message = "Cannot leave delete a message..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
    } catch (err) {
      console.log(err);
    }
  };
};

//Mark Seen Chats
export const seenChatMark = (chat_hdr_id, chat_msg_id) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/seenChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
          chat_msg_id: chat_msg_id,
        }),
      });

      if (!response.ok) {
        let message = "Cannot process seen chat messages..";
        forceLogOut(response.status, message);
      }
    } catch (err) {
      console.log(err);
    }
  };
};

//Get Seen Chat
export const getSeenChat = (chat_hdr_id, created_dttm) => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/chat/getSeenChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify({
          chat_hdr_id: chat_hdr_id,
          created_dttm: created_dttm,
        }),
      });

      if (!response.ok) {
        let message = "Cannot get seen chat record..";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      return resData;
    } catch (err) {
      console.log(err);
    }
  };
};
