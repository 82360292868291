// import S3 from "react-aws-s3";
import AWS from "aws-sdk";

const deleteFiles = async (awsKey, fileList, module) => {
  AWS.config.update({
    region: awsKey.region,
    accessKeyId: awsKey.accessKey,
    secretAccessKey: awsKey.secretKey,
  });

  const s3 = new AWS.S3();

  let keyName = "";

  fileList.forEach((item) => {
    keyName = item.key;

    const deleteParam = {
      Bucket: awsKey.bucket,
      Key: module + "/" + keyName,
    };

    const deleteFromS2 = async (deleteParam) => {
      await s3.deleteObject(deleteParam, function (err, data) {
        if (err) {
          // an error occurred
          console.log(err, err.stack);
        } else {
          // successful response
          console.log("Deleted Sucessfully!");
        }
      });
    };

    deleteFromS2(deleteParam);
  });
};

export default deleteFiles;
