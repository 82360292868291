import forceLogOut from "../../utils/forceLogOut";

import { LOGOUT } from "../reducers/auth";

import { SEARCHTICKET, GETONETICKET, DELETETICKET } from "../reducers/ticket";
import { logOut } from "./auth";
import { getAttachment, getComment } from "./config";

import getEnvironment from "../../utils/environments";
const url = getEnvironment().apiKey;

//search Ticket
export const dashboardTicket = (values) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/dashboardTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Search Ticket";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    return resData;
  };
};

//search Ticket
export const searchTicket = (values) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/allTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Search Ticket";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: SEARCHTICKET,
      ticketResult: resData,
    });
  };
};

//get One Ticket
export const getOneTicket = (ticket_id) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/getTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({ ticket_id: ticket_id }),
    });

    if (!response.ok) {
      let message = "Cannot Get One Ticket";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GETONETICKET,
      oneTicket: resData,
    });
    //get Comment
    await dispatch(getComment(resData[0].ticket_id, "TICKET"));
    await dispatch(getAttachment("tickets", resData[0].ticket_id));
  };
};

//add Ticket
export const addTicket = (values) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/addTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Add Ticket";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    console.log(resData);
    console.log(resData[0].ticket_id);

    //get One Ticket
    await dispatch(getOneTicket(resData[0].ticket_id));

    return resData[0];
  };
};

//update Ticket
export const updateTicket = (values) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/updateTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Update Ticket";
      forceLogOut(response.status, message);
    }

    // const resData = await response.json();

    //get One Ticket
    dispatch(getOneTicket(values.ticket_id));
  };
};

//reOpen Ticket
export const reOpenTicket = (values) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/reopenTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot reOpen Ticket";
      forceLogOut(response.status, message);
    }

    // const resData = await response.json();

    //get One Ticket
    dispatch(getOneTicket(values.ticket_id));
  };
};

//delete Ticket
export const deleteTicket = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/deleteTicket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Ticket";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: DELETETICKET,
      ticket_id: formData.ticket_id,
    });
  };
};

//get related tickets in a group
export const getTicketGroup = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/getTicketGroup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get Ticket Group";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//get all group
export const getAllGroup = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/allGroup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get Groups";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//updateGroup
export const updateGroup = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/updateGroup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Add/Edit Groups";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//delete Ticket
export const deleteGroup = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/deleteGroup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Group";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
  };
};

//getGroupName
export const getGroupName = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/ticket/getGroupName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get Group Name";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};
