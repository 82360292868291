import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ChatIcon from "@mui/icons-material/Chat";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";

import Confirmation from "../components/Confirmation";

import * as authActions from "../../store/actions/auth";
import * as configActions from "../../store/actions/config";

import { createNotifText } from "../../utils/selectOptions";
import formatTime from "../../utils/formatTime";
import getLabel from "../../utils/getLabel";

import * as chatActions from "../../store/actions/chat";

let count = 0;

function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const { onDrawerToggle } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const [alert, setAlert] = useState([]);

  const handleLogout = async () => {
    setAnchorEl(null);
    navigate(`/`, { replace: true });
    await dispatch(authActions.logOut());
  };

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getProfile = async () => {
    console.log("jumpToNavigate: ", userData.user_id);
    const ecryptedTicketId = btoa(
      userData.user_id?.toString().padStart(7, "0")
    );
    navigate(`/MainProfile/${ecryptedTicketId}`, {});
  };

  const getUser = async () => {
    console.log("jumpToNavigate: ", userData.user_id);
    const ecryptedTicketId = btoa(
      userData.user_id?.toString().padStart(7, "0")
    );
    navigate(`/myaccount/${ecryptedTicketId}`, {});
  };

  const jumpToTicket = async (ticket_id, alert_id) => {
    handleCloseModal();
    deleteAlert(alert_id);
    console.log("jumpToTicket: ", ticket_id);
    const ecryptedTicketId = btoa(ticket_id?.toString().padStart(7, "0"));
    navigate(`/updateTicket/${ecryptedTicketId}`, {});
  };

  const deleteAlert = async (alert_id) => {
    setAlert(alert?.filter((item) => item.alert_id !== alert_id));
    await dispatch(configActions.hideAlert(alert_id));
  };

  useEffect(() => {
    const getAlerts = async () => {
      setLoading(true);
      const output = await dispatch(configActions.getAlerts());
      setAlert(output);
      setLoading(false);
    };

    if (loading) getAlerts();
  }, [loading]);

  const getCount = async () => {
    const chatDetails = await dispatch(chatActions.getChat());

    count = 0;

    chatDetails?.map((item) => {
      if (userData.user_id !== item.author_id) {
        if (item.seenusers?.includes(userData.user_id)) {
        } else {
          count = count + 1;
        }
      }
    });
  };

  useEffect(() => {
    getCount();
  }, []);

  const gotoChat = () => {
    getCount();
    navigate(`/chatMain`, {});
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            {!props.hideMenu && (
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs />
            <Grid item>
              <IconButton color="inherit" onClick={() => navigate(`/`, {})}>
                <HomeIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="inherit" onClick={() => gotoChat()}>
                <Badge badgeContent={count} color="warning">
                  <ChatIcon />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item>
              {/* <Tooltip title="Alerts • No alerts"> */}
              {/* <IconButton color="inherit" onClick={handleOpenModal}>
                <Badge badgeContent={alert?.length} color="warning">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <Modal
                // hideBackdrop
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box sx={style}>
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={() => handleCloseModal()}
                  >
                    {getLabel(sysLanguage, "9000", "9", "Back")}
                  </Button>

                  <h2 id="child-modal-title">
                    {getLabel(sysLanguage, "2000", "19", "Notification Center")}
                  </h2>
                  {!!alert &&
                    alert?.map((row, index) => (
                      <List
                        key={index}
                        sx={{
                          width: "100%",
                          // maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt={row?.alternate_name}
                              src={row?.avatar}
                            />
                          </ListItemAvatar>
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid>
                              <ListItemText
                                primary={row.alternate_name}
                                secondary={
                                  <Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      <Link
                                        href="#"
                                        onClick={() =>
                                          jumpToTicket(
                                            row.source_id,
                                            row.alert_id
                                          )
                                        }
                                      >
                                        [
                                        {row.source_id
                                          .toString()
                                          .padStart(7, "0")}
                                        ]
                                      </Link>
                                    </Typography>{" "}
                                    {createNotifText(sysLanguage, row)}
                                    <Typography variant="body2">
                                      {formatTime(row.action_dttm)}
                                    </Typography>
                                  </Fragment>
                                }
                              />
                            </Grid>
                            <Grid>
                              <IconButton
                                color="primary"
                                aria-label="like"
                                component="label"
                                onClick={() => deleteAlert(row.alert_id)}
                              >
                                <DeleteForeverIcon
                                  color="error"
                                  fontSize="large"
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </List>
                    ))}
                </Box>
              </Modal>
              {/* </Tooltip> */}
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                sx={{ p: 0.5 }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Avatar src={userData?.avatar} alt="My Avatar" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {isTrainee && (
                  <MenuItem onClick={() => getProfile()}>
                    <Typography variant="body2">
                      {getLabel(sysLanguage, "2000", "10", "My Profile")}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={() => getUser()}>
                  <Typography variant="body2">
                    {getLabel(sysLanguage, "2000", "9", "My Account")}
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Confirmation
                    buttonName={getLabel(sysLanguage, "1000", "20", "Logout")}
                    title={getLabel(sysLanguage, "1000", "21", "Logout?")}
                    context={getLabel(
                      sysLanguage,
                      "1000",
                      "22",
                      "This will take you to the Login page. Proceed?"
                    )}
                    color="error"
                    variant="outlined"
                    yesFunction={() => handleLogout()}
                  />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;

const style = {
  overflowY: "auto",
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  minHeight: 400,
  maxHeight: 700,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 10,
  p: 4,
};
