import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import getEnvironment from "../utils/environments";
const envName = getEnvironment().envName;

const persistConfig = {
  key: "main-root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: envName === "PRODUCTION" ? false : true,
});
const Persistor = persistStore(store);

export { Persistor };
export default store;
