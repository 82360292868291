import React from "react";

import { useSelector } from "react-redux";

import { BrowserRouter } from "react-router-dom";

import AuthNavigator from "../navigation/AuthNavigator";
import HomeNavigator from "../navigation/HomeNavigator";

const MainNavigator = () => {
  const isAuth = useSelector((state) => !!state.auth.token);

  return (
    <BrowserRouter>
      {isAuth ? <HomeNavigator /> : <AuthNavigator isAuth={isAuth} />}
    </BrowserRouter>
  );
};

export default MainNavigator;
