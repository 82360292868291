function Languages() {
  const langOption = [
    // { code: "", descr: "Choose Language..." },
    { code: "tl", descr: "Filipino" },
    { code: "en", descr: "English" },
    { code: "ja", descr: "日本語" },
    { code: "id", descr: "Bahasa Indonesia" },
    { code: "vi", descr: "Tiếng Việt" },
    { code: "th", descr: "ไทย" },
    // { code: "my", descr: "မြန်မာ" },
  ];

  return langOption;
}

export default Languages;
