import getLabel from "./getLabel";

export const selectLanguage = (labelArray) => {
  if (labelArray) {
    const options = [
      { value: "ja", label: "日本語" },
      { value: "en", label: "English" },
    ];

    return options;
  } else {
    return null;
  }
};

export const languageDescr = (list, value) => {
  let languageDescr = "";

  if (value) {
    let filteredArray = list && list.filter((item) => item.value === value);
    languageDescr = filteredArray[0]?.label;
  }

  return languageDescr;
};

export const yesOrNo = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: true,
        label: getLabel(labelArray, "9000", "35", "Yes"),
      },
      {
        value: false,
        label: getLabel(labelArray, "9000", "36", "No"),
      },
    ];

    return options;
  } else {
    return null;
  }
};

export const selectNotify = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: "01",
        label: getLabel(
          labelArray,
          "9000",
          "26",
          "Yes, I want to receive emails"
        ),
      },
      {
        value: "02",
        label: getLabel(labelArray, "9000", "27", "No, don't send me emails"),
      },
    ];

    return options;
  } else {
    return null;
  }
};

export const selectGender = (labelArray) => {
  if (labelArray) {
    const options = [
      { value: "M", label: getLabel(labelArray, "9000", "37", "Male") },
      { value: "F", label: getLabel(labelArray, "9000", "38", "Female") },
    ];

    return options;
  } else {
    return null;
  }
};

export const ticketStatus = (labelArray) => {
  if (labelArray) {
    const options = [
      { value: "PEN", label: getLabel(labelArray, "3000", "77", "Pending") },
      {
        value: "INP",
        label: getLabel(labelArray, "3000", "78", "In-Progress"),
      },
      { value: "RSV", label: getLabel(labelArray, "3000", "79", "Resolved") },
    ];

    return options;
  } else {
    return null;
  }
};

export const ticketPriority = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: true,
        label: getLabel(labelArray, "3000", "80", "High"),
      },
      {
        value: false,
        label: getLabel(labelArray, "3000", "81", "Normal"),
      },
    ];

    return options;
  } else {
    return null;
  }
};

export const otherInfoType = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: "TY1",
        label: getLabel(labelArray, "4000", "74", "Certification"),
      },

      { value: "TY3", label: getLabel(labelArray, "4000", "76", "Insurance") },
      {
        value: "TY7",
        label: getLabel(labelArray, "4000", "91", "JLPT"),
      },
      {
        value: "TY5",
        label: getLabel(labelArray, "4000", "78", "Skills Test"),
      },
      {
        value: "TY6",
        label: getLabel(labelArray, "4000", "92", "Social Media"),
      },
      {
        value: "TY2",
        label: getLabel(labelArray, "4000", "75", "Training"),
      },
      { value: "TY4", label: getLabel(labelArray, "4000", "77", "Vaccine") },
    ];

    return options;
  } else {
    return null;
  }
};

export const attachmentType = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: "01",
        label: getLabel(labelArray, "4000", "85", "Employment Contract"),
        active: true,
        display: true,
      },

      {
        value: "02",
        label: getLabel(labelArray, "4000", "86", "Flight (Airline) Ticket"),
        active: true,
        display: true,
      },
      {
        value: "03",
        label: getLabel(labelArray, "4000", "87", "OEC"),
        active: true,
        display: true,
      },
      {
        value: "04",
        label: getLabel(labelArray, "4000", "88", "Passport"),
        active: true,
        display: true,
      },
      {
        value: "05",
        label: getLabel(labelArray, "4000", "89", "Residence Card"),
        active: true,
        display: true,
      },
      {
        value: "99",
        label: getLabel(labelArray, "4000", "90", "Other "),
        active: true,
        display: true,
      },
    ];

    return options;
  } else {
    return null;
  }
};

export const mainRoleOption = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: "TRAINEE",
        label: getLabel(labelArray, "9000", "39", "Trainee"),
      },
      {
        value: "OFFICER",
        label: getLabel(labelArray, "9000", "40", "Officer"),
      },
    ];

    return options;
  } else {
    return null;
  }
};

export const accountStatus = (labelArray) => {
  if (labelArray) {
    const options = [
      {
        value: false,
        label: getLabel(labelArray, "9000", "28", "Active"),
      },
      {
        value: true,
        label: getLabel(labelArray, "9000", "29", "Locked Out"),
      },
    ];

    return options;
  } else {
    return null;
  }
};

export const selectListFilter = (
  list,
  filterValue,
  code,
  label,
  descrOnly,
  labelArray
) => {
  if (list?.length > 0) {
    const newList = list.filter((item) => item[code] === filterValue);
    const resultList = [];

    newList.map((item) => {
      const obj = {
        value: item[code],
        [label]: getLabel(labelArray, item.msgset, item.msgnbr, item[label]),
      };
      resultList.push(obj);
    });

    if (descrOnly === "Y") {
      return resultList[0] && resultList[0][label];
    }

    return resultList;
  }
};

export const getOptions = (labelArray, optionArray) => {
  if (!!optionArray && optionArray.length > 0) {
    let newOptionArray = [];

    optionArray.map((option) => {
      if (!!labelArray && labelArray.length > 0) {
        const labelResult = labelArray.filter(
          (item) =>
            item.msgnbr === option.msgnbr && item.msgset === option.msgset
        );
        if (!!labelResult && labelResult.length > 0) {
          newOptionArray.push({
            value: option.code,
            label: labelResult[0].label,
          });
        } else {
          newOptionArray.push({
            value: option.code,
            label: option.descr,
          });
        }
      } else {
        newOptionArray.push({
          value: option.code,
          label: option.descr,
        });
      }
    });

    return newOptionArray;
  } else {
    return [{ value: "", label: "" }];
  }
};

export const createNotifText = (labelArray, item) => {
  switch (item.action) {
    case "1":
      return (
        getLabel(labelArray, "9000", "41", "Updated the ticket status") +
        " - " +
        item.status
      );
    case "2":
      return (
        getLabel(labelArray, "9000", "42", "Ticket assignment changed") +
        " - " +
        item.action_name
      );
    case "3":
      return (
        getLabel(labelArray, "9000", "43", "Ticket priority updated") +
        " - " +
        (item.high_pri ? "High" : "Normal")
      );
    case "4":
      return getLabel(labelArray, "9000", "44", "Ticket ReOpened");
    case "5":
      return getLabel(labelArray, "9000", "45", "Ticket Deleted");
    case "6":
      return (
        getLabel(labelArray, "9000", "46", "Added a comment") +
        " - " +
        (item.text?.length > 40
          ? item.text.substring(0, 40) + "..."
          : item.text)
      );
    case "7":
      return (
        getLabel(labelArray, "9000", "47", "Added an attachment") +
        " - " +
        (item.text?.length > 40
          ? item.text.substring(0, 40) + "..."
          : item.text)
      );
    case "8":
      return (
        getLabel(labelArray, "9000", "48", "Deleted an attachment") +
        " - " +
        (item.text?.length > 40
          ? item.text?.substring(0, 40) + "..."
          : item.text)
      );
    default:
      break;
  }
};
