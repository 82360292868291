import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LinearProgress from "@mui/material/LinearProgress";

import Details from "./Details";

import Navigator from "../components/Navigator";
import Header from "../components/Header";
import Footer from "../components/Footer";

import * as ticketActions from "../../store/actions/ticket";
import * as configActions from "../../store/actions/config";

import getLabel from "../../utils/getLabel";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let output = [];
let tickets = [];

export default function BasicTabs(props) {
  const dispatch = useDispatch();

  let { group_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState(0);

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const [groupDetail, setGroupDetail] = useState();
  const [relatedTicket, setRelatedTicket] = useState();

  const companyList = useSelector((state) => state.config.companyList);
  const traineeName = useSelector((state) => state.config.traineeName);

  const [company, setCompany] = useState({ value: "", label: "" });
  const [grpMember, setGrpMember] = useState([]);

  const [modeAdd, setModeAdd] = useState(
    atob(group_id) === "new" ? true : false
  );
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [canEdit, setCanEdit] = useState(false);
  const [admTicket, setAdmTicket] = useState(false);

  const statusOption = [
    {
      label: getLabel(sysLanguage, "9000", "67", "Inactive"),
      value: false,
    },
    {
      label: getLabel(sysLanguage, "9000", "66", "Active"),
      value: true,
    },
  ];

  const [formData, setFormData] = useState({
    group_id: null,
    group_name: "",
    comments: "",
    company: "",
    group_members: [],
    active_flag: "",
  });

  const loadGroup = async () => {
    if (companyList?.length > 0) {
      //do nothing;
    } else {
      await dispatch(configActions.getCompany());
      await dispatch(configActions.getTrainee());
    }
    setLoading(true);
    const decryptedGroupId = atob(group_id);
    if (decryptedGroupId !== "new") {
      output = await dispatch(
        ticketActions.getAllGroup({
          group_id: decryptedGroupId,
          company: null,
          group_name: "",
        })
      );
      setGroupDetail(output);

      tickets = await dispatch(
        ticketActions.getTicketGroup({
          group_id: decryptedGroupId,
        })
      );
      setRelatedTicket(tickets);
    }
    setLoading(false);
  };

  const loadData = async () => {
    const selRowCompany =
      companyList &&
      companyList?.filter((item) => item.code === output[0]?.company);

    setCompany({
      value: output[0]?.company,
      label:
        selRowCompany.length > 0
          ? getLabel(
              sysLanguage,
              selRowCompany[0].msgset,
              selRowCompany[0].msgnbr,
              selRowCompany[0].descr
            )
          : "",
    });

    const traineeStaging = [];

    !!output[0] &&
      output[0].group_members?.map((member_id) => {
        const label = traineeName.filter((item) => item.value === member_id);
        traineeStaging.push(label[0]);
      });

    setGrpMember(!!traineeStaging ? traineeStaging : []);

    // const selRowStatus = statusOption().filter(
    //   (item) => item.value === groupDetail.active_flag
    // );
  };

  const isReadOnly = () => {
    const isAdmin =
      roleArray && roleArray?.some((item) => item.role_name === "TICKET_ADMIN");
    if (isAdmin) {
      setCanEdit(true);
      setAdmTicket(true);
    } else {
      const editYes =
        roleArray &&
        roleArray?.some((item) => item.role_name === "TICKET_WRITE");
      if (editYes) {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
    }
  };

  useEffect(() => {
    loadGroup();
  }, []);

  useEffect(() => {
    loadData();

    isReadOnly();
  }, [loading]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {/* {isSmUp ? null : ( */}
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          {/* )} */}
          {/* 
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          /> */}
        </Box>

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header onDrawerToggle={handleDrawerToggle} hidden={true} />
          {!loading ? (
            <div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    //   aria-label="basic tabs example"
                  >
                    <Tab
                      // iconPosition="start"
                      // icon=<ListIcon />
                      label={getLabel(sysLanguage, "3000", "24", "Details")}
                      {...a11yProps(0)}
                    />
                  </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 0.7 },
                      // "& .MuiAutocomplete-root": { mr: 0.7 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {/* {output && output.length > 0 && ( */}
                    <Details
                      groupDetail={output}
                      relatedTicket={relatedTicket}
                      companyList={companyList}
                      traineeName={traineeName}
                      setFormData={setFormData}
                      formData={formData}
                      isTrainee={isTrainee}
                      canEdit={canEdit}
                      setCompany={setCompany}
                      company={company}
                      setGrpMember={setGrpMember}
                      grpMember={grpMember}
                      setModeAdd={setModeAdd}
                      modeAdd={modeAdd}
                    />
                    {/* )} */}
                  </Box>
                </TabPanel>

                <TabPanel value={value} index={3}>
                  Item Four
                </TabPanel>
              </Box>
              <Footer />
            </div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
