import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Types() {
  return (
    <Box sx={{ m: 5, width: "100%" }}>
      <Typography variant="h3" gutterBottom>
        Page Not Found
      </Typography>
      <Link href="/">
        <Typography variant="h6" gutterBottom>
          Return to Homepage
        </Typography>
      </Link>
    </Box>
  );
}
