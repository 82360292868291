import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import { Formik, Form } from "formik";

import TableOne from "../components/TableOne";

import {
  selectListFilter,
  getOptions,
  mainRoleOption,
  accountStatus,
} from "../../utils/selectOptions";

import getLabel from "../../utils/getLabel";

import formatDate from "../../utils/formatDate";

import * as configActions from "../../store/actions/config";

const noFunction = (value) => {
  return value;
};

const trimFunction = (value) => {
  if (value && value.length > 100) {
    return value.substring(0, 100) + " ...";
  } else {
    return value;
  }
};

const companyFunction = (value) => {
  if (!value) return "All";
  if (!!value && value !== "M") return value;
  if (!!value && value === "M") return "Multiple Company";
};

const SearchBanner = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);
  const roleArray = useSelector((state) => state.auth.userRoles);
  const bannerList = useSelector((state) => state.config.bannerList);

  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState(null);
  const [show, setShow] = useState({});

  const checkAccess = () => {
    const editOne =
      roleArray &&
      roleArray?.some((item) => item.role_name === "CREATE_ANNOUNCEMENT");
    if (editOne) {
      return true;
    } else {
      return false;
    }
  };

  const headCells = [
    {
      id: "row_id",
      numeric: false,
      disablePadding: true,
      padding: "none",
      function: noFunction,
      link: true,
      label: getLabel(sysLanguage, "6000", "1", "Post ID"),
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      padding: "none",
      function: noFunction,
      link: false,
      label: getLabel(sysLanguage, "6000", "2", "Title"),
    },
    {
      id: "text",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: trimFunction,
      link: false,
      label: getLabel(sysLanguage, "6000", "3", "Text"),
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: companyFunction,
      label: getLabel(sysLanguage, "6000", "10", "Target Company"),
    },
    {
      id: "datefrom",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "6000", "15", "Active From"),
    },
    {
      id: "dateto",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "6000", "16", "Expiring On"),
    },
    {
      id: "last_updated_by",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "6000", "17", "Last Updated By"),
    },
  ];

  useEffect(() => {
    setShow({
      label: getLabel(sysLanguage, "9000", "49", "Show Active"),
      value: "A",
    });
  }, []);

  const handleSearch = async (data) => {
    setLoading(true);
    try {
      await dispatch(configActions.getAllBanner(data));
    } catch (error) {
      setLoading(false);
    }
  };

  const showOption = [
    {
      descr: getLabel(sysLanguage, "9000", "49", "Show Active"),
      code: "A",
    },
    {
      descr: getLabel(sysLanguage, "9000", "51", "Show History"),
      code: "B",
    },
    {
      descr: getLabel(sysLanguage, "9000", "50", "Show Future"),
      code: "C",
    },
  ];

  const jumpToNavigate = async (row_id) => {
    await dispatch(configActions.openOneBanner({ row_id: row_id }));
    navigate(`/MainBanner`, {});
  };

  const addNew = async () => {
    await dispatch(configActions.clearAttachment());
    await dispatch(configActions.openOneBanner({ row_id: null }));
    navigate(`/MainBanner`, {});
  };

  const rows = [];
  bannerList &&
    bannerList.map((item) => {
      let multipleFlag;

      const company = selectListFilter(
        props.companyList,
        !!item.company
          ? item.company.length > 1
            ? (multipleFlag = "M")
            : item.company[0]
          : null,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      rows.push({ ...item, company: multipleFlag === "M" ? "M" : company });
    });

  return (
    <Box sx={{ p: 1 }}>
      <Formik
        initialValues={{
          row_id: "",
          title: "",
          // text: "",
        }}
        onSubmit={(values) => {
          const addedData = {
            ...values,
            company: !!company ? company.value : null,
            show: !!show ? show.value : "A",
          };

          handleSearch(addedData);
        }}
      >
        {({ handleChange, values, resetForm }) => (
          <Form>
            <Stack spacing={1}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  id="row_id"
                  name="row_id"
                  label={getLabel(sysLanguage, "6000", "1", "Post ID")}
                  value={values.row_id}
                  onChange={handleChange("row_id")}
                  size="small"
                  sx={{ width: 100 }}
                />
                <TextField
                  id="title"
                  name="title"
                  label={getLabel(sysLanguage, "6000", "2", "Title")}
                  value={values.title}
                  onChange={handleChange("title")}
                  size="small"
                  sx={{ width: 300 }}
                />
                {/* <TextField
                  id="text"
                  name="text"
                  label="Text"
                  value={values.text}
                  onChange={handleChange("text")}
                  size="small"
                  sx={{ width: 400 }}
                /> */}
              {/* </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              > */}
                <Autocomplete
                  id="company"
                  name="company"
                  value={company}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => setCompany(data)}
                  size="small"
                  options={getOptions(sysLanguage, props.companyList)}
                  sx={{ width: 350 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "6000",
                        "4",
                        "Select Company"
                      )}
                    />
                  )}
                />
                <Autocomplete
                  id="show"
                  name="show"
                  value={show}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => setShow(data)}
                  size="small"
                  options={getOptions(sysLanguage, showOption)}
                  sx={{ width: 180 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(sysLanguage, "6000", "5", "Show")}
                    />
                  )}
                />
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "flex-end",
                  mt: 1,
                }}
              >
                <Button
                  sx={{ m: 1, width: 150 }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  {getLabel(sysLanguage, "9000", "1", "Search")}
                </Button>
                <Button
                  sx={{ m: 1, width: 150 }}
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    setCompany(null);

                    resetForm();
                  }}
                >
                  {getLabel(sysLanguage, "9000", "2", "Clear")}
                </Button>
                {checkAccess() && (
                  <Grid container justifyContent="flex-end">
                    <Button
                      sx={{ m: 1 }}
                      color="primary"
                      variant="contained"
                      onClick={addNew}
                    >
                      {getLabel(
                        sysLanguage,
                        "6000",
                        "6",
                        "Create Announcement"
                      )}
                    </Button>
                  </Grid>
                )}
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
      {rows.length > 0 && (
        <TableOne
          headCells={headCells}
          rows={rows}
          jumpToNavigate={jumpToNavigate}
        />
      )}
    </Box>
  );
};

export default SearchBanner;
