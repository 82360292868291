import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import * as userActions from "../../store/actions/user";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import getLabel from "../../utils/getLabel";

const PasswordChange = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(getLabel(sysLanguage, "5000", "28", "Password is required"))
      .min(
        6,
        getLabel(sysLanguage, "5000", "53", "Your password is too short.")
      )
      .max(50, getLabel(sysLanguage, "5000", "54", "Password is too long.")),
    password2: Yup.string()
      .required(
        getLabel(sysLanguage, "5000", "55", "Confirm Password is required.")
      )
      .oneOf(
        [Yup.ref("password"), null],
        getLabel(sysLanguage, "5000", "56", "Passwords must match.")
      ),
  });

  const ErrorText = (props) => {
    return (
      <div style={{ marginLeft: 10, marginBottom: 10 }}>
        <Typography style={{ color: "#FF0000" }} variant="caption">
          {props.label}
        </Typography>
      </div>
    );
  };

  const handleSave = async (formData) => {
    try {
      setLoadingSave(true);
      await dispatch(userActions.updatePassword(formData));
      setLoadingSave(false);

      setLoadingAlert(true);
      setTimeout(() => {
        setLoadingAlert(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const checkAccess = () => {
    if (props.searchOneUser.role_name === "TRAINEE") {
      if (
        userData.user_id === props.searchOneUser.user_id ||
        props.canEditTrainee ||
        props.canEditOfficer
      )
        return false;
      else {
        return true;
      }
    } else {
      if (
        userData.user_id === props.searchOneUser.user_id ||
        props.canEditOfficer
      )
        return false;
      else {
        return true;
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email_id: props.searchOneUser.email_id,
          password: "",
          password2: "",
        }}
        validationSchema={PasswordSchema}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {({ handleChange, values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Divider textAlign="left">
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "5000", "25", "Password Change")}
                  </Typography>
                </Divider>
                <TextField
                  disabled={checkAccess()}
                  fullWidth
                  autoComplete="off"
                  type="password"
                  id="password"
                  name="password"
                  label={getLabel(sysLanguage, "5000", "26", "New Password")}
                  value={values.password}
                  onChange={handleChange("password")}
                  size="small"
                  sx={{ mb: 1 }}
                />
                {errors.password && touched.password ? (
                  <ErrorText label={errors.password} />
                ) : null}
                <TextField
                  disabled={checkAccess()}
                  fullWidth
                  type="password"
                  id="password2"
                  name="confirm_password"
                  label={getLabel(
                    sysLanguage,
                    "5000",
                    "27",
                    "Confirm New Password"
                  )}
                  value={values.confirm_password}
                  onChange={handleChange("password2")}
                  size="small"
                  sx={{ mb: 1 }}
                />
                {errors.password2 && touched.password2 ? (
                  <ErrorText label={errors.password2} />
                ) : null}
              </Grid>
            </Grid>
            <LoadingButton
              disabled={checkAccess()}
              type="submit"
              loading={loadingSave}
              variant="contained"
              color="primary"
              sx={{ m: 1 }}
            >
              {getLabel(sysLanguage, "9000", "6", "Save")}
            </LoadingButton>
            <Button
              sx={{ m: 1 }}
              color="warning"
              variant="contained"
              onClick={() =>
                props.isTrainee || props.myAccount
                  ? navigate(`/`, {})
                  : navigate(`/manageUser`, {})
              }
            >
              {getLabel(sysLanguage, "9000", "7", "Back")}
            </Button>
          </Form>
        )}
      </Formik>
      {loadingAlert && (
        <div>
          <Alert severity="success">Password Updated!</Alert>
        </div>
      )}
    </div>
  );
};

export default PasswordChange;
