import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";

import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Formik, Form } from "formik";

import formatDate from "../../utils/formatDate";
import { getOptions, selectListFilter } from "../../utils/selectOptions";

import Confirmation from "../components/Confirmation";

import * as profileActions from "../../store/actions/profile";
import getLabel from "../../utils/getLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VisaInfo = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const trainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const oneProfile = useSelector((state) => state.profile.searchOneProfile);

  const visaProfile = useSelector((state) => state.profile.visaProfile);

  const [addMode, setAddMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const [visa, setVisa] = useState({});

  const [receivedDt, setReceivedDt] = useState(null);
  const [expirationDt, setExpirationDt] = useState(null);

  const [open, setOpen] = useState(false);

  const [rowDetail, setRowDetail] = useState({
    business_unit: "",
    user_id: null,
    row_id: null,
    visa_code: "",
    visa_num: "",
    visa_received_dt: null,
    visa_expiration_dt: null,
  });

  const handleEdit = (rowData) => {
    setAddMode(false);

    setRowDetail({
      business_unit: rowData.business_unit,
      user_id: rowData.user_id,
      row_id: rowData.row_id,
      visa_code: rowData.visa_code,
      visa_num: rowData.visa_num,
    });

    setReceivedDt(rowData.visa_received_dt);
    setExpirationDt(rowData.visa_expiration_dt);

    setOpen(true);
  };

  const handleAdd = (rowData) => {
    setAddMode(true);

    setRowDetail({
      business_unit: oneProfile.business_unit,
      user_id: oneProfile.user_id,
      row_id: "New",
      visa_code: "",
      visa_num: "",
    });

    setReceivedDt(null);
    setExpirationDt(null);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = async (formData) => {
    try {
      setLoading(true);
      if (addMode) {
        await dispatch(profileActions.addUserVisa(formData));
      } else {
        await dispatch(profileActions.editUserVisa(formData));
      }
      setLoading(false);

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    try {
      await dispatch(profileActions.delUserVisa(formData));
      setOpen(false);
    } catch (error) {}
  };

  const rows = [];
  visaProfile &&
    visaProfile.map((item) => {
      const visaDescr = selectListFilter(
        props.visaList,
        item.visa_code,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      rows.push({ ...item, visaDescr: visaDescr });
    });

  useEffect(() => {
    let selRow = [];

    selRow = props.visaList.filter((item) => item.code === rowDetail.visa_code);
    setVisa({
      value: rowDetail.visa_code,
      label:
        selRow.length > 0
          ? getLabel(
              sysLanguage,
              selRow[0].msgset,
              selRow[0].msgnbr,
              selRow[0].descr
            )
          : "",
    });
  }, [rowDetail]);

  const handleReceivedDt = (newValue) => {
    setReceivedDt(newValue);
  };

  const handleExpDt = (newValue) => {
    setExpirationDt(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Stack spacing={2}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {getLabel(sysLanguage, "4000", "42", "Visa Information")}
                </Typography>

                <Formik
                  initialValues={{
                    business_unit: rowDetail.business_unit,
                    user_id: rowDetail.user_id,
                    row_id: rowDetail.row_id,
                    visa_num: rowDetail.visa_num,
                  }}
                  // validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    const addedData = {
                      ...values,
                      visa_code: visa.value,
                      visa_received_dt: receivedDt
                        ? new Date(receivedDt).toLocaleDateString()
                        : null,
                      visa_expiration_dt: expirationDt
                        ? new Date(expirationDt).toLocaleDateString()
                        : null,
                    };
                    handleSave(addedData);
                  }}
                >
                  {({
                    handleChange,
                    values,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <Stack spacing={2}>
                        <TextField
                          disabled
                          id="row"
                          name="row"
                          label="Row ID"
                          value={values.row_id}
                          size="small"
                          sx={{ width: 100 }}
                        />

                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Autocomplete
                            id="visa"
                            name="visa"
                            value={visa}
                            getOptionLabel={(option) => option.label || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            onChange={(e, data) => setVisa(data)}
                            options={getOptions(sysLanguage, props.visaList)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "44",
                                  "Visa Status"
                                )}
                              />
                            )}
                            size="small"
                            sx={{ width: 250 }}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <TextField
                            id="visa_num"
                            name="visa_num"
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "45",
                              "Visa Number"
                            )}
                            value={values.visa_num}
                            onChange={handleChange("visa_num")}
                            size="small"
                            sx={{ width: 400 }}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "46",
                                "Date of Permission"
                              )}
                              inputFormat="yyyy/MM/dd"
                              value={receivedDt}
                              onChange={handleReceivedDt}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  sx={{ width: 200 }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "47",
                                "Period of Stay"
                              )}
                              inputFormat="yyyy/MM/dd"
                              value={expirationDt}
                              onChange={handleExpDt}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  size="small"
                                  {...params}
                                  sx={{ width: 200 }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            alignContent: "flex-end",
                            mt: 3,
                          }}
                        >
                          <LoadingButton
                            disabled={!props.canEdit}
                            type="submit"
                            loading={loading}
                            variant="contained"
                            color="primary"
                            sx={{ m: 1 }}
                          >
                            {getLabel(sysLanguage, "9000", "6", "Save")}
                          </LoadingButton>
                          <Button
                            sx={{ m: 1, width: 200 }}
                            color="warning"
                            variant="contained"
                            onClick={() => setOpen(false)}
                          >
                            {getLabel(sysLanguage, "9000", "7", "Cancel")}
                          </Button>
                          {!addMode && (
                            <>
                              <Grid container justifyContent="flex-end">
                                <Confirmation
                                  disabled={!props.canEdit}
                                  buttonName={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "49",
                                    "Delete"
                                  )}
                                  title={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "49",
                                    "Delete"
                                  )}
                                  context={getLabel(
                                    sysLanguage,
                                    "9000",
                                    "5",
                                    "Are you sure you want to delete this item?"
                                  )}
                                  color="error"
                                  variant="outlined"
                                  yesFunction={() => handleDelete(values)}
                                />
                              </Grid>
                            </>
                          )}
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </Box>
          </Fade>
        </Modal>
        <Grid container justifyContent="flex-start">
          <Button
            disabled={!props.canEdit}
            color="primary"
            variant="contained"
            onClick={handleAdd}
          >
            {getLabel(sysLanguage, "4000", "43", "Add New Visa")}
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {getLabel(sysLanguage, "4000", "44", "Visa Status")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "4000", "45", "Visa Number")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "4000", "46", "Date of Permission")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "4000", "47", "Period of Stay")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.row_id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.visaDescr}
                  </TableCell>
                  <TableCell align="right">{row.visa_num}</TableCell>
                  <TableCell align="right">
                    {formatDate(row.visa_received_dt)}
                  </TableCell>
                  <TableCell align="right">
                    {formatDate(row.visa_expiration_dt)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" sx={{ p: 0.5 }}>
                      <ModeEditIcon onClick={() => handleEdit(row)} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() =>
              trainee
                ? navigate(`/`, { replace: true })
                : navigate(`/traineeProfile`, { replace: true })
            }
          >
            {getLabel(sysLanguage, "9000", "9", "Back")}
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

export default VisaInfo;
