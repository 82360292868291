const colors = {
  primary_color: "#218aff",
  accent_color: "#eee",

  recepient_chat_bubble_border: "#c8c8c8",

  contrasted_primary_text: "#fff",
  contrasted_accent_text: "#111",
  datetime_text: "#777777",

  chat_title_shadow: "#a1a1a1",
  chat_shell_background: "#1769aa",

  reply_color: "#121858",
};

export default colors;
