import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";

import Modal from "@mui/material/Modal";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsIcon from "@mui/icons-material/Settings";

import _ from "lodash";
import { io } from "socket.io-client";

import { v4 as uuid } from "uuid";

import S3 from "react-aws-s3";
import AWS from "aws-sdk";

import FlatList from "flatlist-react";

import MessageBox from "./MessageBox";
import SeenChat from "./SeenChat";

import getEnvironment from "../../utils/environments";
import getLabel from "../../utils/getLabel";

import Confirmation from "../components/Confirmation";

import * as chatActions from "../../store/actions/chat";

import NewChatGroup from "./NewChatGroup";

const url = getEnvironment().apiKey;
const socket = io.connect(url);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 300,
  height: "60%",
  bgcolor: "background.paper",
  border: "1px solid #1976d2",
  boxShadow: 18,
  p: 4,
};

const ChatBox = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const awsKey = useSelector((state) => state.config.awsKey);

  const companyList = useSelector((state) => state.config.companyList);
  const allNames = useSelector((state) => state.config.allUsers);

  const chatMessages = [];

  const [inputMsg, setInputMsg] = useState();
  const [sendOnEnter, setSendOnEnter] = useState(true);

  const [asIs, setAsIs] = useState(false);

  const [isInitTyping, setIsInitTyping] = useState(true);
  const [isTyping, setIsTyping] = useState(false);

  const [messages, setMessages] = useState([]);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  //Attachment
  const [keyName, setKeyName] = useState();
  const [fileName, setFileName] = useState();
  const [fileType, setFileType] = useState();

  const [uploadedFile, setUploadedFile] = useState();

  const [seenData, setSeenData] = useState([]);
  const [seenModal, setSeenModal] = useState(false);
  const [loadSeen, setLoadSeen] = useState(false);

  const [newGroup, setNewGroup] = useState(false);
  // const [chatGrpName, setChatGrpName] = useState("");
  const [chatGrpPatricipants, setChatGrpPatricipants] = useState([]);

  let finalRoomID;
  let newMessage;

  finalRoomID = props.chatInfo?.chat_hdr_id || uuid();

  const messagesTopRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesTopRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getAttribute = (participants) => {
    const selectedId = participants?.filter(
      (item) => item !== userData.user_id
    );
    const result =
      !!allNames && allNames.filter((item) => item.code === selectedId[0]);

    const companyName = companyList.filter(
      (item) => item.code === result[0]?.company
    );

    if (result[0]?.role_name === "OFFICER") {
      return result[0]?.position;
    } else {
      return !!companyName[0]?.code
        ? "[" + companyName[0]?.code + "] " + companyName[0]?.descr
        : null;
    }
  };

  const getReceiver = (participants) => {
    const selectedId = participants?.filter(
      (item) => item !== userData.user_id
    );

    return !!selectedId ? selectedId[0] : null;
  };

  const addInfoToName = (name, user_id) => {
    const result =
      !!allNames && allNames.filter((item) => item.code === user_id);

    const companyName = companyList.filter(
      (item) => item.code === result[0]?.company
    );

    if (result[0]?.role_name === "OFFICER") {
      return name + " [" + result[0]?.position + "] ";
    } else {
      return !!companyName[0]?.code
        ? name + " [" + companyName[0]?.code + "] " + companyName[0]?.descr
        : name + null;
    }
  };

  const getParticipants = (participants) => {
    const list = [];

    participants?.map((item) => {
      const currentIndex = allNames?.findIndex(
        (findItem) => findItem.value === item
      );
      // if (currentIndex > 0 && item !== userData.user_id) {
      list.push({
        value: allNames[currentIndex]?.code,
        label: addInfoToName(
          allNames[currentIndex]?.descr,
          allNames[currentIndex]?.code
        ),
      });
      // }
    });

    return list;
  };

  useEffect(() => {
    loadChatBox(props.chatInfo);
  }, [dispatch, props.chatInfo.chat_hdr_id]);

  const loadChatBox = useCallback(
    async (item) => {
      setError(null);
      try {
        setLoading(true);
        const fetchedMsgs = await dispatch(
          chatActions.getMessages(item.chat_hdr_id)
        );
        // console.log(fetchedMsgs);
        props.setChatMessage(fetchedMsgs);
        setLoading(false);

        if (item.chat_hdr_id) {
          await socket.emit("join_room", item.chat_hdr_id);
          if (!!fetchedMsgs[0]?._id) {
            await dispatch(
              chatActions.seenChatMark(item.chat_hdr_id, fetchedMsgs[0]?._id)
            );
          }
        }
      } catch (err) {
        setError(err.message);
        console.log(err);
      }
    },
    [dispatch, loading, props.chatInfo.chat_hdr_id]
  );

  const updateChatSeenTbl = useCallback(
    async (messages) => {
      try {
        await dispatch(chatActions.seenChatMark(finalRoomID, messages[0]?._id));
      } catch (err) {
        setError(err.message);
      }
    },
    [socket]
  );

  //listen to new messages
  useEffect(() => {
    socket.on(finalRoomID, (data) => {
      // console.log("Received: ", data);
      props.setChatMessage(data.concat(props.chatMessage));
      updateChatSeenTbl(data);
      scrollToBottom();
    });

    return () => {
      socket.off(finalRoomID);
    };
  }, [socket, chatMessages]);

  //show is typing ...
  useEffect(() => {
    const keyVal = finalRoomID + "showTyping";
    socket.on(keyVal, (data) => {
      setIsInitTyping(true);

      const startTyping = _.debounce(
        () => {
          if (!isInitTyping) return;
          setIsTyping(true);
        },
        500,
        { leading: true, trailing: false }
      );

      const stopTyping = _.debounce(() => {
        if (!isInitTyping) return;
        setIsTyping(false);
      }, 1000);

      if (data.text) {
        startTyping();
        stopTyping();
      }
    });

    return () => {
      socket.off(keyVal);
    };
  }, [socket]);

  const detectTyping = (text) => {
    if (!!text) {
      setIsInitTyping(true);

      const data = {
        chat_hdr_id: finalRoomID,
        name: userData.alternate_name,
        text: text,
      };
      socket.emit("is_typing", data);
    }
  };

  //remove message
  useEffect(() => {
    const keyVal = finalRoomID + "removeMsg";
    socket.on(keyVal, (data) => {
      props.setChatMessage((prevValue) =>
        prevValue.filter((item) => item._id.trim() !== data.trim())
      );
    });

    return () => {
      socket.off(keyVal);
    };
  }, [socket, dispatch, chatMessages]);

  const handleChange = (event) => {
    detectTyping(event.target.value);
    setInputMsg(event.target.value);
  };

  const uploadFileSS = (file) => {
    setFileType("image");
    setFileName(file.name);

    const config = {
      bucketName: awsKey.bucket,
      dirName: "chatmessaging",
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    };

    //Delete the image that is being replaced by new attachment
    if (keyName) deleteFile();

    const ReactS3Client = new S3(config);

    ReactS3Client.uploadFile(file, file.name)
      .then((data) => {
        setUploadedFile(data.location);
        setKeyName(data.key);
      })
      .catch((err) => console.error(err));
  };

  const uploadFile = (event) => {
    console.log(event);

    //check filetype
    const fType = event.target.files[0];

    if (fType.type.includes("image")) setFileType("image");

    setFileName(fType.name);

    const config = {
      bucketName: awsKey.bucket,
      dirName: "chatmessaging",
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    };

    //Delete the image that is being replaced by new attachment
    if (keyName) deleteFile();

    const ReactS3Client = new S3(config);

    const random = uuid();
    const newFileName =
      userData.user_id + "_" + random + "_" + event.target.files[0].name;

    ReactS3Client.uploadFile(event.target.files[0], newFileName)
      .then((data) => {
        setUploadedFile(data.location);
        setKeyName(data.key);
      })
      .catch((err) => console.error(err));
  };

  const deleteFile = async () => {
    AWS.config.update({
      region: awsKey.region,
      accessKeyId: awsKey.accessKey,
      secretAccessKey: awsKey.secretKey,
    });
    const s3 = new AWS.S3();

    const deleteParam = {
      Bucket: awsKey.bucket,
      Key: keyName,
    };

    await s3.deleteObject(deleteParam, function (err, data) {
      if (err) {
        // an error occurred
        console.log(err, err.stack);
      } else {
        // successful response
        setUploadedFile("");
        console.log("Deleted Sucessfully!");
      }
    });
  };

  const deleteMessage = async (chat_hdr_id, message_id, key) => {
    try {
      // console.log("deleteMessage: ", chat_hdr_id, message_id, key);
      await dispatch(chatActions.deleteMessage(chat_hdr_id, message_id, key));
      props.setChatMessage(
        props.chatMessage.filter(
          (item) => item._id.trim() !== message_id.trim()
        )
      );

      props.chatInfo.participants?.map((item) => {
        const data = {
          recipient: item,
          sender: userData.user_id,
        };
        socket.emit("refreshChat", data);
        socket.emit("remove_message", chat_hdr_id, message_id);
      });

      if (key) {
        AWS.config.update({
          region: awsKey.region,
          accessKeyId: awsKey.accessKey,
          secretAccessKey: awsKey.secretKey,
        });

        const s3 = new AWS.S3();

        const deleteParam = {
          Bucket: awsKey.bucket,
          Key: key,
        };

        await s3.deleteObject(deleteParam, function (err, data) {
          if (err) {
            // an error occurred
            console.log(err, err.stack);
          } else {
            // successful response
            console.log("Deleted Sucessfully!");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const leavePrivateChat = async (chat_hdr_id, participants) => {
    try {
      console.log(chat_hdr_id, participants);
      props.setChatListSearch(
        props.chatListSearch.filter((item) => item.chat_hdr_id !== chat_hdr_id)
      );
      await dispatch(chatActions.leavePersonalChat(chat_hdr_id, participants));
      props.setChatInfo();
    } catch (error) {}
  };

  const deleteGroupChat = async (chat_hdr_id) => {
    try {
      props.setChatListSearch(
        props.chatListSearch.filter((item) => item.chat_hdr_id !== chat_hdr_id)
      );
      await dispatch(chatActions.deleteChatGroup(chat_hdr_id));
      props.setChatInfo();
    } catch (error) {}
  };

  const onSend = (messages) => {
    const currentDate = new Date().toISOString();

    newMessage = {
      user: {
        business_unit: userData.business_unit,
        _id: userData.user_id,
        name: userData.alternate_name,
        avatar: !!userData.avatar ? userData.avatar : null,
      },
      chat_hdr_id: finalRoomID,
      _id: uuid(),
      author_id: userData.user_id,
      avatar: userData.avatar,
      text: inputMsg,
      image: uploadedFile,
      key: keyName,
      chat_type: props.chatInfo.chat_type,
      chat_name: props.chatInfo.chat_name,
      receiverId:
        props.chatInfo.chat_type === "G"
          ? getReceiver(props.chatInfo?.participants)
          : props.chatInfo?.chat_name,
      senderName: userData.alternate_name,
      createdAt: currentDate,
      participants:
        props.chatInfo.chat_type === "G"
          ? props.chatInfo?.participants
          : [userData.user_id, props.chatInfo?.chat_name],
    };

    // console.log("Message Sent", [newMessage]);

    props.setChatMessage((previousMessages) => [
      ...previousMessages,
      newMessage,
    ]);

    socket.emit("send_message", newMessage);

    props.chatInfo?.participants?.map((item) => {
      const data = {
        recipient: item,
        sender: userData.user_id,
      };
      socket.emit("refreshChat", data);
    });

    scrollToBottom();
    setInputMsg("");
    setFileName("");
    setKeyName("");
    setUploadedFile("");
  };

  const renderItem = (item, idx) => {
    return (
      <Box key={idx}>
        <MessageBox
          chat_hdr_id={props.chatInfo.chat_hdr_id || uuid()}
          chat_msg_id={item._id || uuid()}
          setAsIs={setAsIs}
          asIs={asIs}
          author_id={item.author_id}
          avatar={item.user.avatar}
          text={item.text}
          createdAt={item.createdAt}
          chat_type={props.chatInfo?.chat_type}
          fileKey={item.key}
          image={item.image}
          traineename={item.traineename}
          chat_reply_to_id={item.chat_reply_to_id}
          reply_text={item.reply_text}
          messagesEndRef={messagesEndRef}
          deleteMessage={deleteMessage}
          setSeenModal={setSeenModal}
          seenModal={seenModal}
          setLoadSeen={setLoadSeen}
          setSeenData={setSeenData}
        />
      </Box>
    );
  };

  const handlePaste = (event) => {
    const clipboardItems = event.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return /^image\//.test(item.type);
    });

    if (items.length === 0) {
      return;
    }
    const item = items[0];
    const blob = item.getAsFile();

    const random = uuid();

    let file = new File(
      [blob],
      "Screenshot_" + random,
      { type: "image/jpeg", lastModified: new Date().getTime() },
      "utf-8"
    );

    let container = new DataTransfer();
    container.items.add(file);

    uploadFileSS(container.files[0]);
  };

  return (
    <Fragment>
      {loading ? (
        <Box sx={{ width: "100%", paddingTop: 2 }}>
          <LinearProgress />
        </Box>
      ) : (
        <Fragment>
          <NewChatGroup
            setNewGroup={setNewGroup}
            newGroup={newGroup}
            setChatInfo={props.setChatInfo}
            chatInfo={props.chatInfo}
            participantsList={getParticipants(chatGrpPatricipants)}
            setChatGrpName={props.setChatGrpName}
            chatName={props.chatGrpName}
            mode="edit"
          />
          <Box
            sx={{
              paddingTop: 2,
              paddingBottom: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: "#7b1fa2", lineHeight: "12px" }}
                >
                  {props.chatGrpName}
                </Typography>
                <Typography variant="caption" sx={{ color: "#7b1fa2" }}>
                  {!!props.chatInfo && props.chatInfo.chat_type === "P"
                    ? getAttribute(props.chatInfo?.participants)
                    : getLabel(sysLanguage, "6000", "31", "Group Chat")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                {props.chatInfo.chat_type === "G" && (
                  <IconButton
                    sx={{ marginRight: "1px" }}
                    edge="end"
                    aria-label="settings"
                    onClick={async () => {
                      const chatDetails = await dispatch(
                        chatActions.getChatDetails(props.chatInfo.chat_hdr_id)
                      );
                      props.setChatGrpName(chatDetails.chat_name);
                      setChatGrpPatricipants(chatDetails.participants);
                      setNewGroup(true);
                    }}
                  >
                    <SettingsIcon color="info" fontSize="medium" />
                  </IconButton>
                )}
                <Fragment>
                  {props.chatInfo.chat_type === "P" && (
                    <Confirmation
                      icon={true}
                      buttonName={getLabel(sysLanguage, "4000", "49", "Delete")}
                      title={getLabel(sysLanguage, "4000", "49", "Delete")}
                      context={getLabel(
                        sysLanguage,
                        "9000",
                        "5",
                        "Are you sure you want to delete this item?"
                      )}
                      color="error"
                      variant="outlined"
                      yesFunction={() =>
                        leavePrivateChat(
                          props.chatInfo?.chat_hdr_id,
                          props.chatInfo?.participants
                        )
                      }
                    />
                  )}
                  {props.chatInfo.chat_type === "G" &&
                    props.chatInfo?.author_id === userData.user_id && (
                      <Confirmation
                        icon={true}
                        buttonName={getLabel(
                          sysLanguage,
                          "6000",
                          "39",
                          "Delete"
                        )}
                        title={getLabel(sysLanguage, "6000", "39", "Delete")}
                        context={getLabel(
                          sysLanguage,
                          "6000",
                          "39",
                          "Are you sure you want to delete this Chat Group?"
                        )}
                        color="error"
                        variant="outlined"
                        yesFunction={() =>
                          deleteGroupChat(props.chatInfo?.chat_hdr_id)
                        }
                      />
                    )}
                </Fragment>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              height: window.innerHeight * 0.6,
              paddingY: 2,
              paddingX: 0.2,
            }}
          >
            <Modal
              open={seenModal}
              aria-labelledby="Seen Modal"
              aria-describedby="Seen Modal Component"
              closeAfterTransition
            >
              <Box sx={modalStyle}>
                <SeenChat
                  seenData={seenData}
                  loadSeen={loadSeen}
                  setSeenModal={setSeenModal}
                />
              </Box>
            </Modal>
            <FlatList
              list={props.chatMessage}
              renderItem={renderItem}
              // renderWhenEmpty={() => <div>List is empty!</div>}
              sort={{
                by: "createdAt",
                descending: false,
              }}
              hasMoreItems={true}
            />
          </Box>
          <Box sx={{ height: 10, fontStyle: "italic" }}>
            <Typography
              sx={{ fontSize: ".8rem", color: "#9e9e9e", margin: ".3rem" }}
            >
              {isTyping ? props.chatInfo?.traineename + " is typing..." : "   "}
            </Typography>
          </Box>
          <Box sx={{ paddingX: 1 }}>
            <TextField
              fullWidth
              onPaste={handlePaste}
              style={styles.textInput}
              multiline={!sendOnEnter}
              maxRows={3}
              onChange={handleChange}
              value={inputMsg}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !!inputMsg?.trim() && sendOnEnter) {
                  onSend(props);
                  scrollToBottom();
                  setInputMsg("");
                  setUploadedFile("");
                }
              }}
              InputProps={{
                startAdornment: (
                  <Fragment>
                    <input
                      style={{
                        display: "none",
                      }}
                      id="choose-image"
                      type="file"
                      name="file"
                      accept="image/*"
                      onChange={uploadFile}
                    />
                    <label htmlFor="choose-image">
                      <ImageIcon color="primary" fontSize="medium" />
                    </label>
                    <input
                      style={{
                        display: "none",
                      }}
                      id="choose-file"
                      type="file"
                      name="file"
                      accept="application/pdf"
                      onChange={uploadFile}
                    />
                    <label htmlFor="choose-file">
                      <AttachFileIcon
                        color="primary"
                        fontSize="medium"
                        style={{
                          margin: 10,
                        }}
                      />
                    </label>
                    {uploadedFile && (
                      <>
                        {fileType === "image" ? (
                          <>
                            <Box
                              component="img"
                              sx={{
                                borderRadius: 2,
                                margin: 0.5,
                                height: 100,
                                // width: 150,
                                maxWidth: "40%",
                              }}
                              src={uploadedFile}
                            />
                          </>
                        ) : (
                          <>
                            {"... " + fileName.substring(fileName.length / 2)}
                          </>
                        )}
                        <IconButton
                          sx={{ marginRight: "10px" }}
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteFile()}
                        >
                          <DeleteForeverIcon color="error" fontSize="medium" />
                        </IconButton>
                      </>
                    )}
                  </Fragment>
                ),
                endAdornment: (
                  <IconButton
                    disabled={!inputMsg?.trim()}
                    edge="end"
                    aria-label="send"
                    onClick={() => !!inputMsg?.trim() && onSend(props.chatInfo)}
                  >
                    <SendIcon color="primary" fontSize="medium" />
                  </IconButton>
                ),
              }}
            />
            <FormControlLabel
              sx={{ marginLeft: 1 }}
              control={
                <Checkbox
                  color="primary"
                  checked={sendOnEnter}
                  onClick={() => setSendOnEnter(!sendOnEnter)}
                />
              }
              label={
                <Typography style={{ fontSize: ".8rem" }}>
                  {getLabel(
                    sysLanguage,
                    "6000",
                    "43",
                    " Send message on Enter ..."
                  )}
                </Typography>
              }
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ChatBox;

const styles = {
  textInput: {
    marginTop: "1rem",
    fontSize: "0.7rem",
    backgroundColor: "#fff",
    whiteSpace: "normal",
  },
};
