import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LinearProgress from "@mui/material/LinearProgress";
import Badge from "@mui/material/Badge";

import ListIcon from "@mui/icons-material/List";
import ForumIcon from "@mui/icons-material/Forum";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import Details from "./Details";
import Comment from "./Comment";
import FileAttachment from "../components/FileAttachment";

import Navigator from "../components/Navigator";
import Header from "../components/Header";
import Footer from "../components/Footer";

import * as ticketActions from "../../store/actions/ticket";

import * as authActions from "../../store/actions/auth";
import * as configActions from "../../store/actions/config";

import { ticketStatus, ticketPriority } from "../../utils/selectOptions";

import getLabel from "../../utils/getLabel";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let output = [];

export default function BasicTabs(props) {
  const dispatch = useDispatch();

  let { ticket_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState(0);

  const sysLanguage = useSelector((state) => state.config.labels);

  const oneTicketDetail = useSelector((state) => state.ticket.oneTicketDetail);
  const fileList = useSelector((state) => state.config.attachments);
  const ticketComments = useSelector((state) => state.config.oneComment);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const profileOfficer =
    roleArray &&
    roleArray?.some(
      (item) =>
        item.role_name === "PROFILE_READ" || item.role_name === "PROFILE_WRITE"
    );

  const companyList = useSelector((state) => state.config.companyList);
  const issueList = useSelector((state) => state.config.issueList);
  const traineeName = useSelector((state) => state.config.traineeName);
  const officerName = useSelector((state) => state.config.officerName);
  const visaList = useSelector((state) => state.config.visaList);
  const occupationList = useSelector((state) => state.config.occupationList);

  const [groupName, setGroupName] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [canEdit, setCanEdit] = useState(false);
  const [admTicket, setAdmTicket] = useState(false);

  const [formData, setFormData] = useState({
    ticket_id: null,
    title: "",
    description: "",
    resolution: "",
    company: {},
    trainee: {},
    group: {},
    inCharge: {},
    watcher: [],
    status: {},
    type: {},
    priority: {},
    issueDate: null,
    closeDate: null,
  });

  const loadTicket = async () => {
    try {
      if (!!oneTicketDetail.company) {
        output = await dispatch(
          ticketActions.getGroupName({
            company: oneTicketDetail.company,
          })
        );
        setGroupName(output);
      }

      if (officerName?.length > 0) {
        //do nothing;
      } else {
        await dispatch(authActions.loadAccessUser());
        await dispatch(configActions.getCompany());
        await dispatch(configActions.getIssue());
        await dispatch(configActions.getTrainee());
        await dispatch(configActions.getOfficer());
      }

      // setLoading(true);

      const decryptedTicketId = atob(ticket_id?.toString().padStart(7, "0"));
      await dispatch(ticketActions.getOneTicket(decryptedTicketId));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const loadData = async () => {
    const selRowCompany =
      companyList &&
      companyList?.filter((item) => item.code === oneTicketDetail.company);

    const selRowTrainee =
      traineeName &&
      traineeName?.filter((item) => item.value === oneTicketDetail.owner_id);

    const selRowGroup =
      output &&
      output?.filter((item) => item.value === oneTicketDetail.group_id);

    const selRowInCharge =
      !!officerName &&
      officerName?.filter((item) => item.value === oneTicketDetail.assigned_to);

    const watcherStaging = [];

    !!oneTicketDetail.watcher &&
      oneTicketDetail.watcher.map((watcher_id) => {
        const label =
          !!officerName &&
          officerName?.filter((item) => item.value === watcher_id);
        watcherStaging.push(label[0]);
      });

    const selRowStatus = ticketStatus(sysLanguage).filter(
      (item) => item.value === oneTicketDetail.status
    );

    const selRowPri = ticketPriority(sysLanguage).filter(
      (item) => item.value === oneTicketDetail.high_pri
    );

    const selRowType =
      issueList &&
      issueList?.filter((item) => item.code === oneTicketDetail.type);

    setFormData({
      ...formData,
      title: oneTicketDetail.title,
      description: oneTicketDetail.description,
      resolution: oneTicketDetail.resolution,
      ticket_id: oneTicketDetail.ticket_id,
      company: {
        value: oneTicketDetail.company,
        label:
          selRowCompany.length > 0
            ? getLabel(
                sysLanguage,
                selRowCompany[0].msgset,
                selRowCompany[0].msgnbr,
                selRowCompany[0].descr
              )
            : "",
      },
      trainee: {
        value: oneTicketDetail.owner_id,
        label: selRowTrainee?.length > 0 ? selRowTrainee[0]?.label : "",
      },
      group: {
        value: oneTicketDetail.group_id,
        label: selRowGroup?.length > 0 ? selRowGroup[0]?.label : "",
      },
      inCharge: {
        value: oneTicketDetail.assigned_to,
        label: selRowInCharge.length > 0 ? selRowInCharge[0].label : "",
      },
      watcher: !!watcherStaging ? watcherStaging : [],
      status: {
        value: oneTicketDetail.status,
        label: selRowStatus.length > 0 ? selRowStatus[0].label : "",
      },
      type: {
        value: oneTicketDetail.type,
        label:
          selRowType.length > 0
            ? getLabel(
                sysLanguage,
                selRowType[0].msgset,
                selRowType[0].msgnbr,
                selRowType[0].descr
              )
            : "",
      },
      priority: {
        value: oneTicketDetail.high_pri,
        label: selRowPri.length > 0 ? selRowPri[0].label : "",
      },
      issueDate: !!oneTicketDetail.occur_date
        ? oneTicketDetail.occur_date
        : null,
      closeDate: !!oneTicketDetail.closed_dt
        ? oneTicketDetail.closed_dt
        : new Date().toLocaleDateString(),
    });
  };

  const isReadOnly = () => {
    const isAdmin =
      roleArray && roleArray?.some((item) => item.role_name === "TICKET_ADMIN");
    if (isAdmin) {
      setCanEdit(true);
      setAdmTicket(true);
    } else {
      const editYes =
        roleArray &&
        roleArray?.some((item) => item.role_name === "TICKET_WRITE");
      if (editYes || isTrainee) {
        if (isTrainee && oneTicketDetail.group_ticket) {
          setCanEdit(false);
        } else {
          setCanEdit(true);
        }
      } else {
        setCanEdit(false);
      }
    }
  };

  useEffect(() => {
    if (loading) {
      loadTicket();
      loadData();
      isReadOnly();
    }
  }, [dispatch, oneTicketDetail?.ticket_id, ticket_id]);

  // useEffect(() => {
  //   loadTicket();
  //   loadData();
  //   isReadOnly();
  // }, [dispatch, ticket_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {/* {isSmUp ? null : ( */}
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          {/* )} */}

          {/* <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          /> */}
        </Box>

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header onDrawerToggle={handleDrawerToggle} hidden={true} />
          {!loading ? (
            <div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    //   aria-label="basic tabs example"
                  >
                    <Tab
                      iconPosition="start"
                      icon=<ListIcon />
                      label={getLabel(sysLanguage, "3000", "24", "Details")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      iconPosition="start"
                      icon=<Badge
                        badgeContent={ticketComments?.length}
                        color="secondary"
                      >
                        <ForumIcon />
                      </Badge>
                      label={getLabel(sysLanguage, "3000", "43", "Comments")}
                      {...a11yProps(1)}
                    />
                    <Tab
                      iconPosition="start"
                      icon=<Badge
                        badgeContent={fileList?.length}
                        color="secondary"
                      >
                        <AttachFileIcon />
                      </Badge>
                      label={getLabel(sysLanguage, "6000", "19", "Files")}
                      {...a11yProps(2)}
                    />
                    {/* <Tab label="History" {...a11yProps(3)} /> */}
                  </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 0.7 },
                      // "& .MuiAutocomplete-root": { mr: 0.7 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Details
                      oneTicketDetail={oneTicketDetail}
                      fileList={fileList}
                      companyList={companyList}
                      issueList={issueList}
                      visaList={visaList}
                      occupationList={occupationList}
                      officerName={officerName}
                      traineeName={traineeName}
                      groupName={groupName}
                      setFormData={setFormData}
                      formData={formData}
                      isTrainee={isTrainee}
                      canEdit={canEdit}
                      profileOfficer={profileOfficer}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 0.7 },
                      // "& .MuiAutocomplete-root": { mr: 0.7 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Comment
                      oneTicketDetail={oneTicketDetail}
                      ticketComments={ticketComments}
                      admTicket={admTicket}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <FileAttachment
                    module="tickets"
                    isLocked={oneTicketDetail.status === "RSV"}
                    canEdit={admTicket}
                    group_ticket={oneTicketDetail.group_ticket}
                    group_id={oneTicketDetail.group_id}
                    source_id={oneTicketDetail.ticket_id}
                    assigned_to={oneTicketDetail.assigned_to}
                    owner_id={oneTicketDetail.owner_id}
                    watcher={oneTicketDetail.watcher}
                    company={
                      oneTicketDetail.company + "_" + oneTicketDetail.ticket_id
                    }
                    back={"/manageTicket"}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  Item Four
                </TabPanel>
              </Box>
              <Footer />
            </div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
