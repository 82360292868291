import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import TranslateIcon from "@mui/icons-material/Translate";

import Languages from "../../utils/Languages";

const Translation = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = (code) => {
    props.yesFunction(code);
    setOpen(false);
  };

  return (
    <div>
      <IconButton edge="end" aria-label="delete" onClick={handleClickOpen}>
        <TranslateIcon color="primary" fontSize="medium" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        {/* <Grid container spacing={2}> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List dense={true}>
              {Languages().map((row, index) => {
                return (
                  <div>
                    <ListItem>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => handleYes(row.code)}
                      >
                        {row.descr}
                      </Button>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
        {/* </Grid> */}
      </Dialog>
    </div>
  );
};

export default Translation;
