export const SEARCHTICKET = "SEARCHTICKET";
export const GETONETICKET = "GETONETICKET";
export const DELETETICKET = "DELETETICKET";
export const CLEAR_TICKET = "CLEAR_TICKET";

const initialState = {
  searchTicket: "",
  oneTicketDetail: [],
};

const auth = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SEARCHTICKET:
      return {
        ...state,
        searchTicket: action.ticketResult,
      };

    case GETONETICKET:
      return {
        ...state,
        oneTicketDetail: action.oneTicket[0],
      };

    case DELETETICKET:
      const updatedTicket =
        !!state.searchTicket &&
        state.searchTicket?.filter(
          (item) => item.ticket_id !== action.ticket_id
        );

      return {
        ...state,
        searchTicket: updatedTicket,
      };

    case CLEAR_TICKET:
      return {
        // ...state,
        searchTicket: "",
        oneTicketDetail: [],
      };

    default:
      return state;
  }
};

export default auth;
