import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import GridOnIcon from "@mui/icons-material/GridOn";

import { CSVLink } from "react-csv";

import { Formik, Form } from "formik";

import TableOne from "../components/TableOne";

import formatDate from "../../utils/formatDate";
import { getOptions, selectListFilter } from "../../utils/selectOptions";

import * as profileActions from "../../store/actions/profile";
import getLabel from "../../utils/getLabel";

const noFunction = (value) => {
  return value;
};

const SearchProfile = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const searchResultProfile = useSelector(
    (state) => state.profile.searchProfile
  );

  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState(null);
  const [trainee, setTrainee] = useState(null);
  const [visa, setVisa] = useState(null);

  const [tioDtFrom, setTioDtFrom] = useState(null);
  const [tioDtTo, setTioDtTo] = useState(null);

  const [visaDtFrom, setVisaDtFrom] = useState(null);
  const [visaDtTo, setVisaDtTo] = useState(null);

  const [passportDtFrom, setPassportDtFrom] = useState(null);
  const [passportDtTo, setPassportDtTo] = useState(null);

  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [searchField, setSearchField] = useState({ value: "", label: "" });

  const headCells = [
    {
      id: "user_id",
      numeric: false,
      disablePadding: true,
      padding: "none",
      function: noFunction,
      link: true,
      label: getLabel(sysLanguage, "4000", "12", "Employee ID"),
    },
    {
      id: "fullName",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      link: false,
      label: getLabel(sysLanguage, "4000", "13", "Name"),
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "4000", "14", "Company"),
    },
    {
      id: "batch_no",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "4000", "2", "Batch No."),
    },
    {
      id: "visa_code",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "4000", "15", "Visa Status"),
    },
    {
      id: "visa_end_dt",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "4000", "16", "Period of Stay"),
    },
    {
      id: "passport_expiry_dt",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "4000", "17", "Passport Expiration"),
    },
    {
      id: "tio_date",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatDate,
      label: getLabel(sysLanguage, "4000", "39", "TIO Date"),
    },
  ];

  const handleSearch = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      await dispatch(profileActions.searchProfile(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const jumpToNavigate = async (user_id) => {
    console.log("jumpToNavigate: ", user_id);
    // await dispatch(profileActions.getOneProfile(user_id));
    const ecryptedTicketId = btoa(user_id?.toString().padStart(7, "0"));
    navigate(`/MainProfile/${ecryptedTicketId}`, {});
  };

  let csvHeader = [
    { label: "Trainee ID", key: "user_id" },
    { label: "Trainee Name", key: "fullName" },
    { label: "Account Active", key: "account_locked" },
    { label: "Company", key: "company" },
    { label: "Batch No", key: "batch_no" },
    { label: "Visa", key: "visa_code" },
    { label: "Visa Expiry", key: "visa_end_dt" },
    { label: "Passport Expiry", key: "passport_expiry_dt" },
    { label: "TIO Date", key: "tio_date" },
  ];

  let csvData = [];

  const rows = [];
  searchResultProfile &&
    searchResultProfile.map((item) => {
      const company = selectListFilter(
        props.companyList,
        item.company,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      const visa = selectListFilter(
        props.visaList,
        item.visa_code,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      rows.push({ ...item, company: company, visa_code: visa });

      const forCSV = {
        user_id: item.user_id,
        fullName: item.fullName,
        account_locked: !item.account_locked,
        company: company,
        batch_no: item.batch_no,
        visa_code: visa,
        visa_end_dt: formatDate(item.visa_end_dt),
        passport_expiry_dt: formatDate(item.passport_expiry_dt),
        tio_date: formatDate(item.tio_date),
      };

      csvData.push(forCSV);
    });

  return (
    <Box sx={{ p: 1 }}>
      <Formik
        initialValues={{
          batchNo: "",
        }}
        onSubmit={(values) => {
          const addedData = {
            ...values,
            company: !!company ? company.value : null,
            trainee: !!trainee ? trainee.value : null,
            visa: !!visa ? visa.value : null,
            // arrivalDateFrom: arrivalDtFrom,
            // arrivalDateTo: arrivalDtTo,
            visaDateFrom: visaDtFrom,
            visaDateTo: visaDtTo,
            passportDateFrom: passportDtFrom,
            passportDateTo: passportDtTo,
            tioDateFrom: tioDtFrom,
            tioDateTo: tioDtTo,
          };
          handleSearch(addedData);
        }}
      >
        {({ handleChange, values, setFieldValue, resetForm }) => (
          <Form>
            {!advanceSearch ? (
              <Stack spacing={1}>
                <Grid container>
                  <Autocomplete
                    disableClearable
                    id="searchField"
                    name="searchField"
                    value={searchField}
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, data) => {
                      setSearchField(data);
                    }}
                    size="small"
                    sx={{ width: 200 }}
                    options={[
                      {
                        value: 1,
                        label: getLabel(
                          sysLanguage,
                          "4000",
                          "1",
                          "Select Company"
                        ),
                      },
                      {
                        value: 2,
                        label: getLabel(
                          sysLanguage,
                          "4000",
                          "4",
                          "Select Visa Status"
                        ),
                      },
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getLabel(
                          sysLanguage,
                          "9000",
                          "60",
                          "Select Item"
                        )}
                      />
                    )}
                  />
                  <Fragment>
                    {searchField.value === 1 && (
                      <Stack direction={"row"}>
                        <Autocomplete
                          id="company"
                          name="company"
                          value={company}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => {
                            setCompany(data);
                            setTrainee(null);
                          }}
                          size="small"
                          options={getOptions(sysLanguage, props.companyList)}
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "1",
                                "Select Company"
                              )}
                            />
                          )}
                        />
                        <TextField
                          id="batchNo"
                          name="batchNo"
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "2",
                            "Batch No."
                          )}
                          value={values.batchNo}
                          onChange={handleChange("batchNo")}
                          size="small"
                          sx={{ width: 150 }}
                        />
                        <Autocomplete
                          freeSolo
                          id="trainee"
                          name="trainee"
                          value={trainee}
                          getOptionLabel={(option) => option.label || ""}
                          onChange={(e, data) => setTrainee(data)}
                          size="small"
                          options={
                            !!company
                              ? props.traineeName.filter(
                                  (item) => item.company === company.value
                                )
                              : props.traineeName
                          }
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getLabel(
                                sysLanguage,
                                "4000",
                                "3",
                                "Select Trainee"
                              )}
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </Fragment>
                  <Fragment>
                    {searchField.value === 2 && (
                      <Autocomplete
                        id="visaType"
                        name="visaType"
                        value={visa}
                        getOptionLabel={(option) => option.label || ""}
                        onChange={(e, data) => setVisa(data)}
                        size="small"
                        options={getOptions(sysLanguage, props.visaList)}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "4",
                              "Select Visa Status"
                            )}
                          />
                        )}
                      />
                    )}
                  </Fragment>
                </Grid>
              </Stack>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Autocomplete
                        id="company"
                        name="company"
                        value={company}
                        getOptionLabel={(option) => option.label || ""}
                        onChange={(e, data) => {
                          setCompany(data);
                          setTrainee(null);
                        }}
                        size="small"
                        options={getOptions(sysLanguage, props.companyList)}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "1",
                              "Select Company"
                            )}
                          />
                        )}
                      />
                      <TextField
                        id="batchNo"
                        name="batchNo"
                        label={getLabel(sysLanguage, "4000", "2", "Batch No.")}
                        value={values.batchNo}
                        onChange={handleChange("batchNo")}
                        size="small"
                        sx={{ width: 150 }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Autocomplete
                        freeSolo
                        id="trainee"
                        name="trainee"
                        value={trainee}
                        getOptionLabel={(option) => option.label || ""}
                        onChange={(e, data) => setTrainee(data)}
                        size="small"
                        options={
                          !!company
                            ? props.traineeName.filter(
                                (item) => item.company === company.value
                              )
                            : props.traineeName
                        }
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "3",
                              "Select Trainee"
                            )}
                          />
                        )}
                      />
                      <Autocomplete
                        id="visaType"
                        name="visaType"
                        value={visa}
                        getOptionLabel={(option) => option.label || ""}
                        onChange={(e, data) => setVisa(data)}
                        size="small"
                        options={getOptions(sysLanguage, props.visaList)}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "4",
                              "Select Visa Status"
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "5",
                            "TIO Date (From)"
                          )}
                          inputFormat="yyyy/MM/dd"
                          value={tioDtFrom}
                          onChange={(data) =>
                            setTioDtFrom(
                              !!data ? data.toLocaleDateString() : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: 250 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "6",
                            "TIO Date (To)"
                          )}
                          inputFormat="yyyy/MM/dd"
                          value={tioDtTo}
                          onChange={(data) =>
                            setTioDtTo(
                              !!data ? data.toLocaleDateString() : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: 250 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "7",
                            "Period of Stay (From)"
                          )}
                          inputFormat="yyyy/MM/dd"
                          value={visaDtFrom}
                          onChange={(data) =>
                            setVisaDtFrom(
                              !!data ? data.toLocaleDateString() : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: 250 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "8",
                            "Period of Stay (To)"
                          )}
                          inputFormat="yyyy/MM/dd"
                          value={visaDtTo}
                          onChange={(data) =>
                            setVisaDtTo(
                              !!data ? data.toLocaleDateString() : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: 250 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "9",
                            "Passport Expiration (From)"
                          )}
                          inputFormat="yyyy/MM/dd"
                          value={passportDtFrom}
                          onChange={(data) =>
                            setPassportDtFrom(
                              !!data ? data.toLocaleDateString() : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: 250 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={getLabel(
                            sysLanguage,
                            "4000",
                            "10",
                            "Passport Expiration (To)"
                          )}
                          inputFormat="yyyy/MM/dd"
                          value={passportDtTo}
                          onChange={(data) =>
                            setPassportDtTo(
                              !!data ? data.toLocaleDateString() : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              sx={{ width: 250 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            )}
            <Box
              sx={{
                display: "flex",
                alignContent: "flex-end",
                mt: 1,
              }}
            >
              {/* <Button
                sx={{ m: 1 }}
                color="primary"
                variant="contained"
                type="submit"
              >
                {getLabel(sysLanguage, "9000", "1", "Search")}
              </Button> */}
              <LoadingButton
                sx={{ m: 1, width: 150 }}
                color="primary"
                variant="contained"
                type="submit"
                loading={loading}
              >
                {getLabel(sysLanguage, "9000", "1", "Search")}
              </LoadingButton>
              <Button
                sx={{ m: 1, width: 150 }}
                color="warning"
                variant="contained"
                onClick={() => {
                  setCompany(null);
                  setTrainee(null);
                  setVisa(null);
                  // setArrivalDtFrom(null);
                  // setArrivalDtTo(null);
                  setTioDtFrom(null);
                  setTioDtTo(null);
                  setVisaDtFrom(null);
                  setVisaDtTo(null);
                  setPassportDtFrom(null);
                  setPassportDtTo(null);
                  resetForm();
                }}
              >
                {getLabel(sysLanguage, "9000", "2", "Clear")}
              </Button>

              <Grid container justifyContent="flex-end">
                <Button
                  sx={{ m: 1 }}
                  color="success"
                  variant="outlined"
                  onClick={() => setAdvanceSearch(!advanceSearch)}
                >
                  {advanceSearch
                    ? getLabel(sysLanguage, "9000", "58", "Basic Search")
                    : getLabel(sysLanguage, "9000", "59", "Extended Search")}
                </Button>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      {rows.length > 0 && (
        <Box>
          <TableOne
            headCells={headCells}
            rows={rows}
            jumpToNavigate={jumpToNavigate}
          />
          <Grid container justifyContent="flex-end" style={{ paddingRight: 5 }}>
            <CSVLink
              filename="searchprofile.csv"
              target="_blank"
              data={csvData}
              headers={csvHeader}
            >
              <Button size="large" variant="outlined" endIcon={<GridOnIcon />}>
                {getLabel(sysLanguage, "9000", "54", "Export")}
              </Button>
            </CSVLink>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SearchProfile;
