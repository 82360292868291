import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

import { useNavigate } from "react-router-dom";

import ContactMailIcon from "@mui/icons-material/ContactMail";
import BusinessIcon from "@mui/icons-material/Business";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import CampaignIcon from "@mui/icons-material/Campaign";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import TableChartIcon from "@mui/icons-material/TableChart";

import AddBoxIcon from "@mui/icons-material/AddBox";

import getLabel from "../../utils/getLabel";

const item = {
  py: "1px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

let selectedPage = "";

export default function Navigator(props) {
  const { ...other } = props;

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const { onDrawerToggle } = props;

  const navigate = useNavigate();

  const categoriesOfficer = [
    {
      id: getLabel(sysLanguage, "2000", "2", "Tickets"),
      roleaccess: ["TICKET"],
      children: [
        {
          roleaccess: ["TICKET"],
          id: getLabel(sysLanguage, "2000", "3", "Manage Tickets"),
          key: "manageTicket",
          icon: <DnsRoundedIcon />,
        },
        {
          roleaccess: ["TICKET"],
          id: getLabel(sysLanguage, "2000", "24", "Manage Groups"),
          key: "manageGroup",
          icon: <GroupWorkIcon />,
        },
      ],
    },

    {
      id: getLabel(sysLanguage, "2000", "4", "Trainee Management"),
      roleaccess: ["PROFILE", "ANNOUNCEMENT"],
      children: [
        {
          roleaccess: ["ANNOUNCEMENT"],
          id: getLabel(sysLanguage, "2000", "5", "Announcements"),
          key: "manageBanner",
          icon: <CampaignIcon />,
        },
        {
          roleaccess: ["PROFILE"],
          id: getLabel(sysLanguage, "2000", "6", "Trainee Profile"),
          key: "traineeProfile",
          icon: <ContactMailIcon />,
        },
        {
          roleaccess: ["PROFILE"],
          id: getLabel(sysLanguage, "2000", "40", "Company Profile"),
          key: "manageCompany",
          icon: <BusinessIcon />,
        },
      ],
    },

    {
      id: getLabel(sysLanguage, "6000", "26", "Chat"),
      roleaccess: ["OFFICER"],
      children: [
        {
          roleaccess: ["OFFICER"],
          id: getLabel(sysLanguage, "6000", "26", "Chat"),
          key: "ChatMain",
          icon: <ChatBubbleIcon />,
        },
      ],
    },

    {
      id: "Analysis and Reports",
      children: [
        {
          id: "Profile Analysis",
          key: "profileAnalysis",
          icon: <BarChartIcon />,
        },
        {
          id: "Company Profile",
          key: "companyChart",
          icon: <TableChartIcon />,
        },
        {
          id: "Workload Analysis",
          key: "workloadChart",
          icon: <PieChartOutlineIcon />,
        },
      ],
    },
    {
      id: getLabel(sysLanguage, "2000", "7", "System Settings"),
      roleaccess: ["SYSUSERS"],
      children: [
        {
          roleaccess: ["SYSUSERS"],
          id: getLabel(sysLanguage, "2000", "8", "Manage Users"),
          key: "manageUser",
          icon: <PeopleIcon />,
        },
      ],
    },
    {
      id: getLabel(sysLanguage, "2000", "9", "My Account"),
      roleaccess: ["OFFICER"],
      children: [
        {
          roleaccess: ["OFFICER"],
          id: getLabel(sysLanguage, "2000", "9", "My Account"),
          key: "MainUser",
          icon: <ContactMailIcon />,
        },
        {
          roleaccess: ["OFFICER"],
          id: getLabel(sysLanguage, "2000", "41", "Training Materials"),
          key: "Learning",
          icon: <MenuBookIcon />,
        },
      ],
    },
  ];

  const categoriesTrainee = [
    {
      id: getLabel(sysLanguage, "2000", "2", "Tickets"),
      roleaccess: ["TRAINEE"],
      children: [
        {
          roleaccess: ["TRAINEE"],
          id: getLabel(sysLanguage, "2000", "3", "Manage Tickets"),
          key: "manageTicket",
          icon: <AddBoxIcon />,
        },
      ],
    },
    {
      id: getLabel(sysLanguage, "2000", "10", "My Profile"),
      roleaccess: ["TRAINEE"],
      children: [
        {
          roleaccess: ["TRAINEE"],
          id: getLabel(sysLanguage, "2000", "10", "My Profile"),
          key: "MainProfile",
          icon: <ContactMailIcon />,
        },
      ],
    },
    {
      id: getLabel(sysLanguage, "6000", "26", "Chat"),
      roleaccess: ["TRAINEE"],
      children: [
        {
          roleaccess: ["TRAINEE"],
          id: getLabel(sysLanguage, "6000", "26", "Chat"),
          key: "ChatMain",
          icon: <ChatBubbleIcon />,
        },
      ],
    },
    {
      id: getLabel(sysLanguage, "2000", "9", "My Account"),
      roleaccess: ["TRAINEE"],
      children: [
        {
          roleaccess: ["TRAINEE"],
          id: getLabel(sysLanguage, "2000", "9", "My Account"),
          key: "MainUser",
          icon: <ContactMailIcon />,
        },
      ],
    },
  ];

  const categories = isTrainee ? categoriesTrainee : categoriesOfficer;

  const handleHome = useCallback(() => {
    navigate(`/`, {});
  }, [navigate]);

  const handleNewHome = useCallback(() => {
    navigate(`/new`, {});
  }, [navigate]);

  const handleOnClick = useCallback(
    (screen) => {
      const getUser = async () => {
        console.log("jumpToNavigate: ", userData.user_id);
        const ecryptedTicketId = btoa(
          userData.user_id?.toString().padStart(7, "0")
        );
        // await dispatch(userActions.getOneAccount(userData.user_id));
        navigate(`/myaccount/${ecryptedTicketId}`, {});
      };

      const getProfile = async () => {
        console.log("jumpToNavigate: ", userData.user_id);
        const ecryptedTicketId = btoa(
          userData.user_id?.toString().padStart(7, "0")
        );
        navigate(`/MainProfile/${ecryptedTicketId}`, {});
      };

      switch (screen) {
        case "MainUser":
          getUser();

          break;
        case "MainProfile":
          getProfile();
          break;

        default:
          navigate(`/${screen}`, {});
      }
    },
    [navigate]
  );

  const grantAccess = (arr1, arr2) => {
    let allow = false;
    let show = false;

    arr2?.map((roleaccess) => {
      allow = arr1?.some((item) => item.role_name.includes(roleaccess));
      if (allow) show = true;
    });

    return show;
  };

  return (
    <Drawer variant="permanent" {...other}>
      {roleArray && (
        <List disablePadding>
          <ListItem
            sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
          >
            HoRenSo Plus
          </ListItem>
          <Box key={"home"} sx={{ bgcolor: "#101F33" }}>
            <ListItem
              disablePadding
              key={"home"}
              onClick={() => {
                selectedPage = "home";
              }}
            >
              <ListItemButton
                selected={selectedPage === "home" && true}
                sx={{ ...item, mt: 1 }}
                onClick={() => {
                  handleHome();
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText>
                  {getLabel(sysLanguage, "2000", "1", "Dashboard")}
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <Divider sx={{ mt: 1 }} />
          </Box>

          {/* <Box key={"home2"} sx={{ bgcolor: "#101F33" }}>
            <ListItem
              disablePadding
              key={"home"}
              onClick={() => {
                // selectedPage = "home";
              }}
            >
              <ListItemButton
                selected={selectedPage === "home" && true}
                sx={{ ...item, mt: 1 }}
                onClick={() => {
                  handleNewHome();
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText>
                  {getLabel(sysLanguage, "2000", "1000", "Dashboard2")}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Box> */}

          {categories.map(({ id, roleaccess, children }, index) => (
            <Box key={index} sx={{ bgcolor: "#101F33" }}>
              {grantAccess(roleArray, roleaccess) && (
                <>
                  <ListItem sx={{ py: 2, px: 3 }}>
                    <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
                  </ListItem>
                  {children.map(
                    (
                      { id: childId, key: keyName, roleaccess, icon },
                      index
                    ) => (
                      <Box key={index}>
                        {roleArray?.some((item) =>
                          item.role_name.includes(roleaccess)
                        ) && (
                          <ListItem
                            disablePadding
                            key={childId}
                            onClick={() => {
                              selectedPage = keyName;
                            }}
                          >
                            <ListItemButton
                              selected={selectedPage === keyName && true}
                              sx={item}
                              onClick={() => {
                                handleOnClick(keyName);
                              }}
                            >
                              <ListItemIcon>{icon}</ListItemIcon>
                              <ListItemText>{childId}</ListItemText>
                            </ListItemButton>
                          </ListItem>
                        )}
                      </Box>
                    )
                  )}

                  <Divider sx={{ mt: 2 }} />
                </>
              )}
            </Box>
          ))}
        </List>
      )}
    </Drawer>
  );
}
