import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";

import Confirmation from "../components/Confirmation";
import Translation from "../components/Translation";

import { LinkItUrl } from "react-linkify-it";

import formatTime from "../../utils/formatTime";

import * as configActions from "../../store/actions/config";
import getLabel from "../../utils/getLabel";

const Comment = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);
  const userData = useSelector((state) => state.auth.userData[0]);

  // const translatedText = useSelector((state) => state.config.translatedText);

  const [loading, setLoading] = useState(false);

  const [comment, setComment] = useState();

  const [targetComment, setTargetComment] = useState();

  const [translatedField, setTranslatedField] = useState();
  const [translatedText, setTranslatedText] = useState();

  const handleSend = async (text) => {
    const addedData = {
      business_unit: props.oneTicketDetail.business_unit,
      group_ticket: props.oneTicketDetail.group_ticket,
      group_id: props.oneTicketDetail.group_id,
      source_id: props.oneTicketDetail.ticket_id,
      text: text,
      module: "TICKET",
      watcher: props.oneTicketDetail.watcher,
      assigned_to: props.oneTicketDetail.assigned_to,
      owner_id: props.oneTicketDetail.owner_id,
    };

    try {
      setLoading(true);
      setComment("");
      await dispatch(configActions.addComment(addedData));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    try {
      setLoading(true);
      await dispatch(configActions.deleteComment(formData));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTranslate = async (code, text, comment_id) => {
    console.log(code, text, comment_id);
    setTargetComment(comment_id);

    try {
      setLoading(true);
      const output = await dispatch(configActions.translateText(code, text));
      setLoading(false);
      setTranslatedText(output);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <TextField
        required
        disabled={props.oneTicketDetail.status === "RSV"}
        id="comment"
        name="comment"
        label={getLabel(sysLanguage, "3000", "44", "Send Comments")}
        multiline
        rows={3}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
        size="small"
        sx={{ width: "80%" }}
        InputProps={{
          endAdornment: (
            <>
              {comment && comment.length > 2 && (
                <IconButton onClick={() => handleSend(comment)} edge="start">
                  <SendIcon color="primary" fontSize="large" />
                </IconButton>
              )}
            </>
          ),
        }}
      />

      {!!props.ticketComments &&
        props.ticketComments.map((row, index) => {
          return (
            <div>
              <ListItem
                key={index}
                alignItems="flex-start"
                secondaryAction={
                  <React.Fragment>
                    <Translation
                      title="Translation"
                      variant="contained"
                      yesFunction={(code) =>
                        handleTranslate(
                          code,
                          row.text.toString(),
                          row.comment_id
                        )
                      }
                    />
                    {props.oneTicketDetail.status !== "RSV" && (
                      <>
                        {(props.admTicket ||
                          userData.user_id === row.addeduserid) && (
                          <Confirmation
                            icon={true}
                            buttonName={getLabel(
                              sysLanguage,
                              "3000",
                              "52",
                              "Delete"
                            )}
                            title={getLabel(
                              sysLanguage,
                              "3000",
                              "52",
                              "Delete Comment"
                            )}
                            context={getLabel(
                              sysLanguage,
                              "9000",
                              "5",
                              "Are you sure you want to delete this item?"
                            )}
                            color="error"
                            variant="contained"
                            yesFunction={() => handleDelete(row)}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                }
              >
                <ListItemAvatar>
                  <Avatar src={row.avatar} sx={{ width: 46, height: 46 }} />
                </ListItemAvatar>

                <ListItemText
                  sx={{ mr: 5 }}
                  primary={row.addedBy}
                  secondary={
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      <LinkItUrl>
                        <Typography
                          sx={{ display: "block" }}
                          component="span"
                          variant="body2"
                          color="text"
                        >
                          {row.text}
                        </Typography>
                      </LinkItUrl>
                      {!loading && row.comment_id === targetComment && (
                        <Typography
                          sx={{ display: "block", mt: 1 }}
                          component="span"
                          variant="body2"
                          color="primary"
                        >
                          {translatedText}
                        </Typography>
                      )}
                      <Typography
                        sx={{ display: "block", mt: 1 }}
                        component="span"
                        variant="caption"
                        color="text.secondary"
                      >
                        {formatTime(row.added_dttm)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </div>
  );
};

export default Comment;
