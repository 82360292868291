import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LinearProgress from "@mui/material/LinearProgress";

import Navigator from "../components/Navigator";
import Header from "../components/Header";
import Footer from "../components/Footer";

import ChatMates from "./ChatMates";
import ChatBox from "./ChatBox";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ChatMain = (props) => {
  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);

  const [value, setValue] = useState(0);

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const companyList = useSelector((state) => state.config.companyList);
  const traineeName = useSelector((state) => state.config.traineeName);

  const [chatListSearch, setChatListSearch] = useState([]);

  const [chatMessage, setChatMessage] = useState([]);
  const [chatInfo, setChatInfo] = useState();

  const [chatGrpName, setChatGrpName] = useState("");

  const [loadMessage, setLoadMessage] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Box>

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header onDrawerToggle={handleDrawerToggle} hidden={true} />
          <div>
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <ChatMates
                    setChatListSearch={setChatListSearch}
                    chatListSearch={chatListSearch}
                    setChatInfo={setChatInfo}
                    chatInfo={chatInfo}
                    setChatMessage={setChatMessage}
                    setLoadMessage={setLoadMessage}
                    loadMessage={loadMessage}
                    setChatGrpName={setChatGrpName}
                    chatGrpName={chatGrpName}
                  />
                </Grid>
                <Grid
                  item
                  // xs={chatInfo?.chat_type === "G" ? 5 : "8"}
                  xs={8}
                  sx={{ borderLeft: 0.1, borderColor: "gray" }}
                >
                  {!!chatInfo && (
                    <ChatBox
                      setChatListSearch={setChatListSearch}
                      chatListSearch={chatListSearch}
                      setChatInfo={setChatInfo}
                      chatInfo={chatInfo}
                      chatMessage={chatMessage}
                      setChatMessage={setChatMessage}
                      setLoadMessage={setLoadMessage}
                      loadMessage={loadMessage}
                      setChatGrpName={setChatGrpName}
                      chatGrpName={chatGrpName}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <Footer />
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ChatMain;
