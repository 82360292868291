import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";

import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";

import { Formik, Form } from "formik";

import Confirmation from "../components/Confirmation";

import formatTime from "../../utils/formatTime";
import { roleOption } from "../../utils/roleOption";

import * as userActions from "../../store/actions/user";
import getLabel from "../../utils/getLabel";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RoleInfo = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleList = useSelector((state) => state.config.roleList);

  const navigate = useNavigate();

  const [addMode, setAddMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState({});
  const [roleDescr, setRoleDescr] = useState();

  const [open, setOpen] = useState(false);

  const [rowDetail, setRowDetail] = useState({
    business_unit: "",
    user_id: null,
    role_name: "",
    descr: "",
    last_update_dttm: null,
  });

  const handleEdit = (rowData) => {
    setAddMode(false);

    setRowDetail({
      business_unit: rowData.business_unit,
      user_id: rowData.user_id,
      role_name: rowData.role_name,
      descr: rowData.descr,
      last_update_dttm: rowDetail.last_update_dttm,
    });

    setOpen(true);
  };

  const handleAdd = (rowData) => {
    setAddMode(true);

    setRoleDescr();

    setRowDetail({
      business_unit: props.searchOneUser.business_unit,
      user_id: props.searchOneUser.user_id,
      role_name: "",
      descr: "",
      last_update_dttm: null,
    });

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = async (formData) => {
    console.log(formData);
    try {
      setLoading(true);
      await dispatch(userActions.addRole(formData));
      setLoading(false);

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    try {
      console.log(formData);

      await dispatch(userActions.deleteRole(formData));
      setOpen(false);
    } catch (error) {}
  };

  const checkAccess = () => {
    if (props.canEditOfficer) return false;
    else {
      return true;
    }
  };

  const rows = [];
  props.userRoles &&
    props.userRoles.map((item) => {
      rows.push(item);
    });

  useEffect(() => {
    let selRow = [];

    selRow = roleOption(
      sysLanguage,
      props.userRoles,
      props.searchOneUser.code
    ).filter((item) => item.value === rowDetail.code);

    setRole({
      value: rowDetail.code,
      label: selRow.length > 0 ? selRow[0].label : "",
    });
  }, [rowDetail]);

  useEffect(() => {
    if (role) {
      const descr = roleList.filter((item) => item.code === role.value);
      setRoleDescr(descr[0]?.descr);
    }
  }, [role]);

  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Stack spacing={2}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {getLabel(sysLanguage, "5000", "39", "Role")}
                </Typography>
                <Formik
                  initialValues={{
                    business_unit: rowDetail.business_unit,
                    user_id: rowDetail.user_id,
                    role_name: rowDetail.role_name,
                    descr: !addMode ? rowDetail.descr : "",
                    last_update_dttm: rowDetail.last_update_dttm,
                  }}
                  // validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    const addedData = {
                      ...values,
                      role_name: role.value,
                    };
                    handleSave(addedData);
                  }}
                >
                  {({
                    handleChange,
                    values,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <Stack spacing={2}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Autocomplete
                            disablePortal
                            disabled={!addMode}
                            fullWidth
                            id="role"
                            name="role"
                            value={role}
                            getOptionLabel={(option) => option.label || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            onChange={(e, data) => setRole(data)}
                            options={roleOption(
                              sysLanguage,
                              props.userRoles,
                              props.searchOneUser.role_name
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label={getLabel(
                                  sysLanguage,
                                  "5000",
                                  "39",
                                  "Role"
                                )}
                              />
                            )}
                            size="small"
                          />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <TextField
                            disabled
                            fullWidth
                            multiline
                            rows={2}
                            id="descr"
                            name="descr"
                            value={!addMode ? values.descr : roleDescr}
                            size="small"
                          />
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            alignContent: "flex-end",
                            mt: 3,
                          }}
                        >
                          {addMode && (
                            <LoadingButton
                              type="submit"
                              loading={loading}
                              variant="contained"
                              color="primary"
                              sx={{ m: 1 }}
                            >
                              Save
                            </LoadingButton>
                          )}
                          <Button
                            sx={{ m: 1 }}
                            color="warning"
                            variant="contained"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </Box>
          </Fade>
        </Modal>
        {props.searchOneUser?.role_name === "OFFICER" && (
          <Grid container justifyContent="flex-start">
            <Button
              color="primary"
              variant="contained"
              onClick={handleAdd}
              disabled={checkAccess()}
            >
              {getLabel(sysLanguage, "5000", "31", "Add Role")}
            </Button>
          </Grid>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {getLabel(sysLanguage, "5000", "32", "Role Name")}
                </TableCell>
                <TableCell>
                  {getLabel(sysLanguage, "5000", "33", "Description")}
                </TableCell>
                <TableCell>
                  {getLabel(sysLanguage, "5000", "34", "Date Added")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.row_id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.role_name}
                  </TableCell>
                  <TableCell>{row.descr}</TableCell>
                  <TableCell>{formatTime(row.last_update_dttm)}</TableCell>
                  <TableCell align="right">
                    {!checkAccess() && (
                      <>
                        {row.role_name !== "TRAINEE" &&
                          row.role_name !== "OFFICER" && (
                            <Confirmation
                              icon={true}
                              buttonName={getLabel(
                                sysLanguage,
                                "9000",
                                "24",
                                "Delete Item"
                              )}
                              title={getLabel(
                                sysLanguage,
                                "9000",
                                "24",
                                "Delete Item"
                              )}
                              context={getLabel(
                                sysLanguage,
                                "9000",
                                "25",
                                "Are you sure you want to delete this item?"
                              )}
                              color="error"
                              variant="outlined"
                              yesFunction={() =>
                                handleDelete({
                                  user_id: props.searchOneUser?.user_id,
                                  role_name: row.role_name,
                                })
                              }
                            />
                          )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() =>
              props.isTrainee || props.myAccount
                ? navigate(`/`, {})
                : navigate(`/manageUser`, {})
            }
          >
            {getLabel(sysLanguage, "9000", "9", "Back")}
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

export default RoleInfo;
