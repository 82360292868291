import React, { useEffect } from "react";

import { LOGOUT } from "../src/store/reducers/auth";

import { Provider } from "react-redux";
import store, { Persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

import MainNavigator from "./navigation/MainNavigator";

window.Buffer = window.Buffer || require("buffer").Buffer;

const App = () => {
  useEffect(() => {
    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persistor}>
        <MainNavigator />
      </PersistGate>
    </Provider>
  );
};

export default App;
