import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Formik, Form } from "formik";

import Translation from "../components/Translation";

import * as ticketActions from "../../store/actions/ticket";
import * as configActions from "../../store/actions/config";
import * as profileActions from "../../store/actions/profile";

import {
  getOptions,
  ticketStatus,
  ticketPriority,
} from "../../utils/selectOptions";

import getLabel from "../../utils/getLabel";

import Confirmation from "../components/Confirmation";
import PeekProfile from "../components/PeekProfile";
import deleteFiles from "../../utils/deleteFiles";

let output = [];

const Details = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const awsKey = useSelector((state) => state.config.awsKey);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);

  const [translatedField, setTranslatedField] = useState();
  const [translatedText, setTranslatedText] = useState();

  const [groupName, setGroupName] = useState();

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileData, setProfileData] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchApi = async () => {
      if (!!props.formData.company) {
        output = await dispatch(
          ticketActions.getGroupName({
            company: props.formData.company.value,
          })
        );
        setGroupName(output);

        const selRowGroup =
          output &&
          output?.filter((item) => item.value === props.formData.group.value);

        props.setFormData({
          ...props.formData,
          group: {
            value: props.formData.group.value,
            label: selRowGroup?.length > 0 ? selRowGroup[0]?.label : "",
          },
        });
      }
    };

    fetchApi();
  }, []);

  const handleDelete = async (formData) => {
    try {
      setLoading(true);
      await dispatch(ticketActions.deleteTicket(formData));
      deleteFiles(awsKey, props.fileList, "tickets");
      setLoading(false);

      navigate(`/manageTicket`, {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleReOpen = async (formData) => {
    try {
      setLoading(true);
      await dispatch(ticketActions.reOpenTicket(formData));
      setLoading(false);
      props.setFormData({
        ...props.formData,
        status: {
          value: "INP",
          label: "In-Progress",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTranslate = async (code, text) => {
    try {
      setLoading(true);
      const output = await dispatch(configActions.translateText(code, text));
      setLoading(false);
      setTranslatedText(output);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (formData) => {
    try {
      setLoadingSave(true);
      await dispatch(ticketActions.updateTicket(formData));
      setLoadingSave(false);

      setLoadingAlert(true);
      setTimeout(() => {
        setLoadingAlert(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = (owner_id) => {
    traineeInfo(owner_id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const jumpToProfile = async (user_id) => {
    console.log("jumpToProfile: ", user_id);
    const ecryptedTicketId = btoa(user_id?.toString().padStart(7, "0"));
    navigate(`/MainProfile/${ecryptedTicketId}`, {});
  };

  const traineeInfo = async (owner_id) => {
    const data = {
      batchNo: "",
      company: null,
      trainee: owner_id,
      visa: null,
      visaDateFrom: null,
      visaDateTo: null,
      passportDateFrom: null,
      passportDateTo: null,
      tioDateFrom: null,
      tioDateTo: null,
    };

    setLoadingProfile(true);
    const output = await dispatch(profileActions.profileDashboard(data));
    setLoadingProfile(false);
    setProfileData(output[0]);
  };

  return (
    <div>
      <PeekProfile
        open={open}
        setOpen={setOpen}
        loadingProfile={loadingProfile}
        profileData={profileData}
        profileOfficer={props.profileOfficer}
      />

      <Formik
        initialValues={{
          ticket_id: props.formData.ticket_id,
          title: props.formData.title,
          description: props.formData.description,
          resolution: props.formData.resolution,
        }}
        onSubmit={(values) => {
          const watchers = [];

          !!props.formData.watcher &&
            props.formData.watcher.map((item) => {
              watchers.push(item.value);
            });

          const addedData = {
            ...values,
            group_ticket: props.oneTicketDetail.group_ticket,
            group_id: props.oneTicketDetail.group_id,
            occur_date: props.formData.issueDate
              ? new Date(props.formData.issueDate).toLocaleDateString()
              : null,
            closed_dt: props.formData.closeDate
              ? props.formData.status.value === "RSV"
                ? new Date(props.formData.closeDate).toLocaleDateString()
                : null
              : null,
            title: props.formData.title,
            description: props.formData.description,
            resolution:
              props.formData.status.value === "RSV"
                ? props.formData.resolution
                : "",
            type: props.formData.type.value,
            high_pri: props.formData.priority.value,
            status: props.formData.status.value,
            assigned_to: props.formData.inCharge.value,
            watcher: watchers,
          };
          handleSave(addedData);
        }}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Divider textAlign="left" sx={{ m: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "3000", "28", "Trainee Details")}
                  </Typography>
                </Divider>
                <Autocomplete
                  disabled
                  id="company"
                  name="company"
                  value={props.formData.company}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  size="small"
                  options={getOptions(sysLanguage, props.companyList)}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(sysLanguage, "3000", "17", "Company")}
                    />
                  )}
                />
                {props.oneTicketDetail.group_ticket ? (
                  <Autocomplete
                    disabled
                    id="group"
                    name="group"
                    value={props.formData.group}
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    size="small"
                    options={
                      props.groupName?.length > 0 ? props.groupName : groupName
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "65",
                          "Group Name"
                        )}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    disabled
                    id="trainee"
                    name="trainee"
                    label={getLabel(sysLanguage, "3000", "31", "Trainee")}
                    value={props.formData.trainee.label}
                    size="small"
                    sx={{ width: 400 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="send"
                          onClick={() =>
                            handleOpen(props.formData.trainee.value)
                          }
                        >
                          <Avatar
                            src={props.oneTicketDetail.trainee_avatar}
                            sx={{ width: 35, height: 35 }}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                )}

                <Divider textAlign="left" sx={{ m: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "3000", "32", "Ticket Details")}
                  </Typography>
                </Divider>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <TextField
                    disabled
                    id="ticket_id"
                    name="ticket_id"
                    label={getLabel(sysLanguage, "3000", "35", "Ticket ID")}
                    value={props.formData.ticket_id
                      ?.toString()
                      .padStart(7, "0")}
                    size="small"
                    sx={{ width: 250 }}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disabled={
                        !props.canEdit || props.oneTicketDetail.status === "RSV"
                      }
                      label={getLabel(sysLanguage, "3000", "33", "Issue Date")}
                      inputFormat="yyyy/MM/dd"
                      value={props.formData.issueDate}
                      onChange={(data) =>
                        props.setFormData({
                          ...props.formData,
                          issueDate: !!data ? data.toLocaleDateString() : null,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          size="small"
                          {...params}
                          sx={{ width: 250 }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Autocomplete
                    disableClearable
                    disabled={
                      !props.canEdit || props.oneTicketDetail.status === "RSV"
                    }
                    id="type"
                    name="type"
                    value={props.formData.type}
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, data) =>
                      props.setFormData({ ...props.formData, type: data })
                    }
                    size="small"
                    sx={{ width: 250 }}
                    options={getOptions(sysLanguage, props.issueList)}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "34",
                          "Issue Type"
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Divider textAlign="left" sx={{ m: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(
                      sysLanguage,
                      "3000",
                      "29",
                      "In-Charge and Watchers"
                    )}
                  </Typography>
                </Divider>
                <Autocomplete
                  disabled={
                    !props.canEdit ||
                    props.isTrainee ||
                    props.oneTicketDetail.status === "RSV"
                  }
                  id="assigned_to"
                  name="assigned_to"
                  value={props.formData.inCharge}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, inCharge: data })
                  }
                  size="small"
                  options={props.officerName || []}
                  renderInput={(params) => (
                    <TextField
                      required={!props.isTrainee}
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "3000",
                        "4",
                        "In-Charge Officer"
                      )}
                    />
                  )}
                />
                <Autocomplete
                  disabled={
                    !props.canEdit ||
                    props.isTrainee ||
                    props.oneTicketDetail.status === "RSV"
                  }
                  multiple
                  id="watcher"
                  name="watcher"
                  value={props.formData.watcher}
                  getOptionLabel={(option) => option?.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, watcher: data })
                  }
                  size="small"
                  options={props.officerName || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "3000",
                        "30",
                        "Ticket Watchers"
                      )}
                    />
                  )}
                />
                <Divider textAlign="left" sx={{ m: 1 }}>
                  <Typography variant="overline" display="block" gutterBottom>
                    {getLabel(sysLanguage, "3000", "36", "Status and Priority")}
                  </Typography>
                </Divider>
                <Autocomplete
                  disableClearable
                  disabled={
                    !props.canEdit ||
                    props.isTrainee ||
                    props.oneTicketDetail.status === "RSV"
                  }
                  id="status"
                  name="status"
                  value={props.formData.status}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => {
                    props.setFormData({ ...props.formData, status: data });
                    // values.resolution = "";
                  }}
                  size="small"
                  options={ticketStatus(sysLanguage)}
                  renderInput={(params) => (
                    <TextField
                      required={!props.isTrainee}
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "3000",
                        "37",
                        "Ticket Status"
                      )}
                    />
                  )}
                />
                <Autocomplete
                  disableClearable
                  disabled={
                    !props.canEdit ||
                    props.isTrainee ||
                    props.oneTicketDetail.status === "RSV"
                  }
                  id="high_pri"
                  name="high_pri"
                  value={props.formData.priority}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, priority: data })
                  }
                  size="small"
                  options={ticketPriority(sysLanguage)}
                  renderInput={(params) => (
                    <TextField
                      required={!props.isTrainee}
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "3000",
                        "38",
                        "Ticket Priority"
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Stack spacing={2}>
              <TextField
                required
                disabled={
                  !props.canEdit || props.oneTicketDetail.status === "RSV"
                }
                fullWidth
                id="title"
                name="title"
                label={getLabel(sysLanguage, "3000", "39", "Title")}
                value={props.formData.title}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    title: e.target.value,
                  });
                }}
                size="small"
                inputProps={{
                  maxLength: 50,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {props.formData.title &&
                        props.formData.title.length > 2 && (
                          <Translation
                            title="Translation"
                            variant="contained"
                            yesFunction={(code) => {
                              setTranslatedField("A");
                              handleTranslate(code, props.formData.title);
                            }}
                          />
                        )}
                    </>
                  ),
                }}
              />
              {!loading && translatedField === "A" && (
                <div style={{ marginTop: 1, marginBottom: 20, marginLeft: 20 }}>
                  <Typography variant="body2" color="primary">
                    {translatedText}
                  </Typography>
                </div>
              )}
              <TextField
                required
                disabled={
                  !props.canEdit || props.oneTicketDetail.status === "RSV"
                }
                fullWidth
                id="description"
                name="description"
                label={getLabel(sysLanguage, "3000", "40", "Issue Description")}
                multiline
                minRows={3}
                value={props.formData.description}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    description: e.target.value,
                  });
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <>
                      {props.formData.description &&
                        props.formData.description.length > 2 && (
                          <Translation
                            title="Translation"
                            variant="contained"
                            yesFunction={(code) => {
                              setTranslatedField("B");
                              handleTranslate(code, props.formData.description);
                            }}
                          />
                        )}
                    </>
                  ),
                }}
              />
              {!loading && translatedField === "B" && (
                <div style={{ marginTop: 1, marginBottom: 20, marginLeft: 20 }}>
                  <Typography variant="body2" color="primary">
                    {translatedText}
                  </Typography>
                </div>
              )}
              {!!props.formData.status &&
                props.formData.status.value === "RSV" && (
                  <>
                    <TextField
                      required
                      disabled={props.oneTicketDetail.status === "RSV"}
                      fullWidth
                      id="resolution"
                      name="resolution"
                      label={getLabel(
                        sysLanguage,
                        "3000",
                        "53",
                        "Resolution Description"
                      )}
                      multiline
                      minRows={3}
                      value={props.formData.resolution}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          resolution: e.target.value,
                        });
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <>
                            {props.formData.resolution &&
                              props.formData.resolution.length > 2 && (
                                <Translation
                                  title="Translation"
                                  variant="contained"
                                  yesFunction={(code) => {
                                    setTranslatedField("C");
                                    handleTranslate(
                                      code,
                                      props.formData.resolution
                                    );
                                  }}
                                />
                              )}
                          </>
                        ),
                      }}
                    />
                    {!loading && translatedField === "C" && (
                      <div
                        style={{
                          marginTop: 1,
                          marginBottom: 20,
                          marginLeft: 20,
                        }}
                      >
                        <Typography variant="body2" color="primary">
                          {translatedText}
                        </Typography>
                      </div>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={props.oneTicketDetail.status === "RSV"}
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "54",
                          "Resolved Date"
                        )}
                        inputFormat="yyyy/MM/dd"
                        value={props.formData.closeDate}
                        onChange={(data) =>
                          props.setFormData({
                            ...props.formData,
                            closeDate: !!data
                              ? data.toLocaleDateString()
                              : null,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            size="small"
                            {...params}
                            sx={{ width: 250 }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </>
                )}
            </Stack>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <div>
                <LoadingButton
                  disabled={
                    !props.canEdit || props.oneTicketDetail.status === "RSV"
                  }
                  type="submit"
                  loading={loadingSave}
                  variant="contained"
                  color="primary"
                  sx={{ m: 1 }}
                >
                  {getLabel(sysLanguage, "9000", "6", "Save")}
                </LoadingButton>
                <Button
                  sx={{ m: 1 }}
                  color="warning"
                  variant="contained"
                  onClick={() => navigate(`/manageTicket`, {})}
                >
                  {getLabel(sysLanguage, "9000", "7", "Cancel")}
                </Button>
              </div>
              {props.canEdit && (
                <>
                  {props.oneTicketDetail.status === "PEN" && (
                    <Confirmation
                      icon={false}
                      buttonName={getLabel(
                        sysLanguage,
                        "3000",
                        "25",
                        "Delete Ticket"
                      )}
                      title={getLabel(
                        sysLanguage,
                        "3000",
                        "25",
                        "Delete Ticket"
                      )}
                      context={getLabel(
                        sysLanguage,
                        "9000",
                        "5",
                        "Are you sure you want to delete this item?"
                      )}
                      color="error"
                      variant="outlined"
                      yesFunction={() =>
                        handleDelete({ ticket_id: values.ticket_id })
                      }
                    />
                  )}
                </>
              )}
              {props.canEdit && (
                <>
                  {props.oneTicketDetail.status === "RSV" && (
                    <Confirmation
                      icon={false}
                      buttonName={getLabel(
                        sysLanguage,
                        "3000",
                        "55",
                        "ReOpen Ticket"
                      )}
                      title={getLabel(
                        sysLanguage,
                        "3000",
                        "56",
                        "Re-Open this Ticket?"
                      )}
                      context={getLabel(
                        sysLanguage,
                        "3000",
                        "57",
                        "Are you sure you want to re-open this ticket?"
                      )}
                      color="error"
                      variant="outlined"
                      yesFunction={() =>
                        handleReOpen({
                          ticket_id: values.ticket_id,
                          group_ticket: props.oneTicketDetail.group_ticket,
                          group_id: props.oneTicketDetail.group_id,
                          comment: "",
                        })
                      }
                    />
                  )}
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      {loadingAlert && (
        <div>
          <Alert severity="success">
            {getLabel(sysLanguage, "9000", "10", "Changes Saved!")}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Details;
