import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// import { useFormik } from "formik";
import { Formik, Form } from "formik";
// import * as yup from "yup";

import * as profileActions from "../../store/actions/profile";

import getLabel from "../../utils/getLabel";

import { getOptions } from "../../utils/selectOptions";

const PersonalInfo = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const trainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const [loading, setLoading] = useState(false);

  const [loadingAlert, setLoadingAlert] = useState(false);

  const getAge = (birthdate) => {
    if (birthdate) {
      const age = Math.floor(
        (new Date() - new Date(birthdate).getTime()) / 3.15576e10
      );
      return age;
    }
  };

  const handleSave = async (formData) => {
    console.log(formData);

    const data = {
      ...props.formData,
      gender: props.formData.gender.value,
      country: props.formData.country.value,
    };

    // console.log(data);

    try {
      setLoading(true);
      await dispatch(profileActions.saveOneProfile(data));
      setLoading(false);

      setLoadingAlert(true);
      setTimeout(() => {
        setLoadingAlert(false);
      }, 2000);
    } catch (error) {}
  };

  return (
    <Box sx={{ p: 2 }}>
      <Formik
        initialValues={{
          business_unit: props.formData.business_unit,
          user_id: props.formData.user_id,
          email_id: props.formData.email_id,
          first_name: props.formData.first_name,
          middle_name: props.formData.middle_name,
          last_name: props.formData.last_name,
          contact_no: props.formData.contact_no,
          contact_no_two: props.formData.contact_no_two,
          home_address_one: props.formData.home_address_one,
          home_address_two: props.formData.home_address_two,
          passport: props.formData.passport,
        }}
        // validationSchema={SignupSchema}
        onSubmit={(values) => {
          handleSave(props.formData);
        }}
      >
        {({ handleChange, values, setFieldValue, errors, touched }) => (
          <Form>
            <Divider textAlign="left" sx={{ width: "50%" }}>
              <Typography variant="overline" display="block" gutterBottom>
                {getLabel(sysLanguage, "4000", "19", "Personal Details")}
              </Typography>
            </Divider>
            <Stack spacing={2}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  disabled
                  id="user_id"
                  name="user_id"
                  label={getLabel(sysLanguage, "4000", "12", "Employee ID")}
                  value={props.formData.user_id?.toString().padStart(7, "0")}
                  size="small"
                  sx={{ width: 300 }}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  required
                  disabled={!props.canEdit}
                  id="first_name"
                  name="first_name"
                  label={getLabel(sysLanguage, "4000", "20", "First Name")}
                  value={props.formData.first_name}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      first_name: e.target.value,
                    });
                  }}
                  size="small"
                  sx={{ width: 300 }}
                />
                <TextField
                  disabled={!props.canEdit}
                  id="middle_name"
                  name="middle_name"
                  label={getLabel(sysLanguage, "4000", "21", "Middle Name")}
                  value={props.formData.middle_name}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      middle_name: e.target.value,
                    });
                  }}
                  size="small"
                  sx={{ width: 300 }}
                />
                <TextField
                  required
                  disabled={!props.canEdit}
                  id="last_name"
                  name="last_name"
                  label={getLabel(sysLanguage, "4000", "22", "Last Name")}
                  value={props.formData.last_name}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      last_name: e.target.value,
                    });
                  }}
                  size="small"
                  sx={{ width: 300 }}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={!props.canEdit}
                    label={getLabel(sysLanguage, "4000", "23", "Birthdate")}
                    inputFormat="yyyy/MM/dd"
                    value={props.formData.birthDate}
                    onChange={(data) =>
                      props.setFormData({
                        ...props.formData,
                        birthDate: !!data ? data.toLocaleDateString() : null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        sx={{ width: 300 }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  disabled
                  id="age"
                  name="age"
                  label={getLabel(sysLanguage, "4000", "24", "Age")}
                  value={getAge(props.formData.birthDate)}
                  onChange={handleChange("age")}
                  size="small"
                  sx={{ width: 100 }}
                />
                <Autocomplete
                  disabled={!props.canEdit}
                  id="gender"
                  name="gender"
                  value={props.formData.gender}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, gender: data })
                  }
                  options={props.genderOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={getLabel(sysLanguage, "4000", "25", "Gender")}
                    />
                  )}
                  size="small"
                  sx={{ width: 195 }}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Autocomplete
                  disabled={!props.canEdit}
                  id="country"
                  name="country"
                  value={props.formData.country}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  options={getOptions(sysLanguage, props.countryOption)}
                  onChange={(e, data) =>
                    props.setFormData({ ...props.formData, country: data })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={getLabel(sysLanguage, "4000", "26", "Country")}
                    />
                  )}
                  size="small"
                  sx={{ width: 300 }}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  required
                  disabled={!props.canEdit}
                  id="passport"
                  name="passport"
                  label={getLabel(sysLanguage, "4000", "27", "Passport No.")}
                  value={props.formData.passport}
                  onChange={(e) => {
                    props.setFormData({
                      ...props.formData,
                      passport: e.target.value,
                    });
                  }}
                  size="small"
                  sx={{ width: 300 }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={!props.canEdit}
                    label={getLabel(
                      sysLanguage,
                      "4000",
                      "28",
                      "Passport Expiry Date"
                    )}
                    inputFormat="yyyy/MM/dd"
                    value={props.formData.passExpiryDt}
                    onChange={(data) =>
                      props.setFormData({
                        ...props.formData,
                        passExpiryDt: !!data ? data.toLocaleDateString() : null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        sx={{ width: 300 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={2} sm={5} md={5}>
                  <Divider textAlign="left" sx={{ m: 1, width: "50%" }}>
                    <Typography variant="overline" display="block" gutterBottom>
                      {getLabel(
                        sysLanguage,
                        "4000",
                        "29",
                        "Contact and Address"
                      )}
                    </Typography>
                  </Divider>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="contact_no"
                      name="contact_no"
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "30",
                        "Japan Contact No."
                      )}
                      value={props.formData.contact_no}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          contact_no: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 300 }}
                    />
                    <TextField
                      disabled
                      id="email_id"
                      name="email_id"
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "31",
                        "Email Address"
                      )}
                      value={props.formData.email_id}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          email_id: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 300 }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      required
                      disabled={!props.canEdit}
                      id="home_address_one"
                      name="home_address_one"
                      label={getLabel(sysLanguage, "4000", "32", "Address One")}
                      value={props.formData.home_address_one}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          home_address_one: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 605 }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="home_address_two"
                      name="home_address_two"
                      label={getLabel(sysLanguage, "4000", "33", "Address Two")}
                      value={props.formData.home_address_two}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          home_address_two: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 605 }}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="contact_no_two"
                      name="contact_no_two"
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "94",
                        "Home Country Contact No."
                      )}
                      value={props.formData.contact_no_two}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          contact_no_two: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 300 }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={2} sm={7} md={7}>
                  <Divider textAlign="left" sx={{ m: 1, width: "50%" }}>
                    <Typography variant="overline" display="block" gutterBottom>
                      {getLabel(
                        sysLanguage,
                        "4000",
                        "79",
                        "Emergency Contact Details"
                      )}
                    </Typography>
                  </Divider>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="emer_name"
                      name="emer_name"
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "80",
                        "Contact Name"
                      )}
                      value={props.formData.emer_name}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          emer_name: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 400 }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="emer_relationship"
                      name="emer_relationship"
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "81",
                        "Relationship"
                      )}
                      value={props.formData.emer_relationship}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          emer_relationship: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 400 }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="emer_address"
                      name="emer_address"
                      label={getLabel(sysLanguage, "4000", "82", "Address")}
                      value={props.formData.emer_address}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          emer_address: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 700 }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      disabled={!props.canEdit}
                      id="emer_tel_no"
                      name="emer_tel_no"
                      label={getLabel(sysLanguage, "4000", "83", "Contact No.")}
                      value={props.formData.emer_tel_no}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          emer_tel_no: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 400 }}
                    />
                    <TextField
                      disabled={!props.canEdit}
                      id="emer_email"
                      name="emer_email"
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "84",
                        "Email Address"
                      )}
                      value={props.formData.emer_email}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          emer_email: e.target.value,
                        });
                      }}
                      size="small"
                      sx={{ width: 400 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <LoadingButton
                  disabled={!props.canEdit}
                  type="submit"
                  loading={loading}
                  variant="contained"
                  color="primary"
                  sx={{ m: 1 }}
                >
                  {getLabel(sysLanguage, "9000", "6", "Save")}
                </LoadingButton>
                <Button
                  sx={{ m: 1 }}
                  color="warning"
                  variant="contained"
                  onClick={() =>
                    trainee
                      ? navigate(`/`, {})
                      : navigate(`/traineeProfile`, {})
                  }
                >
                  {getLabel(sysLanguage, "9000", "7", "Cancel")}
                </Button>
              </Grid>
              {/* </form> */}
            </Stack>
          </Form>
        )}
      </Formik>
      {loadingAlert && (
        <div>
          <Alert severity="success">
            {getLabel(sysLanguage, "9000", "10", "Changes Saved!")}
          </Alert>
        </div>
      )}
    </Box>
  );
};

export default PersonalInfo;
