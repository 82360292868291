import { PURGE } from "redux-persist";

export const LOADUSER = "LOADUSER";
export const USERROLES = "USERROLES";
export const GET_USER = "GET_USER";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const LOGOUT = "LOGOUT";

const initialState = {
  token: "",
  userData: "",
  userAccount: "",
  userRoles: "",
};

const auth = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case LOADUSER:
      // localStorage.setItem("token", action.token);

      return {
        ...state,
        token: action.token,
        userData: action.authData,
      };
    case USERROLES:
      return {
        ...state,
        userRoles: action.accessRoles,
      };

    case UPDATE_AVATAR:
      if (state.userData[0].user_id === action.user_id) {
        return {
          ...state,
          userData: [{ ...state.userData[0], avatar: action.avatar }],
        };
      } else {
        return {
          ...state,
        };
      }

    case GET_USER:
      return {
        ...state,
        userData: action.userSearch,
      };

    case LOGOUT:
      // sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      return {
        // ...state,
        token: "",
        userData: "",
        userAccount: "",
        userRoles: "",
      };
    default:
      return state;
  }
};

export default auth;
