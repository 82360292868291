import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import RegisterScreen from "./RegisterScreen";
import ForgotPassword from "./ForgotPassword";
import ContactUs from "./ContactUs";

import * as authActions from "../../store/actions/auth";

import Logo from "../../images/login_logo1.jpg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* <Link color="inherit" href="https://mui.com/"> */}
      HoRenSo Plus
      {/* </Link>{" "} */} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const ErrorText = (props) => {
  return (
    <Typography style={{ color: "#FF0000" }} variant="caption">
      {props.label}
    </Typography>
  );
};

const theme = createTheme();

export default function LoginScreen() {
  const dispatch = useDispatch();

  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const handleClickOpenForgot = () => {
    setOpenForgot(true);
  };

  const handleCloseForGot = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenForgot(false);
  };

  const handleCloseContact = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenContact(false);
  };

  const handleContact = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenContact(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const credentials = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      await dispatch(authActions.loginUser(credentials));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(String(err));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ px: 10, py: 5, height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={0}
          md={7}
          sx={{
            // backgroundImage: "url(https://picsum.photos/200)",
            backgroundImage: `url(${Logo})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            width: "50%",
            height: "100%",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <ErrorText label={error} />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                }
                label="Show password"
              />
              <LoadingButton
                type="submit"
                loading={loading}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={handleClickOpenForgot}
                  >
                    Forgot password?
                  </Link>

                  <Dialog open={openForgot} onClose={handleCloseForGot}>
                    <ForgotPassword handleClose={handleCloseForGot} />
                  </Dialog>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" onClick={handleContact}>
                    Contact Us
                  </Link>

                  <Dialog open={openContact} onClose={handleCloseContact}>
                    <ContactUs handleClose={handleCloseContact} />
                  </Dialog>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={handleClickOpen}>
                    Register
                  </Link>
                  <Dialog open={open} onClose={handleClose}>
                    <RegisterScreen handleClose={handleClose} />
                  </Dialog>
                </Grid>
                <Grid item>
                  <Link
                    href="/privacypolicy"
                    variant="body2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </Grid>
              </Grid>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
