import getLabel from "./getLabel";

export const roleOption = (labelArray, listofRoles, mainRole) => {
  let roleOption = [];

  if (labelArray) {
    if (mainRole === "OFFICER") {
      roleOption = [
        {
          value: "OFFICER",
          label: getLabel(labelArray, "8500", "17", "Officer"),
        },
        {
          value: "TICKET_ADMIN",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "Create or Update Tickets, Comments, and Files"
          ),
        },
        {
          value: "TICKET_WRITE",
          label: getLabel(labelArray, "8500", "17", "Create or Update Tickets"),
        },
        {
          value: "TICKET_READ",
          label: getLabel(labelArray, "8500", "17", "View Only Tickets"),
        },
        {
          value: "PROFILE_WRITE",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "Create or Update Trainees' Profile"
          ),
        },
        {
          value: "PROFILE_READ",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "View Only Trainees' Profile"
          ),
        },
        {
          value: "CREATE_ANNOUNCEMENT",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "Create, Update, or Delete Announcements"
          ),
        },
        {
          value: "VIEW_ANNOUNCEMENT",
          label: getLabel(labelArray, "8500", "17", "View Only Announcements"),
        },
        {
          value: "SYSUSERS_VIEWONLY",
          label: getLabel(labelArray, "8500", "17", "View Only System Users"),
        },
        {
          value: "SYSUSERS_UPDATE_TRAINEE",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "Update System Users - Trainee"
          ),
        },
        {
          value: "SYSUSERS_UPDATE_ADMIN",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "Update System Users - Trainees or Officers"
          ),
        },
        {
          value: "SYSUSERS_DELETE",
          label: getLabel(
            labelArray,
            "8500",
            "17",
            "Can delete system users - Trainees or Officers"
          ),
        },
      ];
    } else {
      return [];
    }

    const filteredOption = roleOption.filter(
      (item1) => !listofRoles.some((item2) => item2.role_name === item1.value)
    );

    return filteredOption;
  } else {
    return null;
  }
};
