import store from "../store/store";
import { LOGOUT } from "../store/reducers/auth";

const forceLogOut = (status, message) => {
  if (status === 401) {
    store.dispatch({ type: LOGOUT });
    console.log(message);
  }
};

export default forceLogOut;
