export const DASHBANNER = "DASHBANNER";
export const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";

const initialState = {
  dashBannerList: [],
};

const dashboard = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DASHBANNER:
      return {
        ...state,
        dashBannerList: action.dashBannerList,
      };
    case CLEAR_DASHBOARD:
      return {
        ...state,
        dashBannerList: [],
      };

    default:
      return state;
  }
};

export default dashboard;
