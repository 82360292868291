import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Confirmation = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    props.yesFunction();
    setOpen(false);
  };

  return (
    <div>
      {props.icon ? (
        <>
          {!props.disabled && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={handleClickOpen}
            >
              <DeleteForeverIcon color="error" fontSize="medium" />
            </IconButton>
          )}
        </>
      ) : (
        <Button
          disabled={props.disabled}
          sx={{ m: 1 }}
          color={props.color}
          variant={props.variant}
          onClick={handleClickOpen}
        >
          {props.buttonName}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.context}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Confirmation;
