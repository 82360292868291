import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { io } from "socket.io-client";

import FlatList from "flatlist-react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import formatTimeChat from "../../utils/formatTimeChat";

import getEnvironment from "../../utils/environments";
import getLabel from "../../utils/getLabel";

import * as chatActions from "../../store/actions/chat";

import NewChat from "./NewChat";
import NewChatGroup from "./NewChatGroup";

const url = getEnvironment().apiKey;
const socket = io.connect(url);

const ChatMates = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const companyList = useSelector((state) => state.config.companyList);
  const allNames = useSelector((state) => state.config.allUsers);

  const [chatList, setChatList] = useState([]);
  // const [chatListSearch, setChatListSearch] = useState([]);

  const [searchChat, setSearchChat] = useState();

  const [loadChat, setLoadChat] = useState(false);

  const [newChat, setNewChat] = useState(false);
  const [newGroup, setNewGroup] = useState(false);

  const [selButton, setSelButton] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSearch = (event) => {
    if (event.target.value.length < 1) {
      props.setChatListSearch(chatList);
      return;
    }

    const textData = event.target.value.toUpperCase();

    const newData = chatList.filter((item) => {
      const itemData = `${
        !!item.chat_name_descr && item.chat_name_descr.toUpperCase()
      } ${!!item.traineename && item.traineename.toUpperCase()}
      ${!!item.position && item.position.toUpperCase()} ${
        !!item.text && item.text.toUpperCase()
      } `;

      return itemData.indexOf(textData) > -1;
    });

    props.setChatListSearch(newData);
  };

  const getName = (participants) => {
    const selectedId = participants?.filter(
      (item) => item !== userData.user_id
    );
    const result =
      !!allNames && allNames.filter((item) => item.code === selectedId[0]);
    return result[0]?.descr;
  };

  const getAvatar = (participants) => {
    const selectedId = participants?.filter(
      (item) => item !== userData.user_id
    );
    const result =
      !!allNames && allNames.filter((item) => item.code === selectedId[0]);
    return result[0]?.avatar;
  };

  const getAttribute = (participants) => {
    const selectedId = participants?.filter(
      (item) => item !== userData.user_id
    );
    const result =
      !!allNames && allNames.filter((item) => item.code === selectedId[0]);

    const companyName = companyList?.filter(
      (item) => item.code === result[0]?.company
    );

    if (result[0]?.role_name === "OFFICER") {
      return result[0]?.position;
    } else {
      return !!companyName[0]?.code
        ? "[" + companyName[0]?.code + "] " + companyName[0]?.descr
        : null;
    }
  };

  const returnMessage = (message, sender, chat_type) => {
    const longMessage = message.substring(0, 30) + " ...";

    if (message.length > 30) {
      return chat_type === "G" ? sender + ": " + longMessage : longMessage;
    } else {
      return chat_type === "G"
        ? sender === userData.user_id
          ? "You: " + message
          : getName([sender]) + ": " + message
        : sender === userData.user_id
        ? "You: " + message
        : message;
    }
  };

  const loadChatList = async () => {
    try {
      setLoadChat(true);
      const output = await dispatch(chatActions.getChat());

      let newOutput = [];
      output.map((item) =>
        newOutput.push({
          ...item,
          chat_name_descr:
            item.chat_type === "P"
              ? getName(item.orig_participants)
              : item.chat_name,
          avatar: item.chat_type === "P" ? getAvatar(item.participants) : null,
        })
      );

      setChatList(newOutput);
      props.setChatListSearch(newOutput);

      setLoadChat(false);
    } catch (error) {
      console.log(error);
      setLoadChat(false);
    }
  };

  useEffect(() => {
    const socketCon = async () => {
      await socket.emit("watchChat", userData.user_id);
    };

    socketCon();

    loadChatList();
  }, [dispatch]);

  useEffect(() => {
    socket.on(userData.user_id, (data) => {
      loadChatList();
      // console.log("listening: ", data);
    });

    return () => {
      socket.off(userData.user_id);
    };
  }, [socket, dispatch]);

  const loadChatBoxMessages = (item) => {
    props.setChatInfo(item);
    props.setChatGrpName(item.chat_name_descr);
  };

  const renderItem = (props, idx) => {
    return (
      <List key={idx} sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Button
          variant="text"
          sx={{
            ":hover": {
              bgcolor: "#f0f0f0",
              color: "#03a9f4",
            },
            backgroundColor: selButton === idx ? "#03a9f4" : "#fff",
            width: "100%",
            height: "3.5rem",
            alignContent: "flex-start",
            justifyContent: "center",
            textTransform: "none",
          }}
          onClick={async () => {
            loadChatList();
            setSelButton(idx);
            loadChatBoxMessages(props);
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {props.chat_type === "P" ? (
                <Avatar
                  alt={
                    props.chat_type === "P"
                      ? getName(props.orig_participants)
                      : props.chat_name
                  }
                  src={
                    props.chat_type === "P"
                      ? getAvatar(props.participants)
                      : "https://picsum.photos/200/300"
                  }
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: "#ff9800",
                  }}
                >
                  "GC"
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#01579b",
                        lineHeight: "13px",
                        fontSize: 13,
                        fontWeight: "400",
                      }}
                    >
                      {props.chat_type === "P"
                        ? getName(props.orig_participants)
                        : props.chat_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontWeight: "300",

                        color: "#7b1fa2",
                      }}
                    >
                      {props.chat_type === "G"
                        ? getLabel(sysLanguage, "6000", "31", "Group Chat")
                        : getAttribute(props.orig_participants)}
                    </Typography>
                  </Box>
                  <Box>
                    {props.author_id === userData.user_id ? (
                      !props.seenusers?.includes(null) &&
                      props.seenusers?.length > 0 && (
                        <Typography
                          sx={{ lineHeight: "12px", color: "#ba68c8" }}
                          variant="caption"
                        >
                          Seen
                        </Typography>
                      )
                    ) : props.seenusers?.includes(userData.user_id) ? null : (
                      <Chip label="New" color="primary" size="small" />
                    )}
                  </Box>
                </Box>
              }
              secondary={
                <Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingTop: 0.5,
                    }}
                  >
                    <Typography sx={{ lineHeight: "12px", fontSize: 12 }}>
                      {returnMessage(
                        props.text,
                        props.author_id,
                        props.chat_type
                      )}
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>
                      {formatTimeChat(props.created_dttm)}
                    </Typography>
                  </Box>
                </Fragment>
              }
            />
          </ListItem>
        </Button>
        <Divider component="li" />
      </List>
    );
  };

  return (
    <Fragment>
      <Grid
        sx={{ paddingTop: 1 }}
        container
        direction="row"
        justifyContent="center"
        alignItems="baseline"
      >
        <TextField
          id="searchChat"
          name="searchChat"
          label={getLabel(sysLanguage, "6000", "44", "Search Name/Group")}
          value={searchChat}
          onChange={handleSearch}
          size="small"
          variant="outlined"
          sx={{ width: "90%", marginTop: 1 }}
          InputProps={{
            endAdornment: (
              <Stack direction={"row"} spacing={1}>
                <IconButton
                  edge="end"
                  aria-label="addperson"
                  onClick={() => setNewChat(true)}
                >
                  <PersonAddAlt1Icon color="primary" />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="addgroup"
                  onClick={() => setNewGroup(true)}
                >
                  <GroupAddIcon color="primary" />
                </IconButton>
              </Stack>
            ),
          }}
        />
      </Grid>
      <Box sx={{ padding: 2, width: "100%" }}>
        {/* {loadChat && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )} */}
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            height: window.innerHeight * 0.7,
          }}
        >
          {/* New Chat */}
          <NewChat
            setNewChat={setNewChat}
            newChat={newChat}
            setChatInfo={props.setChatInfo}
            chatInfo={props.chatInfo}
            setChatGrpName={props.setChatGrpName}
          />
          <NewChatGroup
            setNewGroup={setNewGroup}
            newGroup={newGroup}
            setChatInfo={props.setChatInfo}
            chatInfo={props.chatInfo}
            mode="add"
          />
          {/* New Chat End */}

          <FlatList
            list={props.chatListSearch}
            renderItem={renderItem}
            // renderWhenEmpty={() => <div>List is empty!</div>}
            sort={{
              by: "created_dttm",
              descending: true,
            }}
            hasMoreItems={true}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default ChatMates;
