import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import NewReleasesIcon from "@mui/icons-material/NewReleases";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";

import PeekProfile from "../components/PeekProfile";

import * as ticketActions from "../../store/actions/ticket";
import * as profileActions from "../../store/actions/profile";

import formatTime from "../../utils/formatTime";
import getLabel from "../../utils/getLabel";

import { ticketStatus, selectListFilter } from "../../utils/selectOptions";

const DashboardTickets = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);
  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const ticketAdmin =
    roleArray && roleArray?.some((item) => item.role_name === "TICKET_ADMIN");

  const ticketOfficer =
    roleArray &&
    roleArray?.some(
      (item) =>
        item.role_name === "TICKET_READ" || item.role_name === "TICKET_WRITE"
    );

  const companyList = useSelector((state) => state.config.companyList);
  const issueList = useSelector((state) => state.config.issueList);

  const [ticketData, setTicketData] = useState();
  const [ticketDataSearch, setTicketDataSearch] = useState();

  const [loading, setLoading] = useState(true);

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileData, setProfileData] = useState([]);

  const [showList, setShowList] = useState(false);
  const [open, setOpen] = useState(false);

  const [ticketFilter, setTicketFilter] = useState("B");

  let rows = [];

  const handleOpen = (owner_id) => {
    traineeInfo(owner_id);
    setOpen(true);
  };

  const fetchTicket = async () => {
    try {
      setLoading(true);

      const data = {
        ticket_id: "",
        title: "",
        statPending: true,
        statInProgress: true,
        statResolved: false,
        company: isTrainee ? userData.company : null,
        issueType: null,
        priority: null,
        issueDtFrom: null,
        issueDtTo: new Date(),
        owner_id: isTrainee ? userData.user_id : null,
        assigned_to:
          ticketAdmin || ticketOfficer
            ? null
            : isTrainee
            ? null
            : userData.user_id,
        dashboard: true,
      };

      if (loading) {
        const output = await dispatch(ticketActions.dashboardTicket(data));
        const newOuput = output.filter((item) => item.status !== "RSV");

        setTicketData(newOuput);
        setTicketDataSearch(newOuput);

        if (!isTrainee) handleTicketFilter(newOuput, "B");

        !!newOuput &&
          newOuput?.map((item) => {
            const type = selectListFilter(
              issueList,
              item.type,
              "code",
              "descr",
              "Y",
              sysLanguage
            );

            const company = selectListFilter(
              companyList,
              item.company,
              "code",
              "descr",
              "Y",
              sysLanguage
            );

            const status = selectListFilter(
              ticketStatus(sysLanguage),
              item.status,
              "value",
              "label",
              "Y",
              sysLanguage
            );

            rows.push({
              ...item,
              type: type,
              company: company,
              status: status,
            });
          });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!roleArray) {
      fetchTicket();
    }
  }, [roleArray]);

  const traineeInfo = async (owner_id) => {
    const data = {
      batchNo: "",
      company: null,
      trainee: owner_id,
      visa: null,
      visaDateFrom: null,
      visaDateTo: null,
      passportDateFrom: null,
      passportDateTo: null,
      tioDateFrom: null,
      tioDateTo: null,
    };

    setLoadingProfile(true);
    const output = await dispatch(profileActions.profileDashboard(data));
    setLoadingProfile(false);
    setProfileData(output[0]);
  };

  const jumpToNavigate = async (ticket_id) => {
    console.log("jumpToNavigate: ", ticket_id);
    const ecryptedTicketId = btoa(ticket_id?.toString().padStart(7, "0"));
    navigate(`/updateTicket/${ecryptedTicketId}`, {});
  };

  const jumpToProfile = async (user_id) => {
    console.log("jumpToProfile: ", user_id);
    const ecryptedTicketId = btoa(user_id?.toString().padStart(7, "0"));
    navigate(`/MainProfile/${ecryptedTicketId}`, {});
  };

  const handleTicketFilter = async (data, filter) => {
    setTicketFilter(filter);

    if (filter === "A")
      setTicketData(data?.filter((item) => item.high_pri === true));

    if (filter === "B")
      setTicketData(
        data?.filter((item) => item.assigned_to === userData.user_id)
      );

    if (filter === "C")
      setTicketData(
        data?.filter((item) => item.watcher.includes(userData.user_id))
      );

    if (filter === "D")
      setTicketData(
        data?.filter(
          (item) => item.assigned_to === null || item.assigned_to === ""
        )
      );

    if (filter === "E") setTicketData(data);
  };

  return (
    <Fragment>
      <PeekProfile
        open={open}
        setOpen={setOpen}
        loadingProfile={loadingProfile}
        profileData={profileData}
        profileOfficer={props.profileOfficer}
      />
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          marginTop: 2,
        }}
      >
        {!loading ? (
          <Box
            sx={{
              marginX: 2,
              paddingY: 2,
            }}
          >
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              <Typography
                variant="h5"
                align="left"
                sx={{ mb: 1, color: "#5c5b5b" }}
              >
                {getLabel(sysLanguage, "2000", "2", "Tickets")}
              </Typography>
              <Button size="small" onClick={() => setShowList(!showList)}>
                {getLabel(
                  sysLanguage,
                  "2000",
                  showList ? "39" : "38",
                  "Show List"
                )}
              </Button>
            </Stack>
            {showList && (
              <Box>
                {(ticketOfficer || ticketAdmin) && (
                  <Stack
                    sx={{ m: 1, mb: 2 }}
                    justifyContent="center"
                    alignItems="center"
                    direction={"row"}
                    spacing={3}
                  >
                    <Button
                      size="small"
                      variant={ticketFilter === "A" ? "contained" : "outlined"}
                      endIcon={<NewReleasesIcon />}
                      onClick={() => handleTicketFilter(ticketDataSearch, "A")}
                    >
                      {getLabel(sysLanguage, "2000", "28", "High Priority")}
                    </Button>
                    <Button
                      size="small"
                      variant={ticketFilter === "B" ? "contained" : "outlined"}
                      endIcon={<AssignmentIcon />}
                      onClick={() => handleTicketFilter(ticketDataSearch, "B")}
                    >
                      {getLabel(sysLanguage, "2000", "29", "My Tickets")}
                    </Button>
                    <Button
                      size="small"
                      variant={ticketFilter === "C" ? "contained" : "outlined"}
                      endIcon={<VisibilityIcon />}
                      onClick={() => handleTicketFilter(ticketDataSearch, "C")}
                    >
                      {getLabel(sysLanguage, "2000", "30", "Watching Tickets")}
                    </Button>
                    <Button
                      size="small"
                      variant={ticketFilter === "D" ? "contained" : "outlined"}
                      endIcon={<AssignmentTurnedInIcon />}
                      onClick={() => handleTicketFilter(ticketDataSearch, "D")}
                    >
                      {getLabel(
                        sysLanguage,
                        "2000",
                        "31",
                        "Unassigned Tickets"
                      )}
                    </Button>
                    <Button
                      size="small"
                      variant={ticketFilter === "E" ? "contained" : "outlined"}
                      endIcon={<DensitySmallIcon />}
                      onClick={() => handleTicketFilter(ticketDataSearch, "E")}
                    >
                      {getLabel(sysLanguage, "3000", "76", "Open Tickets")}
                    </Button>
                  </Stack>
                )}
                {ticketData?.length > 0 ? (
                  <Fragment>
                    <Box>
                      <Box
                        sx={{
                          overflowY: "auto",
                          maxHeight: 300,
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                      >
                        <Fragment>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 300 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>#</TableCell>
                                  <TableCell>
                                    {getLabel(
                                      sysLanguage,
                                      "3000",
                                      "1",
                                      "Ticket ID"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {getLabel(
                                      sysLanguage,
                                      "3000",
                                      "14",
                                      "Title"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {isTrainee
                                      ? getLabel(
                                          sysLanguage,
                                          "3000",
                                          "19",
                                          "Assigned To"
                                        )
                                      : getLabel(
                                          sysLanguage,
                                          "3000",
                                          "31",
                                          "Trainee"
                                        )}
                                  </TableCell>
                                  {ticketAdmin && (
                                    <TableCell>
                                      {getLabel(
                                        sysLanguage,
                                        "3000",
                                        "19",
                                        "Assigned To"
                                      )}
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    {getLabel(
                                      sysLanguage,
                                      "3000",
                                      "58",
                                      "Last Update"
                                    )}
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {ticketData?.length > 0 &&
                                  ticketData?.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell component="th" scope="row">
                                        <Link
                                          component="button"
                                          underline="always"
                                          onClick={() =>
                                            jumpToNavigate(row.ticket_id)
                                          }
                                        >
                                          {row.ticket_id
                                            .toString()
                                            .padStart(7, "0")}
                                        </Link>
                                      </TableCell>
                                      <TableCell>{row.title}</TableCell>
                                      <TableCell>
                                        {!row.group_ticket && !isTrainee && (
                                          <IconButton
                                            color="primary"
                                            aria-label="like"
                                            component="label"
                                            onClick={() =>
                                              handleOpen(row.owner_id)
                                            }
                                          >
                                            <Avatar
                                              alt={row.trainee_avatar}
                                              src={row.trainee_avatar}
                                            />
                                          </IconButton>
                                        )}
                                        {isTrainee
                                          ? row.alternate_name
                                          : row.fullName}
                                      </TableCell>
                                      {ticketAdmin && (
                                        <TableCell>
                                          {row.alternate_name}
                                        </TableCell>
                                      )}

                                      <TableCell>
                                        {formatTime(row.last_update_dttm)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Fragment>
                      </Box>
                    </Box>
                  </Fragment>
                ) : (
                  <>{getLabel(sysLanguage, "2000", "25", "No Tickets")}</>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <CircularProgress sx={{ alignSelf: "center" }} />
          </Box>
        )}
      </Paper>
    </Fragment>
  );
};

export default DashboardTickets;
