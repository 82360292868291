import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import { Formik, Form } from "formik";

import GridOnIcon from "@mui/icons-material/GridOn";
import { CSVLink } from "react-csv";

import TableOne from "../components/TableOne";

import formatTime from "../../utils/formatTime";

import {
  selectListFilter,
  getOptions,
  mainRoleOption,
  accountStatus,
} from "../../utils/selectOptions";
import getLabel from "../../utils/getLabel";

import * as userActions from "../../store/actions/user";

const SearchUser = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const searchResultUser = useSelector((state) => state.user.searchUser);

  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState(null);
  const [locked, setLocked] = useState(null);
  const [roleName, setRoleName] = useState(null);

  const noFunction = (value) => {
    return value;
  };

  const boolFunction = (value) => {
    if (value) return getLabel(sysLanguage, "9000", "29", "Locked Out");
    if (!value) return getLabel(sysLanguage, "9000", "28", "Active");
  };

  const acessName = (value) => {
    if (value === "TRAINEE")
      return getLabel(sysLanguage, "9000", "39", "Trainee");
    if (value === "OFFICER")
      return getLabel(sysLanguage, "9000", "40", "Officer");
  };

  const headCells = [
    {
      id: "user_id",
      numeric: false,
      disablePadding: true,
      padding: "none",
      function: noFunction,
      link: true,
      label: getLabel(sysLanguage, "5000", "1", "Employee ID"),
    },
    {
      id: "fullname",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      link: false,
      label: getLabel(sysLanguage, "5000", "2", "Name"),
    },
    {
      id: "email_id",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "5000", "3", "Email ID"),
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "5000", "8", "Company"),
    },
    {
      id: "role_name",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: acessName,
      label: getLabel(sysLanguage, "5000", "9", "Access Type"),
    },
    {
      id: "position",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "5000", "10", "Designation"),
    },
    {
      id: "last_login_dttm",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: formatTime,
      label: getLabel(sysLanguage, "5000", "17", "Last Login"),
    },
    {
      id: "account_locked",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: boolFunction,
      label: getLabel(sysLanguage, "5000", "11", "Status"),
    },
  ];

  const handleSearch = async (data) => {
    setLoading(true);
    try {
      await dispatch(userActions.searchAccount(data));
    } catch (error) {
      setLoading(false);
    }
  };

  const jumpToNavigate = async (user_id) => {
    console.log("jumpToNavigate: ", user_id);
    const ecryptedTicketId = btoa(user_id?.toString().padStart(7, "0"));
    navigate(`/mainUser/${ecryptedTicketId}`, {});
  };

  let csvHeader = [
    { label: "User ID", key: "user_id" },
    { label: "Name", key: "fullName" },
    { label: "Email", key: "email_id" },
    { label: "Access Type", key: "role_name" },
    { label: "Designation", key: "position" },
    { label: "Company", key: "company" },
    { label: "Last Login ", key: "last_login_dttm" },
    { label: "Account Active", key: "account_locked" },
  ];

  let csvData = [];

  const rows = [];
  !!searchResultUser &&
    searchResultUser?.map((item) => {
      const company = selectListFilter(
        props.companyList,
        item.company,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      rows.push({ ...item, company: company });

      const forCSV = {
        user_id: item.user_id,
        fullName: item.fullname,
        email_id: item.email_id,
        role_name: item.role_name,
        position: item.position,
        company: company,
        last_login_dttm: formatTime(item.last_login_dttm),
        account_locked: !item.account_locked,
      };

      csvData.push(forCSV);
    });

  return (
    <Box sx={{ p: 1 }}>
      <Formik
        initialValues={{
          user_id: "",
          fullname: "",
          email_id: "",
        }}
        onSubmit={(values) => {
          const addedData = {
            ...values,
            company: !!company ? company.value : null,
            role_name: !!roleName ? roleName.value : null,
            account_locked: !!locked ? locked.value : null,
          };
          handleSearch(addedData);
        }}
      >
        {({ handleChange, values, resetForm }) => (
          <Form>
            <Stack spacing={1}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  id="user_id"
                  name="user_id"
                  label={getLabel(sysLanguage, "5000", "1", "Employee ID")}
                  value={values.user_id}
                  onChange={handleChange("user_id")}
                  size="small"
                  sx={{ width: 200 }}
                />
                <TextField
                  id="fullname"
                  name="fullname"
                  label={getLabel(sysLanguage, "5000", "2", "Name")}
                  value={values.fullname}
                  onChange={handleChange("fullname")}
                  size="small"
                  sx={{ width: 400 }}
                />
                <TextField
                  id="email_id"
                  name="email_id"
                  label={getLabel(sysLanguage, "5000", "3", "Email ID")}
                  value={values.email_id}
                  onChange={handleChange("email_id")}
                  size="small"
                  sx={{ width: 300 }}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Autocomplete
                  id="role_name"
                  name="role_name"
                  value={roleName}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => setRoleName(data)}
                  size="small"
                  options={mainRoleOption(sysLanguage)}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "5000",
                        "4",
                        "Select Access Type"
                      )}
                    />
                  )}
                />
                <Autocomplete
                  id="company"
                  name="company"
                  value={company}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => setCompany(data)}
                  size="small"
                  options={getOptions(sysLanguage, props.companyList)}
                  sx={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "5000",
                        "5",
                        "Select Company"
                      )}
                    />
                  )}
                />
                <Autocomplete
                  disablePortal
                  id="account_locked"
                  name="account_locked"
                  value={locked}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, data) => setLocked(data)}
                  size="small"
                  options={accountStatus(sysLanguage)}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getLabel(
                        sysLanguage,
                        "5000",
                        "6",
                        "Select Account Status"
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Button
                  sx={{ m: 1 }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  {getLabel(sysLanguage, "9000", "1", "Search")}
                </Button>
                <Button
                  sx={{ m: 1 }}
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    setCompany(null);
                    setRoleName(null);
                    setLocked(null);
                    resetForm();
                  }}
                >
                  {getLabel(sysLanguage, "9000", "2", "Clear")}
                </Button>
              </Grid>
            </Stack>
          </Form>
        )}
      </Formik>
      {rows.length > 0 && (
        <Box>
          <TableOne
            headCells={headCells}
            rows={rows}
            jumpToNavigate={jumpToNavigate}
          />
          <Grid container justifyContent="flex-end" style={{ paddingRight: 5 }}>
            <CSVLink
              filename="systemuser.csv"
              target="_blank"
              data={csvData}
              headers={csvHeader}
            >
              <Button size="large" variant="outlined" endIcon={<GridOnIcon />}>
                {getLabel(sysLanguage, "9000", "54", "Export")}
              </Button>
            </CSVLink>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SearchUser;
