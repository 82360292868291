import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import getLabel from "../../utils/getLabel";

import * as ticketActions from "../../store/actions/ticket";

ChartJS.register(ArcElement, Tooltip, Legend);

const GraphDashboard = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);
  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const ticketAdmin =
    roleArray && roleArray?.some((item) => item.role_name === "TICKET_ADMIN");

  const ticketOfficer =
    roleArray &&
    roleArray?.some(
      (item) =>
        item.role_name === "TICKET_READ" || item.role_name === "TICKET_WRITE"
    );

  const [countNotStarted, setCounNotStarted] = useState();
  const [countInProg, setCountInProg] = useState();

  const [countOne, setCountOne] = useState();
  const [countTwo, setCountTwo] = useState();
  const [countThree, setCountThree] = useState();

  const [loading, setLoading] = useState(true);

  const fetchTicket = async () => {
    try {
      setLoading(true);

      const data = {
        ticket_id: "",
        title: "",
        statPending: true,
        statInProgress: true,
        statResolved: false,
        company: isTrainee ? userData.company : null,
        issueType: null,
        priority: null,
        issueDtFrom: null,
        issueDtTo: new Date(),
        owner_id: isTrainee ? userData.user_id : null,
        assigned_to:
          ticketAdmin || ticketOfficer
            ? null
            : isTrainee
            ? null
            : userData.user_id,
        dashboard: true,
      };

      if (loading) {
        const output = await dispatch(ticketActions.dashboardTicket(data));

        setCounNotStarted(
          output.filter((item) => item.status === "PEN").length
        );
        setCountInProg(output.filter((item) => item.status === "INP").length);
        setCountOne(
          output.filter(
            (item) => item.status !== "RSV" && item.opendaycount <= 7
          ).length
        );
        setCountTwo(
          output.filter(
            (item) =>
              item.status !== "RSV" &&
              item.opendaycount > 7 &&
              item.opendaycount <= 30
          ).length
        );
        setCountThree(
          output.filter(
            (item) => item.status !== "RSV" && item.opendaycount > 30
          ).length
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTicket();
  }, []);

  const data1 = {
    maintainAspectRatio: true,
    responsive: true,
    labels: [
      getLabel(sysLanguage, "3000", "59", "Not Stared"),
      getLabel(sysLanguage, "3000", "60", "In-Progress"),
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [countNotStarted, countInProg],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    maintainAspectRatio: true,
    responsive: true,
    labels: [
      getLabel(sysLanguage, "3000", "61", "< 1 week"),
      getLabel(sysLanguage, "3000", "62", "> 2 weeks"),
      getLabel(sysLanguage, "3000", "63", "> 1 month"),
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [countOne, countTwo, countThree],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],

        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const option1 = {
    layout: {
      padding: { top: 10 },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  return (
    <Fragment>
      {(ticketAdmin || ticketOfficer) && (
        <Fragment>
          {countNotStarted > 0 || countInProg > 0 ? (
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                maxHeight: 350,
                overflow: "hidden",
                overflowY: "scroll",
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
              }}
            >
              {getLabel(sysLanguage, "2000", "14", "Active Ticket Analysis")}
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid>
                  <Typography variant="body1">
                    {getLabel(sysLanguage, "2000", "15", "Ticket Status")}
                  </Typography>
                  <Doughnut
                    data={data1}
                    //   width={100}
                    height={200}
                    options={option1}
                  />
                </Grid>
                <Grid>
                  <Typography variant="body1">
                    {getLabel(sysLanguage, "2000", "16", "Ticket Age")}
                  </Typography>
                  <Doughnut
                    data={data2}
                    //   width={100}
                    height={200}
                    options={option1}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>{getLabel(sysLanguage, "2000", "25", "Graph Not Available")}</>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default GraphDashboard;
