import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FlatList from "flatlist-react";

import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";

import { selectListFilter, ticketStatus } from "../../utils/selectOptions";

import GraphDashboard from "../dashboard/GraphDashboard";
import MultiName from "../components/MultiName";
import PeekProfile from "../components/PeekProfile";

import formatTime from "../../utils/formatTime";
import getLabel from "../../utils/getLabel";

import * as ticketActions from "../../store/actions/ticket";
import * as profileActions from "../../store/actions/profile";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginTop: 5,
  justifyContent: "center",
  alignContent: "center",
  color: theme.palette.text.secondary,
}));

const CompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const sysLanguage = useSelector((state) => state.config.labels);
  const companyOne = useSelector((state) =>
    state.config.companyList.filter((item) => item.code === props.companyId)
  );
  const companyLabel = sysLanguage.filter(
    (item) =>
      item.msgset === companyOne.msgset && item.msgnbr === companyOne.msgnbr
  );
  const issueList = useSelector((state) => state.config.issueList);
  const occupationList = useSelector((state) => state.config.occupationList);

  const [loading, setLoading] = useState(true);

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileData, setProfileData] = useState([]);

  const [openName, setOpenName] = useState(false);
  const [open, setOpen] = useState(false);

  const [ticketData, setTicketData] = useState();
  const [ticketDataSearch, setTicketDataSearch] = useState();

  const traineeInfo = async (owner_id) => {
    const data = {
      batchNo: "",
      company: null,
      trainee: owner_id,
      visa: null,
      visaDateFrom: null,
      visaDateTo: null,
      passportDateFrom: null,
      passportDateTo: null,
      tioDateFrom: null,
      tioDateTo: null,
    };

    setLoadingProfile(true);
    const output = await dispatch(profileActions.profileDashboard(data));
    setLoadingProfile(false);
    setProfileData(output[0]);
  };

  const handleOpen = (owner_id) => {
    traineeInfo(owner_id);
    setOpen(true);
  };

  const fetchTicket = async () => {
    try {
      setLoading(true);

      const data = {
        ticket_id: "",
        title: "",
        statPending: true,
        statInProgress: true,
        statResolved: false,
        company: props.companyId,
        priority: null,
        issueDtFrom: null,
        issueDtTo: new Date(),
        owner_id: null,
        assigned_to: null,
        dashboard: true,
      };

      if (loading) {
        const output = await dispatch(ticketActions.dashboardTicket(data));
        const newOuput = output.filter((item) => item.status !== "RSV");

        setTicketData(newOuput);
        setTicketDataSearch(newOuput);

        !!newOuput &&
          newOuput?.map((item) => {
            const type = selectListFilter(
              issueList,
              item.type,
              "code",
              "descr",
              "Y",
              sysLanguage
            );

            const company = selectListFilter(
              props.companyList,
              item.company,
              "code",
              "descr",
              "Y",
              sysLanguage
            );

            const status = selectListFilter(
              ticketStatus(sysLanguage),
              item.status,
              "value",
              "label",
              "Y",
              sysLanguage
            );

            rows.push({
              ...item,
              type: type,
              company: company,
              status: status,
            });
          });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTicket();
  }, []);

  let rows = [];

  const TicketTable = () => {
    return (
      <Fragment>
        <Typography variant="h5" align="left" sx={{ mb: 1, color: "#5c5b5b" }}>
          {getLabel(sysLanguage, "2000", "37", "Active Tickets")}
          {" ("}
          {ticketData?.length}
          {")"}
        </Typography>
        <Box>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: 600,
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            <Fragment>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>
                        {getLabel(sysLanguage, "3000", "1", "Ticket ID")}
                      </TableCell>
                      <TableCell>
                        {getLabel(sysLanguage, "3000", "14", "Title")}
                      </TableCell>
                      <TableCell>
                        {getLabel(sysLanguage, "3000", "31", "Trainee")}
                      </TableCell>
                      <TableCell>
                        {getLabel(sysLanguage, "3000", "19", "Assigned To")}
                      </TableCell>
                      <TableCell>
                        {getLabel(sysLanguage, "3000", "58", "Last Update")}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketData?.length > 0 &&
                      ticketData?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell component="th" scope="row">
                            <Link
                              component="button"
                              underline="always"
                              onClick={() => jumpToNavigate(row.ticket_id)}
                            >
                              {row.ticket_id.toString().padStart(7, "0")}
                            </Link>
                          </TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>
                            {!row.group_ticket && (
                              <IconButton
                                color="primary"
                                aria-label="like"
                                component="label"
                                onClick={() => handleOpen(row.owner_id)}
                              >
                                <Avatar
                                  alt={row.trainee_avatar}
                                  src={row.trainee_avatar}
                                />
                              </IconButton>
                            )}
                            {row.fullName}
                          </TableCell>
                          <TableCell>{row.alternate_name}</TableCell>
                          <TableCell>
                            {formatTime(row.last_update_dttm)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Fragment>
          </Box>
        </Box>
      </Fragment>
    );
  };

  const jumpToNavigate = async (ticket_id) => {
    console.log("jumpToNavigate: ", ticket_id);
    const ecryptedTicketId = btoa(ticket_id?.toString().padStart(7, "0"));
    navigate(`/updateTicket/${ecryptedTicketId}`, {});
  };

  const getName = (msgset, msgnbr, defVal) => {
    const labels = sysLanguage.filter(
      (item) => item.msgset === msgset && item.msgnbr === msgnbr
    );
    const label = labels[0].label || defVal;
    return label;
  };

  const renderItem = (item, idx) => {
    return (
      <List key={idx} sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <IconButton
              color="primary"
              aria-label="like"
              component="label"
              onClick={() => handleOpen(item.user_id)}
            >
              <Avatar alt={item.avatar} src={item.avatar} />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item.fullName}
                </Typography>
                {/* {" " + item.company} */}
              </Fragment>
            }
            secondary={
              <Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.primary"
                >
                  {selectListFilter(
                    occupationList,
                    item.occupation,
                    "code",
                    "descr",
                    "Y",
                    sysLanguage
                  )}
                </Typography>
              </Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <PeekProfile
        open={open}
        setOpen={setOpen}
        loadingProfile={loadingProfile}
        profileData={profileData}
        profileOfficer={true}
      />
      <MultiName
        open={openName}
        setOpen={setOpenName}
        compLabel={getName(
          companyOne[0].msgset,
          companyOne[0].msgnbr,
          companyOne[0].descr
        )}
        msgset={companyOne[0].msgset}
        msgnbr={companyOne[0].msgnbr}
        labelSet="3000"
        labelNbr="17"
      />
      {loadingProfile && loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <BusinessIcon
                    sx={{ fontSize: 40, color: "#42a5f5", marginRight: 1 }}
                  />

                  <Typography variant="h5" gutterBottom>
                    {getName(
                      companyOne[0].msgset,
                      companyOne[0].msgnbr,
                      companyOne[0].descr
                    )}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    ({companyOne[0].code})
                  </Typography>
                </Box>
                <IconButton
                  sx={{ marginRight: 1 }}
                  onClick={() => setOpenName(true)}
                >
                  <EditIcon sx={{ fontSize: 30, color: "#42a5f5" }} />
                </IconButton>
              </Box>
            </Item>
            {/* <Item>
            <GraphDashboard />
          </Item> */}
            <Item>
              <Box>
                <TicketTable />
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <Typography
                variant="h5"
                align="left"
                sx={{ mb: 1, color: "#5c5b5b" }}
              >
                {getLabel(sysLanguage, "9000", "39", "Trainees")}
                {" ("}
                {props.profileList?.length}
                {")"}
              </Typography>
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: windowSize.current[1],
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <FlatList
                  list={props.profileList}
                  renderItem={renderItem}
                  // renderWhenEmpty={() => <div>List is empty!</div>}
                  sort={{
                    by: "fullName",
                    descending: false,
                  }}
                  hasMoreItems={true}
                />
              </Box>
            </Item>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CompanyPage;
