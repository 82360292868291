import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import * as authActions from "../store/actions/auth";

export default function Types() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    navigate(`/`, { replace: true });
    await dispatch(authActions.logOut());
  };

  return (
    <Box sx={{ m: 5, width: "100%" }}>
      <Typography variant="h3" gutterBottom>
        Session Expired or Not Authorized
      </Typography>
      <Button href="/" onClick={() => handleLogout()}>
        <Typography variant="h6" gutterBottom>
          Log In
        </Typography>
      </Button>
    </Box>
  );
}
