import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import * as authActions from "../../store/actions/auth";
import * as configActions from "../../store/actions/config";

import { createNotifText } from "../../utils/selectOptions";
import formatTime from "../../utils/formatTime";
import getLabel from "../../utils/getLabel";

import * as chatActions from "../../store/actions/chat";

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState([]);

  const jumpToTicket = async (ticket_id, alert_id) => {
    deleteAlert(alert_id);
    console.log("jumpToTicket: ", ticket_id);
    const ecryptedTicketId = btoa(ticket_id?.toString().padStart(7, "0"));
    navigate(`/updateTicket/${ecryptedTicketId}`, {});
  };

  const deleteAlert = async (alert_id) => {
    setAlert(alert?.filter((item) => item.alert_id !== alert_id));
    await dispatch(configActions.hideAlert(alert_id));
  };

  useEffect(() => {
    const getAlerts = async () => {
      setLoading(true);
      const output = await dispatch(configActions.getAlerts());
      setAlert(output);
      setLoading(false);
    };

    if (loading) getAlerts();
  }, [loading]);

  return (
    <Fragment>
      <Stack direction={"row"}>
        <Typography variant="h5" align="left" sx={{ color: "#5c5b5b" }}>
          {getLabel(sysLanguage, "2000", "19", "Notification Center")}
        </Typography>
        {alert?.length > 0 && (
          <Chip
            label={alert?.length}
            // variant="outlined"
            color="warning"
            size="small"
            sx={{ marginX: 0.3 }}
          ></Chip>
        )}
      </Stack>
      <Box sx={style}>
        {!!alert &&
          alert?.map((row, index) => (
            <List key={index}>
              <ListItem alignItems="center" sx={{ height: 75 }}>
                <ListItemAvatar>
                  <Avatar
                    alt={row?.alternate_name}
                    src={row?.avatar}
                    sx={{ width: 45, height: 45 }}
                  />
                </ListItemAvatar>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ListItemText
                    primary={row.alternate_name}
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              jumpToTicket(row.source_id, row.alert_id)
                            }
                          >
                            [{row.source_id.toString().padStart(7, "0")}]
                          </Link>
                        </Typography>{" "}
                        {createNotifText(sysLanguage, row)}
                        <Typography variant="body2">
                          {formatTime(row.action_dttm)}
                        </Typography>
                      </Fragment>
                    }
                  />
                  <IconButton
                    color="error"
                    aria-label="delete"
                    component="label"
                    onClick={() => deleteAlert(row.alert_id)}
                  >
                    <DeleteForeverIcon color="error" fontSize="small" />
                  </IconButton>
                </Grid>
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          ))}
      </Box>
    </Fragment>
  );
};

export default Notifications;

const style = {
  overflowY: "auto",
  minHeight: 200,
  maxHeight: 750,
};
