export const GET_LABEL = "GET_LABEL";
export const GET_ROLES = "GET_ROLES";
export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS";
export const GET_AWS = "GET_AWS";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_ISSUELIST = "GET_ISSUELIST";
export const GET_TRAINEE = "GET_TRAINEE";
export const GET_OFFICER = "GET_OFFICER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_VISALIST = "GET_VISALIST";
export const GET_OCCUPATION = "GET_OCCUPATION";
export const GET_BANNER = "GET_BANNER";
export const GET_ONEBANNER = "GET_ONEBANNER";
export const OPEN_ONE_BANNER = "OPEN_ONE_BANNER";
export const DELETEBANNER = "DELETEBANNER";
export const GETCOMMENT = "GETCOMMENT";

export const CLEAR_CONFIG = "CLEAR_CONFIG";

const initialState = {
  awsKey: "",
  attachments: "",
  labels: "",
  companyList: "",
  visaList: "",
  issueList: "",
  occupationList: "",
  traineeName: "",
  officerName: "",
  bannerList: "",
  bannerListOne: "",
  openOneBanner: "",
  roleList: "",
  oneComment: "",
};

const config = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case GET_AWS:
      return {
        ...state,
        awsKey: action.awsCred,
      };
    case GETCOMMENT:
      return {
        ...state,
        oneComment: action.oneComment,
      };

    case GET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.attachments,
      };
    case CLEAR_ATTACHMENTS:
      return {
        ...state,
        attachments: "",
      };
    case GET_LABEL:
      return {
        ...state,
        labels: action.labels,
      };
    case GET_COMPANY:
      return {
        ...state,
        companyList: action.companyList,
      };
    case GET_COUNTRY:
      return {
        ...state,
        countryList: action.countryList,
      };
    case GET_ROLES:
      return {
        ...state,
        roleList: action.roleList,
      };
    case GET_ISSUELIST:
      return {
        ...state,
        issueList: action.issueList,
      };
    case GET_TRAINEE:
      return {
        ...state,
        traineeName: action.traineeName,
      };
    case GET_OFFICER:
      return {
        ...state,
        officerName: action.officerName,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.allUsers,
      };
    case GET_VISALIST:
      return {
        ...state,
        visaList: action.visaList,
      };
    case GET_OCCUPATION:
      return {
        ...state,
        occupationList: action.occupationList,
      };
    case GET_BANNER:
      return {
        ...state,
        bannerList: action.bannerList,
      };
    case GET_ONEBANNER:
      return {
        ...state,
        bannerListOne: action.bannerListOne[0],
      };
    case OPEN_ONE_BANNER:
      if (!!action.bannerId) {
        const openBanner = state.bannerList?.filter(
          (item) => item.row_id === action.bannerId
        );

        return {
          ...state,
          openOneBanner: openBanner[0],
        };
      } else {
        return {
          ...state,
          openOneBanner: null,
        };
      }
    case DELETEBANNER:
      const updatedBanner = state.bannerList.filter(
        (item) => item.row_id !== action.row_id
      );

      return {
        ...state,
        bannerList: updatedBanner,
      };

    case CLEAR_CONFIG:
      return {
        // ...state,
        awsKey: "",
        attachments: "",
        labels: "",
        companyList: "",
        visaList: "",
        issueList: "",
        occupationList: "",
        bannerList: "",
        traineeName: "",
        officerName: "",
        openOneBanner: "",
        roleList: "",
        oneComment: "",
      };

    default:
      return state;
  }
};

export default config;
