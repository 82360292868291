import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";

import { Formik, Form } from "formik";

import TableOne from "../components/TableOne";
import getLabel from "../../utils/getLabel";
import { getOptions, selectListFilter } from "../../utils/selectOptions";

import * as ticketActions from "../../store/actions/ticket";

const SearchTicket = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const [searchGroup, setSearchGroup] = useState([]);
  const [company, setCompany] = useState(null);

  const [loading, setLoading] = useState(false);

  const noFunction = (value) => {
    return value;
  };

  const countFunction = (value) => {
    return value?.length;
  };

  const headCells = [
    {
      id: "group_id",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      link: true,
      label: getLabel(sysLanguage, "3000", "64", "Group ID"),
    },
    {
      id: "group_name",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "65", "Group Name"),
    },
    {
      id: "group_members",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: countFunction,
      label: getLabel(sysLanguage, "3000", "74", "Member Count"),
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      padding: "",
      function: noFunction,
      label: getLabel(sysLanguage, "3000", "17", "Company"),
    },
  ];

  const handleSearch = async (data) => {
    setLoading(true);
    try {
      const output = await dispatch(ticketActions.getAllGroup(data));
      setSearchGroup(output);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const jumpToNavigate = async (group_id) => {
    console.log("jumpToNavigate: ", group_id);
    const encryptedGroupId = btoa(group_id);
    navigate(`/mainGroup/${encryptedGroupId}`, {});
  };

  const rows = [];
  searchGroup &&
    searchGroup.map((item) => {
      const company = selectListFilter(
        props.companyList,
        item.company,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      rows.push({ ...item, company: company });
    });

  return (
    <div>
      <Box sx={{ p: 1 }}>
        <Formik
          initialValues={{
            group_id: "",
            group_name: "",
          }}
          onSubmit={(values) => {
            const addedData = {
              ...values,
              company: !!company ? company.value : null,
            };
            handleSearch(addedData);
          }}
        >
          {({ handleChange, values, setFieldValue, resetForm }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item>
                  <Stack spacing={1}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Autocomplete
                        value={company}
                        id="company"
                        name="company"
                        getOptionLabel={(option) => option.label || ""}
                        onChange={(e, data) => {
                          setCompany(data);
                        }}
                        size="small"
                        options={getOptions(sysLanguage, props.companyList)}
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getLabel(
                              sysLanguage,
                              "3000",
                              "5",
                              "Select Company"
                            )}
                          />
                        )}
                      />
                      <TextField
                        id="group_id"
                        name="group_id"
                        label={getLabel(sysLanguage, "3000", "64", "Group ID")}
                        value={values.group_id}
                        onChange={handleChange("group_id")}
                        size="small"
                        sx={{ width: 200 }}
                      />

                      <TextField
                        id="group_name"
                        name="group_name"
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "65",
                          "Group Name"
                        )}
                        value={values.group_name}
                        onChange={handleChange("group_name")}
                        size="small"
                        sx={{ width: 300 }}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignContent: "flex-end",
                  mt: 1,
                }}
              >
                <LoadingButton
                  sx={{ m: 1, width: 150 }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  {getLabel(sysLanguage, "9000", "1", "Search")}
                </LoadingButton>
                <Button
                  sx={{ m: 1, width: 150 }}
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    setCompany(null);
                    resetForm();
                  }}
                >
                  {getLabel(sysLanguage, "9000", "2", "Clear")}
                </Button>
                {!isTrainee && (
                  <Grid container justifyContent="flex-end">
                    <Button
                      sx={{ m: 1 }}
                      color="primary"
                      variant="contained"
                      onClick={() => jumpToNavigate("new")}
                    >
                      {getLabel(sysLanguage, "3000", "68", "Create Group")}
                    </Button>
                  </Grid>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        {rows.length > 0 && (
          <TableOne
            headCells={headCells}
            rows={rows}
            jumpToNavigate={jumpToNavigate}
          />
        )}
      </Box>
    </div>
  );
};

export default SearchTicket;
