function formatTimeChat(date) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date();
  const secondDate = new Date(date);

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  if (date) {
    if (diffDays > 6) {
      return new Date(date).toLocaleDateString("en-ZA", {
        timeZone: "Japan",
        weekday: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    } else {
      return (
        new Date(date).toLocaleDateString("en-ZA", {
          timeZone: "Japan",
          weekday: "short",
        }) +
        " " +
        new Date(date).toLocaleString("en-ZA", {
          timeZone: "Japan",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        })
      );
    }
  } else {
    return null;
  }
}

export default formatTimeChat;
