import React from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "../screens/dashboard/Dashboard";
// import Dashboard2 from "../screens/dashboard/Dashboard2";

import UpdateTicket from "../screens/ticket/UpdateTicket";
import ManageTicket from "../screens/ticket/ManageTicket";
import ManageGroup from "../screens/group/ManageGroup";
import MainGroup from "../screens/group/MainGroup";
import ManageProfile from "../screens/profile/ManageProfile";
import MainProfile from "../screens/profile/MainProfile";
import ManageUser from "../screens/user/ManageUser";
import MainUser from "../screens/user/MainUser";
import ManageCompany from "../screens/company/ManageCompany";
import MainCompany from "../screens/company/MainCompany";
import MyAccount from "../screens/user/MyAccount";
import ManageBanner from "../screens/announcement/ManageBanner";
import MainBanner from "../screens/announcement/MainBanner";
import ChatMain from "../screens/chat/ChatMain";
import Learning from "../screens/docs/Learning";

import NotFound from "./NotFound";

const HomeNavigator = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />;
      {/* <Route path="/new" element={<Dashboard2 />} />; */}
      <Route path="/updateTicket/:ticket_id" element={<UpdateTicket />} />;
      <Route path="/manageTicket" element={<ManageTicket />} />;
      <Route path="/manageGroup" element={<ManageGroup />} />;
      <Route path="/mainGroup/:group_id" element={<MainGroup />} />;
      <Route path="/traineeProfile" element={<ManageProfile />} />;
      <Route path="/mainProfile/:profile_id" element={<MainProfile />} />;
      <Route path="/manageMyProfile" element={<MainProfile />} />;
      <Route path="/manageUser" element={<ManageUser />} />;
      <Route path="/mainUser/:user_id" element={<MainUser />} />;
      <Route path="/myaccount/:user_id" element={<MyAccount />} />;
      <Route path="/manageBanner" element={<ManageBanner />} />;
      <Route path="/mainBanner" element={<MainBanner />} />;
      <Route path="/chatMain" element={<ChatMain />} />;
      <Route path="/manageCompany" element={<ManageCompany />} />;
      <Route path="/mainCompany/:companyId" element={<MainCompany />} />;
      <Route path="/learning" element={<Learning />} />;
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default HomeNavigator;
