import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { io } from "socket.io-client";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";

import { v4 as uuid } from "uuid";
import FlatList from "flatlist-react";

import * as chatActions from "../../store/actions/chat";

import getEnvironment from "../../utils/environments";
import getLabel from "../../utils/getLabel";

const url = getEnvironment().apiKey;
const socket = io.connect(url);

const NewChat = (props) => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);
  const userData = useSelector((state) => state.auth.userData[0]);

  const companyList = useSelector((state) => state.config.companyList);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const allNames = useSelector((state) =>
    isTrainee
      ? state.config.allUsers?.filter(
          (item) =>
            item.code !== userData.user_id &&
            (item.company === userData.company || item.role_name === "OFFICER")
        )
      : state.config.allUsers?.filter((item) => item.code !== userData.user_id)
  );

  // const closeModal = () => props.setNewChat(false)

  const [searchFilter, setSearchFilter] = useState(allNames);

  const handleSearch = (event) => {
    // if (event.target.value.length < 1) {
    //   setSearchFilter("");
    //   return;
    // }

    const newData = allNames.filter((item) => {
      const itemData = `${
        item.code
      } ${item.descr?.toUpperCase()} ${item.company?.toUpperCase()} `;

      const textData = event.target.value.toUpperCase();

      return itemData.indexOf(textData) > -1;
    });

    setSearchFilter(newData);
  };

  const getNames = (user_id) => {
    const result =
      !!allNames && allNames.filter((item) => item.code === user_id);
    return result[0]?.descr;
  };

  const getAttribute = (user_id) => {
    const result =
      !!allNames && allNames.filter((item) => item.code === user_id);

    const companyName = companyList.filter(
      (item) => item.code === result[0]?.company
    );

    if (result[0]?.role_name === "OFFICER") {
      return result[0]?.position;
    } else {
      return !!companyName[0]?.code
        ? "[" + companyName[0]?.code + "] " + companyName[0]?.descr
        : null;
    }
  };

  const handleNewChat = async (chatMate) => {
    const chatId = await dispatch(
      chatActions.getChatIdNewChat(chatMate, userData.user_id)
    );

    const chatDetails = {
      chat_hdr_id: !!chatId?.chat_hdr_id ? chatId.chat_hdr_id : uuid(),
      chat_type: "P",
      chat_name: chatMate,
      chat_name_descr: getNames(chatMate),
      participants: [userData.user_id, chatMate],
      mode: "add",
    };

    props.setChatGrpName(getNames(chatMate));
    props.setChatInfo(chatDetails);

    props.setNewChat(false);
  };

  const renderUsers = (item, index) => {
    return (
      <List
        key={index}
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        <Button
          variant="text"
          sx={{
            ":hover": {
              bgcolor: "#03a9f4",
              color: "#03a9f4",
            },
            width: "100%",
            height: 50,
          }}
          onClick={async () => {
            handleNewChat(item.value);
          }}
        >
          <ListItem alignItems="center">
            <ListItemAvatar>
              <Avatar alt={item.avatar} src={item.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#01579b",
                        lineHeight: "13px",
                        fontSize: 13,
                        fontWeight: "400",
                      }}
                    >
                      {item.descr}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontWeight: "300",

                        color: "#7b1fa2",
                      }}
                    >
                      {getAttribute(item.code)}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </ListItem>
        </Button>
        <Divider variant="inset" component="li" />
      </List>
    );
  };

  return (
    <div>
      <Modal
        open={props.newChat}
        // onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 1 }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {getLabel(sysLanguage, "6000", "33", "Search User")}
            </Typography>
            <CloseIcon
              color="error"
              fontSize="large"
              onClick={() => props.setNewChat(false)}
            />
          </Grid>
          <Divider variant="left" />
          <div style={{ margin: 1 }}>
            <TextField
              id="standard-search"
              label={getLabel(sysLanguage, "6000", "33", "Search User")}
              type="search"
              variant="standard"
              onChange={handleSearch}
              sx={{ margin: "0.3rem", width: "60%" }}
            />
          </div>
          <div
            style={{
              marginTop: "1rem",
              margin: ".3rem",
              overflow: "auto",
              // minHeight: window.innerHeight * 0.3,
              // maxHeight: window.innerHeight * 0.52,
              height: window.innerHeight * 0.45,
            }}
          >
            <FlatList
              list={searchFilter}
              renderItem={renderUsers}
              // renderWhenEmpty={() => <div>List is empty!</div>}
              sort={{
                by: "code",
                descending: false,
              }}
              hasMoreItems={true}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NewChat;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minHeight: 600,
  maxHeight: 600,
};
