import React, { Fragment, useState } from "react";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

import BannerDash from "./BannerDash";
import TopCards from "./TopCards";
import DashboardTickets from "./DashboardTickets";
import AlertsDashboard from "./AlertsDashboard";
import GraphDashboard from "./GraphDashboard";

import Notifications from "./Notifications";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  elevation: 3,
}));

const TraineeDash = (props) => {
  return (
    <Fragment>
      {/* <Box
        sx={{
          flex: 1,
          marginTop: 2,
          margin: 2,
        }}
      >
        <TopCards />
      </Box> */}
      <Box
        sx={{
          flex: 1,
          margin: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid xs={8}>
            <Grid container>
              <Box
                sx={{
                  marginX: 3,
                  width: "100%",
                }}
              >
                {props.isOfficer && <TopCards />}
                <BannerDash
                  setBulletinNbr={props.setBulletinNbr}
                  bulletinNbr={props.bulletinNbr}
                  dashBannerList={props.dashBannerList}
                  awsKey={props.awsKey}
                  setLike={props.setLike}
                  like={props.like}
                  setDislike={props.setDislike}
                  dislike={props.dislike}
                  selRow={props.selRow}
                  setSelRow={props.setSelRow}
                  refreshBanner={props.refreshBanner}
                  companyList={props.companyList}
                  bannerOfficer={props.bannerOfficer}
                />
                <DashboardTickets profileOfficer={props.profileOfficer} />
              </Box>
            </Grid>
          </Grid>
          <Grid xs>
            <Item>
              <Notifications />
            </Item>
          </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={6} md={7}>
            <Stack spacing={2}>
            // <Item>Notifications</Item> 
              {props.isOfficer && (
                <Fragment>
                  {props.profileOfficer && (
                    <Item key={1}>
                      <AlertsDashboard />
                    </Item>
                  )}
                  <Item>
                    <GraphDashboard />
                  </Item>
                </Fragment>
              )}
              <Item>
                <DashboardTickets />
              </Item>
            </Stack>
          </Grid>
          <Grid xs={6} md={5}>
            <Item>
              <BannerDash
                dashBannerList={props.dashBannerList}
                awsKey={props.awsKey}
                setLike={props.setLike}
                like={props.like}
                setDislike={props.setDislike}
                dislike={props.dislike}
                selRow={props.selRow}
                setSelRow={props.setSelRow}
                refreshBanner={props.refreshBanner}
                companyList={props.companyList}
              />
            </Item>
          </Grid>
        </Grid>
      </Box> */}
    </Fragment>
  );
};

export default TraineeDash;
