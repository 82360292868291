import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LinkItUrl } from "react-linkify-it";
import { v4 as uuid } from "uuid";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import TranslateIcon from "@mui/icons-material/Translate";

import formatTimeChat from "../../utils/formatTimeChat";

import Confirmation from "../components/Confirmation";

import getLabel from "../../utils/getLabel";

import colors from "../../colors";

import * as chatActions from "../../store/actions/chat";
import * as configActions from "../../store/actions/config";

const MessageBox = (props) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.userData[0]);

  const sysLanguage = useSelector((state) => state.config.labels);

  // const companyList = useSelector((state) => state.config.companyList);
  // const allNames = useSelector((state) => state.config.allUsers);

  const [del, setDel] = useState(false);
  const [delChatId, setDelChatId] = useState("");

  const [details, setDetails] = useState(false);
  const [detailsID, setDetailsID] = useState("");

  const [translate, setTranslate] = useState(false);
  const [transId, setTransId] = useState("");

  const [loading, setLoading] = useState(false);
  const [translatedText, setTranslatedText] = useState("");

  const scrollToBottom = () => {
    props.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!props.asIs) scrollToBottom();
    return () => {
      props.setAsIs(false);
    };
  }, [props.chatInfo?.chat_hdr_id]);

  const getSeenChat = async (message) => {
    try {
      props.setLoadSeen(true);
      const results = await dispatch(
        chatActions.getSeenChat(message.chat_hdr_id, message.createdAt)
      );
      props.setSeenData(results);

      props.setSeenModal(true);

      props.setLoadSeen(false);
    } catch (error) {
      props.setLoadSeen(false);

      console.log(error);
    }
  };

  const handleTranslate = async (chat_id, text) => {
    // console.log(chat_id);
    let code;

    switch (userData.language) {
      case "JPN":
        code = "ja";
        break;

      case "IDN":
        code = "id";
        break;

      case "VNM":
        code = "vi";
        break;

      case "TAI":
        code = "th";
        break;

      default:
        code = "en";
        break;
    }

    try {
      setLoading(true);
      const output = await dispatch(configActions.translateText(code, text));
      setLoading(false);
      setTranslatedText(output);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box key={uuid()} sx={{ padding: "5px" }}>
      <Grid
        container
        sx={
          props.author_id === userData.user_id
            ? {
                ...styles.chatBubbleWrapper,
                ...styles.userChatBubbleOrientation,
                alignItems: "center",
              }
            : {
                ...styles.chatBubbleWrapper,
                ...styles.recipientChatBubbleOrientation,
                alignItems: "center",
              }
        }
      >
        <Grid item sx={{ paddingRight: "5px" }}>
          {props.author_id !== userData.user_id && (
            <Avatar
              src={props.avatar}
              style={{ width: "30px", height: "30px" }}
            />
          )}
        </Grid>
        <Grid item>
          {del && delChatId === props.chat_msg_id && (
            <Box sx={{ paddingX: "5px" }}>
              <Confirmation
                icon={true}
                buttonName={getLabel(sysLanguage, "4000", "49", "Delete")}
                title={getLabel(sysLanguage, "4000", "49", "Delete")}
                context={getLabel(
                  sysLanguage,
                  "9000",
                  "5",
                  "Are you sure you want to delete this item?"
                )}
                color="error"
                variant="outlined"
                yesFunction={() =>
                  props.deleteMessage(
                    props.chat_hdr_id,
                    props.chat_msg_id,
                    props.fileKey
                  )
                }
              />
            </Box>
          )}
        </Grid>
        <Grid item>
          {details && detailsID === props.chat_msg_id && (
            <Box sx={{ paddingX: "5px", paddingRight: "15px" }}>
              <IconButton
                edge="end"
                aria-label="visibility"
                onClick={() => {
                  getSeenChat(props);
                }}
              >
                <VisibilityIcon fontSize="medium" color="primary" />
              </IconButton>
            </Box>
          )}
        </Grid>

        <Grid
          item
          onMouseOver={() => {
            if (props.author_id === userData.user_id) {
              setDelChatId(props.chat_msg_id);
              setDetailsID(props.chat_msg_id);
              setDel(true);
              setDetails(true);
              setTranslate(true);

              setTimeout(() => {
                setDel(false);
                setDetails(false);
                setTranslate(false);
              }, 4000);
            } else {
              setTransId(props.chat_msg_id);
              setTranslate(true);

              setTimeout(() => {
                setTranslate(false);
              }, 4000);
            }
          }}
          sx={
            props.author_id === userData.user_id
              ? {
                  ...styles.chatBubble,
                  ...styles.userChatBubble,
                }
              : {
                  ...styles.chatBubble,
                  ...styles.recipientChatBubble,
                }
          }
        >
          {!!props.image && (
            <Fragment>
              {props.image.includes("pdf") ? (
                <Box
                  onClick={() => window.open(props.image, "_blank")}
                  sx={{
                    borderRadius: 1,
                    maxHeight: { xs: 220, md: 250 },
                    maxWidth: { xs: 250, md: 350 },
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <PictureAsPdfRoundedIcon fontSize="large" color="warning" />
                    <Typography
                      sx={
                        props.author_id === userData.user_id
                          ? {
                              ...styles.userText,
                            }
                          : {
                              ...styles.recipientText,
                            }
                      }
                    >
                      {"... " + props.fileKey &&
                        props.fileKey
                          .split("/")[1]
                          .substring(props.fileKey.length / 2)}
                    </Typography>
                  </Grid>
                </Box>
              ) : (
                <Box
                  onClick={() => window.open(props.image, "_blank")}
                  component="img"
                  sx={{
                    borderRadius: 1,
                    maxHeight: { xs: 220, md: 250 },
                    maxWidth: { xs: 250, md: 320 },
                  }}
                  alt="Attachment"
                  src={props.image}
                />
              )}
            </Fragment>
          )}

          {props.text &&
            props.text.split("\n").map((text, key) => {
              return (
                <Box>
                  {!!props.reply_text && (
                    <Box>
                      <Typography style={styles.replyText}>
                        {props.reply_text}
                      </Typography>
                      <Divider />
                    </Box>
                  )}
                  <Typography
                    key={key}
                    sx={
                      props.author_id === userData.user_id
                        ? {
                            ...styles.userText,
                          }
                        : {
                            ...styles.recipientText,
                          }
                    }
                  >
                    <LinkItUrl> {text} </LinkItUrl>
                  </Typography>
                </Box>
              );
            })}

          {!loading &&
            translatedText &&
            translatedText.split("\n").map((translatedText, key) => {
              return (
                <Typography
                  key={key}
                  sx={
                    props.author_id === userData.user_id
                      ? {
                          ...styles.userText,
                        }
                      : {
                          ...styles.recipientText,
                          color: "#3d5afe",
                        }
                  }
                >
                  <LinkItUrl> {translatedText} </LinkItUrl>
                </Typography>
              );
            })}
        </Grid>
        <Grid item>
          {translate && transId === props.chat_msg_id && (
            <Box sx={{ paddingLeft: "15px" }}>
              <IconButton
                edge="end"
                aria-label="visibility"
                onClick={() => {
                  handleTranslate(props.chat_msg_id, props.text);
                }}
              >
                <TranslateIcon fontSize="medium" color="primary" />
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid
          container
          item
          sx={
            props.author_id === userData.user_id
              ? {
                  ...styles.userChatBubbleOrientation,
                  ...styles.dateTimeText,
                }
              : {
                  ...styles.recipientChatBubbleOrientation,
                  ...styles.dateTimeText,
                }
          }
        >
          <Fragment>
            <Typography sx={{ fontSize: "0.6rem", paddingRight: "10px" }}>
              {props.chat_type === "G" &&
                userData.user_id !== props.author_id &&
                props.traineename}
            </Typography>
            <Typography sx={{ fontSize: "0.6rem" }}>
              {formatTimeChat(props.createdAt && props.createdAt)}
            </Typography>
          </Fragment>
        </Grid>
      </Grid>
      <div ref={props.messagesEndRef} />
    </Box>
  );
};

export default MessageBox;

const styles = {
  chatBubbleWrapper: {
    marginBottom: "0px",
  },
  chatBubble: {
    padding: "5px 10px",
    maxWidth: "75%",
    width: "-webkit-fit-content",
  },
  userChatBubble: {
    backgroundColor: colors["primary_color"],
    border: "1px solid " + colors["primary_color"],
    borderRadius: "14px 14px 0 14px",
  },
  recipientChatBubble: {
    backgroundColor: colors["accent_color"],
    border: "1px solid " + colors["recepient_chat_bubble_border"],
    borderRadius: "14px 14px 14px 0",
  },
  userChatBubbleOrientation: {
    justifyContent: "flex-end",
    paddingRight: 1,
  },
  recipientChatBubbleOrientation: {
    justifyContent: "flex-start",
    paddingLeft: 1,
  },
  userText: {
    color: colors["contrasted_primary_text"],
    fontSize: ".8rem",
    overflowWrap: "break-word",
  },
  recipientText: {
    color: colors["contrasted_accent_text"],
    fontSize: ".8rem",
    overflowWrap: "break-word",
  },
  replyText: {
    color: colors["reply_color"],
    fontSize: ".8rem",
    overflowWrap: "break-word",
  },
  dateTimeText: {
    color: colors["datetime_text"],
  },
};
