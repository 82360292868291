import React, { Fragment } from "react";

import { useSelector } from "react-redux";

import FlatList from "flatlist-react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

import CloseIcon from "@mui/icons-material/Close";

import formatTime from "../../utils/formatTime";

import getLabel from "../../utils/getLabel";

const SeenChat = (props) => {
  const sysLanguage = useSelector((state) => state.config.labels);

  const companyList = useSelector((state) => state.config.companyList);
  const allNames = useSelector((state) => state.config.allUsers);

  const getAttribute = (user_id) => {
    const result =
      !!allNames && allNames.filter((item) => item.code === user_id);

    const companyName = companyList.filter(
      (item) => item.code === result[0]?.company
    );

    if (result[0]?.role_name === "OFFICER") {
      return result[0]?.position;
    } else {
      return !!companyName[0]?.code
        ? "[" + companyName[0]?.code + "] " + companyName[0]?.descr
        : null;
    }
  };

  const renderItem = (props, idx) => {
    return (
      <List key={idx} sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Box
          sx={{
            width: "100%",
            height: "3.5rem",
            alignContent: "flex-start",
            justifyContent: "center",
          }}
        >
          <ListItem alignItems="center">
            <ListItemAvatar>
              <Avatar alt={props.avatar} src={props.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#01579b",
                        lineHeight: "13px",
                        fontSize: 13,
                        fontWeight: "400",
                      }}
                    >
                      {props.alternate_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontWeight: "300",

                        color: "#7b1fa2",
                      }}
                    >
                      {getAttribute(props.user_id)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontWeight: "300",

                        color: "#7b1fa2",
                      }}
                    >
                      {formatTime(props.created_dttm)}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </ListItem>
        </Box>
        <Divider variant="inset" component="li" />
      </List>
    );
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#01579b",
              fontSize: 16,
              fontWeight: "400",
            }}
          >
            {getLabel(sysLanguage, "6000", "42", "Seen")}
          </Typography>
        </Box>
        <IconButton
          aria-label="close-modal"
          onClick={() => props.setSeenModal(false)}
        >
          <CloseIcon color="primary" fontSize="medium" />
        </IconButton>
      </Box>
      <Box sx={{ width: "100%" }}>
        {props.loadSeen ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              overflow: "scroll",
              height: window.innerHeight - window.innerHeight * 0.55,
            }}
          >
            <FlatList
              list={props.seenData}
              renderItem={renderItem}
              renderWhenEmpty={() => <div>List is empty!</div>}
              sort={{
                by: "created_dttm",
                descending: true,
              }}
              hasMoreItems={true}
            />
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default SeenChat;
