import forceLogOut from "../../utils/forceLogOut";

import { DASHBANNER } from "../reducers/dashboard";

import getEnvironment from "../../utils/environments";
const url = getEnvironment().apiKey;

//get Banner lists
export const getAllBanner = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getAllBanner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get Banner List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: DASHBANNER,
      dashBannerList: resData,
    });
  };
};

//update Reaction
export const updateReaction = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/updateReaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot update reaction";
      forceLogOut(response.status, message);
    }
  };
};
