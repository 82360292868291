export const SEARCHPROFILE = "SEARCHPROFILE";
export const SEARCH_ONE_PROFILE = "SEARCH_ONE_PROFILE";
export const JOB_PROFILE = "JOB_PROFILE";
export const VISA_PROFILE = "VISA_PROFILE";
export const OTHER_PROFILE = "OTHER_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

const initialState = {
  searchProfile: "",
  searchOneProfile: "",
  jobProfile: "",
  visaProfile: "",
  otherProfile: "",
};

const auth = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SEARCHPROFILE:
      return {
        ...state,
        searchProfile: action.profileResult,
      };
    case SEARCH_ONE_PROFILE:
      return {
        ...state,
        searchOneProfile: action.profileResult[0],
      };
    case JOB_PROFILE:
      return {
        ...state,
        jobProfile: action.jobResult,
      };
    case VISA_PROFILE:
      return {
        ...state,
        visaProfile: action.visaResult,
      };
    case OTHER_PROFILE:
      return {
        ...state,
        otherProfile: action.otherResult,
      };
    case CLEAR_PROFILE:
      return {
        // ...state,
        searchProfile: "",
        searchOneProfile: "",
        jobProfile: "",
        visaProfile: "",
        otherProfile: "",
      };

    default:
      return state;
  }
};

export default auth;
