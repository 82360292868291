import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import ticket from "./ticket";
import user from "./user";
import config from "./config";
import dashboard from "./dashboard";

export default combineReducers({
  auth,
  profile,
  ticket,
  user,
  config,
  dashboard,
});
