import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import formatDate from "../../utils/formatDate";

import { selectListFilter } from "../../utils/selectOptions";

import getLabel from "../../utils/getLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "25%",
  maxWidth: 700,
  minWidth: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const PeekProfile = (props) => {
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);
  const visaList = useSelector((state) => state.config.visaList);
  const companyList = useSelector((state) => state.config.companyList);
  const occupationList = useSelector((state) => state.config.occupationList);

  const jumpToProfile = async (user_id) => {
    const ecryptedTicketId = btoa(user_id?.toString().padStart(7, "0"));
    navigate(`/MainProfile/${ecryptedTicketId}`, {});
  };

  const handleClose = () => props.setOpen(false);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!props.loadingProfile ? (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography id="modal-modal-title" variant="h6">
                {getLabel(sysLanguage, "4000", "18", "  User Information")}
              </Typography>
              <Button
                sx={{ mb: 1, height: "30px" }}
                color="warning"
                variant="contained"
                onClick={() => handleClose()}
              >
                {getLabel(sysLanguage, "2000", "32", "Close")}
              </Button>
            </Box>

            <Divider />
            <Grid
              container
              rowSpacing={1}
              sx={{
                mt: 0.5,
              }}
            >
              <Grid item xs={8}>
                <Typography variant="body2">
                  {getLabel(sysLanguage, "4000", "13", "Name")}:{" "}
                  {props.profileOfficer ? (
                    <Link
                      onClick={() => jumpToProfile(props.profileData.user_id)}
                    >
                      {props.profileData.fullName}
                    </Link>
                  ) : (
                    props.profileData.fullName
                  )}
                </Typography>
                <Typography variant="body2">
                  {getLabel(sysLanguage, "4000", "14", "Company")}:{" "}
                  {selectListFilter(
                    companyList,
                    props.profileData.company,
                    "code",
                    "descr",
                    "Y",
                    sysLanguage
                  )}
                </Typography>
                <Typography variant="body2">
                  {getLabel(sysLanguage, "4000", "38", "Occupation")}:{" "}
                  {selectListFilter(
                    occupationList,
                    props.profileData.occupation,
                    "code",
                    "descr",
                    "Y",
                    sysLanguage
                  )}
                </Typography>
                <Typography variant="body2">
                  {getLabel(sysLanguage, "4000", "17", "Passport Expiration")}:{" "}
                  {formatDate(props.profileData.passport_expiry_dt)}
                </Typography>
                <Typography variant="body2">
                  {getLabel(sysLanguage, "4000", "15", "Visa")}:{" "}
                  {selectListFilter(
                    visaList,
                    props.profileData.visa_code,
                    "code",
                    "descr",
                    "Y",
                    sysLanguage
                  )}
                </Typography>
                <Typography variant="body2">
                  {getLabel(sysLanguage, "4000", "93", "Visa Expiration")}:{" "}
                  {formatDate(props.profileData.visa_end_dt)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Avatar
                  sx={{ height: "130px", width: "130px" }}
                  alt={props.profileData.avatar}
                  src={props.profileData.avatar}
                />
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Modal>
  );
};

export default PeekProfile;
