export const SEARCHUSER = "SEARCHUSER";
export const SEARCH_ONE_USER = "SEARCH_ONE_USER";
export const UPDATE_ONE_USER = "UPDATE_ONE_USER";
export const SEARCH_USERROLES = "SEARCH_USERROLES";
export const DELETEUSER = "DELETEUSER";
export const CLEAR_USER = "CLEAR_USER";

const initialState = {
  searchUser: "",
  searchOneUser: "",
  userRoles: "",
};

const auth = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SEARCHUSER:
      return {
        ...state,
        searchUser: action.userResult,
      };
    case SEARCH_ONE_USER:
      return {
        ...state,
        searchOneUser: action.userResult[0],
      };
    case UPDATE_ONE_USER:
      return {
        ...state,
        searchOneUser: action.userData,
      };
    case SEARCH_USERROLES:
      return {
        ...state,
        userRoles: action.accessRoles,
      };
    case DELETEUSER:
      const updatedResult = state.searchUser.filter(
        (item) => item.user_id !== action.userId
      );
      return {
        ...state,
        searchUser: updatedResult,
      };
    case CLEAR_USER:
      return {
        // ...state,
        searchUser: "",
        searchOneUser: "",
        userRoles: "",
      };

    default:
      return state;
  }
};

export default auth;
