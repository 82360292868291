import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";

import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { LinkItUrl } from "react-linkify-it";

import { Formik, Form } from "formik";

import formatDate from "../../utils/formatDate";
import { selectListFilter, otherInfoType } from "../../utils/selectOptions";

import Confirmation from "../components/Confirmation";

import * as profileActions from "../../store/actions/profile";

import getLabel from "../../utils/getLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OtherInfo = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const trainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const oneProfile = useSelector((state) => state.profile.searchOneProfile);

  const otherProfile = useSelector((state) => state.profile.otherProfile);

  const [addMode, setAddMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const [itemType, setItemType] = useState({});

  const [dateOne, setDateOne] = useState(null);
  const [dateTwo, setDateTwo] = useState(null);

  const [open, setOpen] = useState(false);

  const [rowDetail, setRowDetail] = useState({
    business_unit: "",
    user_id: null,
    row_id: null,
    item_type: "",
    item_one: "",
    item_two: "",
    date_one: null,
    date_two: null,
    comment: "",
  });

  const handleEdit = (rowData) => {
    setAddMode(false);

    setRowDetail({
      business_unit: rowData.business_unit,
      user_id: rowData.user_id,
      row_id: rowData.row_id,
      item_type: rowData.item_type,
      item_one: rowData.item_one,
      item_two: rowData.item_two,
      comment: rowData.comment,
    });

    setDateOne(rowData.date_one);
    setDateTwo(rowData.date_two);

    setOpen(true);
  };

  const handleAdd = (rowData) => {
    setAddMode(true);

    setRowDetail({
      business_unit: oneProfile.business_unit,
      user_id: oneProfile.user_id,
      row_id: "New",
      item_type: "",
      item_one: "",
      item_two: "",
      comment: "",
    });

    setDateOne(null);
    setDateTwo(null);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = async (formData) => {
    try {
      setLoading(true);

      if (addMode) {
        await dispatch(profileActions.addUserOther(formData));
      } else {
        await dispatch(profileActions.editUserOther(formData));
      }
      setLoading(false);

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (formData) => {
    setLoading(true);
    try {
      await dispatch(profileActions.delUserOther(formData));
      setOpen(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const rows = [];
  otherProfile &&
    otherProfile.map((item) => {
      rows.push(item);
    });

  useEffect(() => {
    let selRow = [];

    selRow = otherInfoType(sysLanguage).filter(
      (item) => item.value === rowDetail.item_type
    );
    setItemType({
      value: rowDetail.item_type,
      label: selRow?.length > 0 ? selRow[0].label : "",
    });
  }, [rowDetail]);

  const handleReceivedDt = (newValue) => {
    setDateOne(newValue);
  };

  const handleExpDt = (newValue) => {
    setDateTwo(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Stack spacing={2}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Other Information
                </Typography>
                {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography> */}
                <Formik
                  initialValues={{
                    business_unit: rowDetail.business_unit,
                    user_id: rowDetail.user_id,
                    row_id: rowDetail.row_id,
                    item_one: rowDetail.item_one,
                    item_two: rowDetail.item_two,
                    comment: rowDetail.comment,
                  }}
                  // validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    const addedData = {
                      ...values,
                      item_type: itemType.value,
                      date_one: dateOne
                        ? new Date(dateOne).toLocaleDateString()
                        : null,
                      date_two: dateTwo
                        ? new Date(dateTwo).toLocaleDateString()
                        : null,
                    };
                    handleSave(addedData);
                  }}
                >
                  {({
                    handleChange,
                    values,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <Stack spacing={2}>
                        <TextField
                          disabled
                          id="row"
                          name="row"
                          label={getLabel(sysLanguage, "4000", "48", "Row Id")}
                          value={values.row_id}
                          size="small"
                          sx={{ width: 100 }}
                        />

                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Autocomplete
                            disablePortal
                            id="itemType"
                            name="itemType"
                            value={itemType}
                            getOptionLabel={(option) => option.label || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            onChange={(e, data) => setItemType(data)}
                            options={otherInfoType(sysLanguage)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "58",
                                  "Type"
                                )}
                              />
                            )}
                            size="small"
                            sx={{ width: 250 }}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <TextField
                            required
                            id="item_one"
                            name="item_one"
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "53",
                              "Description One"
                            )}
                            value={values.item_one}
                            onChange={handleChange("item_one")}
                            size="small"
                            sx={{ width: 400 }}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <TextField
                            id="item_two"
                            name="item_two"
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "54",
                              "Description Two"
                            )}
                            value={values.item_two}
                            onChange={handleChange("item_two")}
                            size="small"
                            sx={{ width: 400 }}
                          />
                        </Grid>
                        {itemType?.value !== "TY6" && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "55",
                                  "Date One"
                                )}
                                inputFormat="yyyy/MM/dd"
                                value={dateOne}
                                onChange={handleReceivedDt}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    sx={{ width: 200 }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                        {(itemType?.value === "TY2" ||
                          itemType?.value === "TY3") && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label={getLabel(
                                  sysLanguage,
                                  "4000",
                                  "56",
                                  "Date Two"
                                )}
                                inputFormat="yyyy/MM/dd"
                                value={dateTwo}
                                onChange={handleExpDt}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    sx={{ width: 200 }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <TextField
                            rows={2}
                            multiline
                            id="comment"
                            name="comment"
                            label={getLabel(
                              sysLanguage,
                              "4000",
                              "61",
                              "Comments"
                            )}
                            value={values.comment}
                            onChange={handleChange("comment")}
                            size="small"
                            sx={{ width: 400 }}
                          />
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            alignContent: "flex-end",
                            mt: 3,
                          }}
                        >
                          <LoadingButton
                            disabled={!props.canEdit}
                            type="submit"
                            loading={loading}
                            variant="contained"
                            color="primary"
                            sx={{ m: 1 }}
                          >
                            {getLabel(sysLanguage, "9000", "6", "Save")}
                          </LoadingButton>
                          <Button
                            sx={{ m: 1, width: 200 }}
                            color="warning"
                            variant="contained"
                            onClick={() => setOpen(false)}
                          >
                            {getLabel(sysLanguage, "9000", "7", "Cancel")}
                          </Button>
                          {!addMode && (
                            <>
                              <Grid container justifyContent="flex-end">
                                <Confirmation
                                  disabled={!props.canEdit}
                                  buttonName={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "49",
                                    "Delete"
                                  )}
                                  title={getLabel(
                                    sysLanguage,
                                    "4000",
                                    "49",
                                    "Delete"
                                  )}
                                  context={getLabel(
                                    sysLanguage,
                                    "9000",
                                    "5",
                                    "Are you sure you want to delete this item?"
                                  )}
                                  color="error"
                                  variant="outlined"
                                  yesFunction={() => handleDelete(values)}
                                />
                              </Grid>
                            </>
                          )}
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </Box>
          </Fade>
        </Modal>
        <Grid container justifyContent="flex-start">
          <Button
            disabled={!props.canEdit}
            color="primary"
            variant="contained"
            onClick={handleAdd}
          >
            {getLabel(sysLanguage, "4000", "51", "Add Other Information")}
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {getLabel(sysLanguage, "4000", "52", "Item Type")}
                </TableCell>
                <TableCell align="left">
                  {getLabel(sysLanguage, "4000", "53", "Description One")}
                </TableCell>
                <TableCell align="left">
                  {getLabel(sysLanguage, "4000", "54", "Description Two")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "4000", "55", "Date One")}
                </TableCell>
                <TableCell align="right">
                  {getLabel(sysLanguage, "4000", "56", "Date Two")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.row_id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {selectListFilter(
                      otherInfoType(sysLanguage),
                      row.item_type,
                      "value",
                      "label",
                      "Y",
                      ""
                    )}
                  </TableCell>

                  <TableCell align="left">
                    <LinkItUrl>{row.item_one}</LinkItUrl>
                  </TableCell>
                  <TableCell align="left">
                    <LinkItUrl>{row.item_two}</LinkItUrl>
                  </TableCell>
                  <TableCell align="right">
                    {row.item_type === "TY6" ? null : formatDate(row.date_one)}
                  </TableCell>
                  <TableCell align="right">
                    {row.item_type === "TY2" || row.item_type === "TY3"
                      ? formatDate(row.date_two)
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" sx={{ p: 0.5 }}>
                      <ModeEditIcon onClick={() => handleEdit(row)} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() =>
              trainee
                ? navigate(`/`, { replace: true })
                : navigate(`/traineeProfile`, { replace: true })
            }
          >
            {getLabel(sysLanguage, "9000", "9", "Back")}
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

export default OtherInfo;
