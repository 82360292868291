import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import PersonalInfo from "./PersonalInfo";
import WorkInfo from "./WorkInfo";
import VisaInfo from "./VisaInfo";
import OtherInfo from "./OtherInfo";
import FileAttachment from "../components/FileAttachment";

import Navigator from "../components/Navigator";
import Header from "../components/Header";
import Footer from "../components/Footer";

import NoAccess from "../components/NoAccess";

import * as configActions from "../../store/actions/config";
import * as profileActions from "../../store/actions/profile";

import getLabel from "../../utils/getLabel";

import { selectListFilter } from "../../utils/selectOptions";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainProfile() {
  const dispatch = useDispatch();

  let { profile_id } = useParams();

  const sysLanguage = useSelector((state) => state.config.labels);
  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);

  const profileWrite =
    roleArray && roleArray?.some((item) => item.role_name === "PROFILE_WRITE");

  const profileRead =
    roleArray && roleArray?.some((item) => item.role_name === "PROFILE_READ");

  const isTrainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const oneProfile = useSelector((state) => state.profile.searchOneProfile);

  const companyList = useSelector((state) => state.config.companyList);
  const visaList = useSelector((state) => state.config.visaList);
  const occupationList = useSelector((state) => state.config.occupationList);
  const countryList = useSelector((state) => state.config.countryList);

  const [value, setValue] = useState(0);

  const [canEdit, setCanEdit] = useState(false);

  const [loading, setLoading] = useState(true);

  const [noAccess, setNoAccess] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [formData, setFormData] = useState({
    user_id: "",
    email_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    contact_no: "",
    contact_no_two: "",
    home_address_one: "",
    home_address_two: "",
    emer_name: "",
    emer_relationship: "",
    emer_address: "",
    emer_tel_no: "",
    emer_email: "",
    passport: "",
    gender: {},
    country: {},
    birthDate: null,
    passExpiryDt: null,
  });

  const loadProfile = async () => {
    const decryptedTicketId = isTrainee
      ? userData.user_id
      : atob(profile_id?.toString().padStart(7, "0"));
    await dispatch(profileActions.getOneProfile(decryptedTicketId));
    setLoading(false);
  };

  const loadData = async () => {
    const selRowCountry = countryList?.filter(
      (item) => item.code === oneProfile.country_origin
    );
    const selRowGender = genderOption.filter(
      (item) => item.value === oneProfile.gender
    );

    setFormData({
      ...formData,
      user_id: !!oneProfile ? oneProfile.user_id : "",
      email_id: !!oneProfile ? oneProfile.email_id : "",
      first_name: !!oneProfile ? oneProfile.first_name : "",
      middle_name: !!oneProfile ? oneProfile.middle_name : "",
      last_name: !!oneProfile ? oneProfile.last_name : "",
      contact_no: !!oneProfile ? oneProfile.contact_no : "",
      contact_no_two: !!oneProfile ? oneProfile.contact_no_two : "",
      home_address_one: !!oneProfile ? oneProfile.home_address_one : "",
      home_address_two: !!oneProfile ? oneProfile.home_address_two : "",
      emer_name: !!oneProfile ? oneProfile.emer_name : "",
      emer_relationship: !!oneProfile ? oneProfile.emer_relationship : "",
      emer_address: !!oneProfile ? oneProfile.emer_address : "",
      emer_tel_no: !!oneProfile ? oneProfile.emer_tel_no : "",
      emer_email: !!oneProfile ? oneProfile.emer_email : "",
      passport: !!oneProfile ? oneProfile.passport : "",
      country: {
        value: !!oneProfile ? oneProfile.country_origin : "",
        label:
          selRowCountry?.length > 0
            ? selectListFilter(
                countryList,
                selRowCountry[0].code,
                "code",
                "descr",
                "Y",
                sysLanguage
              )
            : "",
      },
      gender: {
        value: oneProfile.gender,
        label: selRowGender.length > 0 ? selRowGender[0].label : "",
      },
      birthDate: !!oneProfile ? oneProfile.birth_date : null,
      passExpiryDt: !!oneProfile ? oneProfile.passport_expiry_dt : null,
    });
  };

  const loadConfig = async () => {
    if (companyList.length < 1) await dispatch(configActions.getCompany());
    if (visaList.length < 1) await dispatch(configActions.getVisaList());
    if (occupationList.length < 1)
      await dispatch(configActions.getOccupation());
  };

  const genderOption = [
    {
      label: "男性(Male)",
      value: "M",
    },
    {
      label: "女性(Female)",
      value: "F",
    },
  ];

  const isReadOnly = () => {
    const editYes =
      roleArray &&
      roleArray?.some((item) => item.role_name === "PROFILE_WRITE");
    if (editYes || isTrainee) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  };

  useEffect(() => {
    // console.log("Profile: ", profileWrite, profileRead);

    if (profileWrite || profileRead) {
    } else {
      if (!isTrainee) setNoAccess(true);
    }
  }, []);

  useEffect(() => {
    loadProfile();
    loadData();

    loadConfig();

    isReadOnly();
  }, [loading, oneProfile?.user_id]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {/* {isSmUp ? null : ( */}
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          {/* )} */}

          {/* <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          /> */}
        </Box>

        <Box sx={{ width: "100%" }}>
          {!loading ? (
            <div>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Header onDrawerToggle={handleDrawerToggle} />
                {noAccess ? (
                  <NoAccess />
                ) : (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    //   aria-label="basic tabs example"
                  >
                    <Tab
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "18",
                        "Personal Information"
                      )}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "34",
                        "Work Information"
                      )}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "42",
                        "Visa Information"
                      )}
                      {...a11yProps(2)}
                    />
                    <Tab
                      label={getLabel(
                        sysLanguage,
                        "4000",
                        "50",
                        "Other Information"
                      )}
                      {...a11yProps(3)}
                    />
                    <Tab
                      label={getLabel(sysLanguage, "4000", "62", "Files")}
                      {...a11yProps(4)}
                    />
                  </Tabs>
                )}
              </Box>

              {!noAccess && (
                <>
                  <TabPanel value={value} index={0}>
                    <Box
                      sx={{
                        "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                        "& .MuiAutocomplete-root": { mr: 0.7 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <PersonalInfo
                        formData={formData}
                        setFormData={setFormData}
                        genderOption={genderOption}
                        countryOption={countryList}
                        canEdit={canEdit}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Box
                      sx={{
                        "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                        "& .MuiAutocomplete-root": { mr: 0.7 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <WorkInfo
                        companyList={companyList}
                        occupationList={occupationList}
                        canEdit={canEdit}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Box
                      sx={{
                        "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                        "& .MuiAutocomplete-root": { mr: 0.7 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <VisaInfo visaList={visaList} canEdit={canEdit} />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Box
                      sx={{
                        "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                        "& .MuiAutocomplete-root": { mr: 0.7 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <OtherInfo canEdit={canEdit} />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Box
                      sx={{
                        "& .MuiTextField-root": { ml: 0.7, mt: 1 },
                        "& .MuiAutocomplete-root": { mr: 0.7 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FileAttachment
                        module="profile"
                        isLocked={false}
                        canEdit={profileWrite}
                        source_id={oneProfile.user_id}
                        company={oneProfile.user_id}
                        back={"/traineeProfile"}
                      />
                    </Box>
                  </TabPanel>
                </>
              )}
              <Footer />
            </div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
