import React from "react";
import { Routes, Route } from "react-router-dom";

import LoginScreen from "../screens/auth/LoginScreen";
import PrivacyandTerms from "../screens/others/PrivacyandTerms";
import SessionExpired from "./SessionExpired";

const AuthNavigator = (props) => {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/privacypolicy" element={<PrivacyandTerms />} />
      <Route path="*" element={<SessionExpired />} />
    </Routes>
  );
};

export default AuthNavigator;
