import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import GridOnIcon from "@mui/icons-material/GridOn";

import { CSVLink } from "react-csv";

import { Formik, Form } from "formik";

import TableOne from "../components/TableOne";

import formatDate from "../../utils/formatDate";
import { getOptions, selectListFilter } from "../../utils/selectOptions";

import * as profileActions from "../../store/actions/profile";
import getLabel from "../../utils/getLabel";

const noFunction = (value) => {
  return value;
};

const SearchCompany = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);
  const companyList = useSelector((state) => state.config.companyList);

  const newCompany = [];

  !!companyList &&
    companyList.map((item) => {
      const company = selectListFilter(
        companyList,
        item.code,
        "code",
        "descr",
        "Y",
        sysLanguage
      );

      newCompany.push({ ...item, descr: company });
    });

  const [loading, setLoading] = useState(false);
  const [searchCompany, setSearchCompany] = useState(newCompany);

  const headCells = [
    {
      id: "code",
      numeric: false,
      disablePadding: false,
      padding: "normal",
      function: noFunction,
      link: true,
      noPaddedZero: true,
      label: getLabel(sysLanguage, "4000", "48", "Row ID"),
    },
    {
      id: "descr",
      numeric: false,
      disablePadding: false,
      padding: "normal",
      function: noFunction,
      label: getLabel(sysLanguage, "4000", "14", "Company"),
    },
  ];

  const handleSearch = async (data) => {
    setLoading(true);
    try {
      const newData = newCompany.filter((item) => {
        const itemData = `${!!item.descr && item.descr.toUpperCase()} ${
          !!item.code && item.code.toUpperCase()
        }
         `;
        return itemData.indexOf(data.toUpperCase()) > -1;
      });
      setSearchCompany(newData);
    } catch (error) {
      setLoading(false);
    }
  };

  const jumpToNavigate = async (companyId) => {
    const ecryptedTicketId = btoa(companyId);
    navigate(`/MainCompany/${ecryptedTicketId}`, {});
  };

  let csvHeader = [
    { label: "Code", key: "code" },
    { label: "Company", key: "descr" },
  ];

  let csvData = [];

  searchCompany &&
    searchCompany.map((item) => {
      const forCSV = {
        code: item.code,
        descr: item.descr,
      };

      csvData.push(forCSV);
    });

  return (
    <Box sx={{ p: 1 }}>
      <Formik
        initialValues={{
          company: "",
        }}
        onSubmit={(values) => {
          handleSearch(values.company);
        }}
      >
        {({ handleChange, values, setFieldValue, resetForm }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Stack spacing={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextField
                      id="batchNo"
                      name="batchNo"
                      label={getLabel(sysLanguage, "4000", "14", "Company")}
                      value={values.company}
                      onChange={handleChange("company")}
                      size="small"
                      sx={{ width: 350 }}
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                sx={{ m: 1 }}
                color="primary"
                variant="contained"
                type="submit"
              >
                {getLabel(sysLanguage, "9000", "1", "Search")}
              </Button>
              <Button
                sx={{ m: 1 }}
                color="warning"
                variant="contained"
                onClick={() => {
                  resetForm();
                }}
              >
                {getLabel(sysLanguage, "9000", "2", "Clear")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {companyList.length > 0 && (
        <Box>
          <TableOne
            headCells={headCells}
            rows={searchCompany}
            jumpToNavigate={jumpToNavigate}
          />
          <Grid container justifyContent="flex-end" style={{ paddingRight: 5 }}>
            <CSVLink
              filename="searchcopmany.csv"
              target="_blank"
              data={csvData}
              headers={csvHeader}
            >
              <Button size="large" variant="outlined" endIcon={<GridOnIcon />}>
                {getLabel(sysLanguage, "9000", "54", "Export")}
              </Button>
            </CSVLink>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SearchCompany;
