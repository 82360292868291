import forceLogOut from "../../utils/forceLogOut";

import {
  GET_AWS,
  GET_ATTACHMENTS,
  CLEAR_ATTACHMENTS,
  GET_LABEL,
  GET_ROLES,
  GET_COMPANY,
  GET_SENDINGORG,
  GET_COUNTRY,
  GET_ISSUELIST,
  GET_TRAINEE,
  GET_OFFICER,
  GET_ALL_USERS,
  GET_VISALIST,
  GET_OCCUPATION,
  GET_BANNER,
  GET_ONEBANNER,
  OPEN_ONE_BANNER,
  DELETEBANNER,
  GETCOMMENT,
} from "../reducers/config";

import getEnvironment from "../../utils/environments";
import { attachmentType } from "../../utils/selectOptions";
const url = getEnvironment().apiKey;

// Get AWS Config
export const getAWS = () => {
  return async (dispatch, getState) => {
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(`${url}/config/getAWSCred`, {
        method: "POST",
        headers: {
          "x-auth-token": token,
        },
      });

      if (!response.ok) {
        let message = "Cannot get AWS...";
        forceLogOut(response.status, message);
      }

      const resData = await response.json();
      dispatch({
        type: GET_AWS,
        awsCred: resData,
      });
      // return resData;
    } catch (err) {
      // send to custom analytics server
      throw err;
    }
  };
};

//insert access log details
export const insertAcessLog = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/insertAcessLog`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Insert Access Logs";
      forceLogOut(response.status, message);
    }

    // const resData = await response.json();
    // return resData;
  };
};

//Retrieve attachment
export const getAttachment = (module, source_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getAttachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({ source_id: source_id, module: module }),
    });
    if (!response.ok) {
      let message = "Get attachment error!";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_ATTACHMENTS,
      attachments: resData,
    });
  };
};

//Clear attachment
export const clearAttachment = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_ATTACHMENTS,
    });
  };
};

//Add/Delete New attachment
export const addDeleteAttachment = (data) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/addDeleteAttachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      let message = "Add/Delete of attachment error!";
      forceLogOut(response.status, message);
    }

    await dispatch(getAttachment(data.module, data.source_id));
  };
};

//translate text
export const translateText = (code, text) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/translateText`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        targetLang: code,
        text: text,
      }),
    });

    if (!response.ok) {
      let message = "Cannot Translate Text";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//get Country lists
export const getCountry = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/country`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Country List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_COUNTRY,
      countryList: resData,
    });
  };
};

//get SendingOrg lists
export const getSendingOrg = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/sendingorg`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get SendingOrg List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    return resData;
  };
};

//get Company lists
export const getCompany = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/company`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Company List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_COMPANY,
      companyList: resData,
    });
  };
};

//get Issue lists
export const getIssue = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/issue`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Issue List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_ISSUELIST,
      issueList: resData,
    });
  };
};

//get Visa lists
export const getVisaList = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/visaList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Visa List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_VISALIST,
      visaList: resData,
    });
  };
};

//get Occupation lists
export const getOccupation = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Occupation List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_OCCUPATION,
      occupationList: resData,
    });
  };
};

//get Banner lists
export const getAllBanner = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getAllBanner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get Banner List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    dispatch({
      type: GET_BANNER,
      bannerList: resData,
    });
  };
};

//get Banner lists
export const getOneBanner = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getAllBanner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get One Banner List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    if (formData.mode !== "add")
      await dispatch(getAttachment("announcements", formData.row_id));

    dispatch({
      type: GET_ONEBANNER,
      bannerListOne: resData,
    });
  };
};

//get One banner
export const openOneBanner = (formData) => {
  return async (dispatch) => {
    if (!!formData.row_id) {
      if (formData.mode !== "add")
        await dispatch(getAttachment("announcements", formData.row_id));
    }

    dispatch({
      type: OPEN_ONE_BANNER,
      bannerId: formData.row_id,
    });
  };
};

//add or edit Banner/Announcement
export const addEditBanner = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/addEditBanner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Add/Edit Banner List";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    switch (formData.mode) {
      case "add":
      case "edit":
        dispatch(openOneBanner(resData[0].row_id));

        return resData[0].row_id;

      case "delete":
        dispatch({
          type: DELETEBANNER,
          row_id: formData.row_id,
        });

        break;
      default:
        break;
    }
  };
};

//get Roles
export const getRoles = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getallroles`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Roles";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_ROLES,
      roleList: resData,
    });
  };
};

//get All Trainee
export const getTrainee = (roleName) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getTrainee`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Trainee Names";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_TRAINEE,
      traineeName: resData,
    });
  };
};

//get All Officer
export const getOfficer = (roleName) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getOfficer`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Officer Names";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_OFFICER,
      officerName: resData,
    });
  };
};

//get All User
export const getAllUsers = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getAllUsers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get All Users";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    dispatch({
      type: GET_ALL_USERS,
      allUsers: resData,
    });
  };
};

//get Comment
export const getComment = (source_id, module) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getComment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({ source_id: source_id, module: module }),
    });

    if (!response.ok) {
      let message = "Cannot Get One Comment";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GETCOMMENT,
      oneComment: resData,
    });
  };
};

//add Comment
export const addComment = (values) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/addComment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Add Comment";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get comment
    await dispatch(getComment(resData[0].source_id, resData[0].module));
  };
};

//delete Comment
export const deleteComment = (formData) => {
  return async (dispatch) => {
    // const token = useSelector((state) => state.auth.token);
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/deleteComment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Comment";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get comment
    await dispatch(getComment(formData.source_id, formData.module));
  };
};

//get Reaction
export const getSeenDetails = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/seenDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Get Seen details";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//get Alert
export const getAlerts = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getAlert`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (!response.ok) {
      let message = "Cannot Get Alerts";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//hide Alert
export const hideAlert = (alert_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/hideAlert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({ alert_id: alert_id }),
    });

    if (!response.ok) {
      let message = "Cannot Hide Alerts";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    return resData;
  };
};

//get Label
export const getLabels = (language) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/getLabel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({ language: language }),
    });
    if (!response.ok) {
      let message = "Cannot Get Labels";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: GET_LABEL,
      labels: resData,
    });
  };
};

//update FileName
export const updateFileName = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/updateFileName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        row_id: formData.row_id,
        originalname: formData.originalname,
        attachmenttype: formData.attachmenttype.value,
      }),
    });
    if (!response.ok) {
      let message = "CAnnot update file";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    return resData;
  };
};

//update Label
export const updateLabel = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/config/updateLabel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        lang_code: formData.lang_code,
        msgset: formData.msgset,
        msgnbr: formData.msgnbr,
        label: formData.label,
      }),
    });
    if (!response.ok) {
      let message = "Cannot update Label";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    //return resData;
  };
};
