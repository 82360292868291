import forceLogOut from "../../utils/forceLogOut";

import {
  SEARCHPROFILE,
  SEARCH_ONE_PROFILE,
  JOB_PROFILE,
  VISA_PROFILE,
  OTHER_PROFILE,
} from "../reducers/profile";

import { getAttachment } from "./config";

import getEnvironment from "../../utils/environments";
const url = getEnvironment().apiKey;

//search profileDashboard
export const profileDashboard = (values) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/getAllProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Search Profile";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    return resData;
  };
};

//search Profile
export const searchProfile = (values) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/getAllProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      let message = "Cannot Search Profile";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: SEARCHPROFILE,
      profileResult: resData,
    });
  };
};

//search One Profile
export const getOneProfile = (user_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/getProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    });

    if (!response.ok) {
      let message = "Cannot Search Profile";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    dispatch({
      type: SEARCH_ONE_PROFILE,
      profileResult: resData,
    });

    //get Profile Details
    await dispatch(getJobDetails(user_id));
    await dispatch(getUserVisa(user_id));
    await dispatch(getUserOther(user_id));
    await dispatch(getAttachment("profile", user_id));
  };
};

//Save One Profile
export const saveOneProfile = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/updateProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Save Profile";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();
    dispatch({
      type: SEARCH_ONE_PROFILE,
      profileResult: resData,
    });
  };
};

//get Job details
export const getJobDetails = (user_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    });

    if (!response.ok) {
      let message = "Cannot Retrieve Job";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: JOB_PROFILE,
      jobResult: resData,
    });
  };
};

//Add Job detail
export const addJob = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/addJob`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Add Job";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Job Detail
    await dispatch(getJobDetails(formData.user_id));
  };
};

//update Job detail
export const updateJob = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/editJob`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Update Job";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Job Detail
    await dispatch(getJobDetails(formData.user_id));
  };
};

//delete Job detail
export const deleteJob = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/deleteJob`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Job";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Job Detail
    await dispatch(getJobDetails(formData.user_id));
  };
};

//get Visa details
export const getUserVisa = (user_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/userVisa`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    });

    if (!response.ok) {
      let message = "Cannot Retrieve User Visa";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: VISA_PROFILE,
      visaResult: resData,
    });
  };
};

//Add Visa detail
export const addUserVisa = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/addUserVisa`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Add Visa";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Visa Detail
    await dispatch(getUserVisa(formData.user_id));
  };
};

//update Visa detail
export const editUserVisa = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/editUserVisa`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Update Visa";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Visa Detail
    await dispatch(getUserVisa(formData.user_id));
  };
};

//delete Visa detail
export const delUserVisa = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/delUserVisa`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Visa";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Job Detail
    // await dispatch(getUserVisa(formData.user_id));
  };
};

//get Other details
export const getUserOther = (user_id) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/userOther`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    });

    if (!response.ok) {
      let message = "Cannot Retrieve User Other Info";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    dispatch({
      type: OTHER_PROFILE,
      otherResult: resData,
    });
  };
};

//Add Other detail
export const addUserOther = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/addUserOther`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Add Other Info";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Other Detail
    await dispatch(getUserOther(formData.user_id));
  };
};

//update Other Info detail
export const editUserOther = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/editUserOther`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Update Other Info";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Other Detail
    await dispatch(getUserOther(formData.user_id));
  };
};

//delete Other detail
export const delUserOther = (formData) => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");

    const response = await fetch(`${url}/profile/delUserOther`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      let message = "Cannot Delete Other Info";
      forceLogOut(response.status, message);
    }

    const resData = await response.json();

    //get Other Info Detail
    await dispatch(getUserOther(formData.user_id));
  };
};
