import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const NoAccess = () => {
  return (
    <Box sx={{ m: 5, width: "100%" }}>
      <Typography variant="h2" color="inherit" noWrap>
        Access Denied.
      </Typography>
      <Typography variant="h5" color="inherit" noWrap>
        Please contact your Administrator.
      </Typography>
    </Box>
  );
};

export default NoAccess;
