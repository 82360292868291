import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Button from "@mui/material//Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";

import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Formik, Form } from "formik";

import { getOptions } from "../../utils/selectOptions";

import * as ticketActions from "../../store/actions/ticket";
import getLabel from "../../utils/getLabel";

const AddTicket = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sysLanguage = useSelector((state) => state.config.labels);
  const userData = useSelector((state) => state.auth.userData[0]);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const trainee =
    roleArray && roleArray?.some((item) => item.role_name === "TRAINEE");

  const [officer, setOfficer] = useState(null);
  const [group, setGroup] = useState({
    value: false,
    label: getLabel(sysLanguage, "9000", "36", "No"),
  });
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState([]);
  const [company, setCompany] = useState(null);
  const [owner, setOwner] = useState(null);
  const [issue, setIssue] = useState(null);

  const [dateIssue, setDateIssue] = useState(new Date());

  const [loading, setLoading] = useState(false);

  const handleDateIssue = (newValue) => {
    setDateIssue(newValue);
  };

  const handleSave = async (formData) => {
    try {
      setLoading(true);
      const newTicket = await dispatch(ticketActions.addTicket(formData));
      setLoading(false);

      const ecryptedTicketId = btoa(
        newTicket.ticket_id?.toString().padStart(7, "0")
      );

      navigate(`/updateTicket/${ecryptedTicketId}`, {});
      props.handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          title: "",
          description: "",
        }}
        onSubmit={(values) => {
          const addedData = {
            ...values,
            group_ticket: props.isTrainee ? false : group.value,
            group_id: props.isTrainee
              ? null
              : group.value
              ? groupId.value
              : null,
            status: props.isTrainee ? "PEN" : "INP",
            company: props.isTrainee ? props.userData.company : company.value,
            owner_id: props.isTrainee
              ? props.userData.user_id
              : group.value
              ? groupId.value
              : owner.value,
            type: issue.value,
            assigned_to: trainee
              ? null
              : !!officer?.value
              ? officer?.value
              : userData.user_id,
            occur_date: dateIssue
              ? new Date(dateIssue).toLocaleDateString()
              : null,
          };
          handleSave(addedData);
        }}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <Stack spacing={2}>
              {!props.isTrainee && (
                <>
                  <Autocomplete
                    id="assigned_to"
                    name="assigned_to"
                    value={officer}
                    getOptionLabel={(option) => option.label || ""}
                    onChange={(e, data) => setOfficer(data)}
                    size="small"
                    options={props.officerName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "4",
                          "In-Charge Officer"
                        )}
                      />
                    )}
                  />
                  <Autocomplete
                    id="company"
                    name="company"
                    value={company}
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, data) => {
                      const fetchApi = async () => {
                        const output = await dispatch(
                          ticketActions.getGroupName({
                            company: !!data ? data.value : null,
                          })
                        );
                        setGroupName(output);
                      };
                      if (!!data) fetchApi();
                      setCompany(data);
                      setOwner(null);
                      setGroupId(null);
                    }}
                    size="small"
                    options={getOptions(sysLanguage, props.companyList)}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "5",
                          "Select Company"
                        )}
                      />
                    )}
                  />
                  <Autocomplete
                    disableClearable
                    id="group"
                    name="group"
                    value={group}
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, data) => {
                      setGroup(data);
                      setGroupId(null);
                      setOwner(null);
                    }}
                    size="small"
                    options={[
                      {
                        value: true,
                        label: getLabel(sysLanguage, "9000", "35", "Yes"),
                      },
                      {
                        value: false,
                        label: getLabel(sysLanguage, "9000", "36", "No"),
                      },
                    ]}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={getLabel(
                          sysLanguage,
                          "3000",
                          "72",
                          "Group Ticket"
                        )}
                      />
                    )}
                  />
                  {!!group?.value ? (
                    <Autocomplete
                      id="group_id"
                      name="group_id"
                      value={groupId}
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, data) => setGroupId(data)}
                      size="small"
                      options={
                        !!company
                          ? groupName?.filter(
                              (item) => item.company === company.value
                            )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={getLabel(
                            sysLanguage,
                            "3000",
                            "73",
                            "Select Group"
                          )}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id="owner_id"
                      name="owner_id"
                      value={owner}
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, data) => setOwner(data)}
                      size="small"
                      options={
                        !!company
                          ? props.traineeName.filter(
                              (item) => item.company === company.value
                            )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={getLabel(
                            sysLanguage,
                            "3000",
                            "6",
                            "Select Trainee"
                          )}
                        />
                      )}
                    />
                  )}
                </>
              )}
              <Autocomplete
                id="type"
                name="type"
                value={issue}
                getOptionLabel={(option) => option.label || ""}
                onChange={(e, data) => setIssue(data)}
                size="small"
                options={getOptions(
                  sysLanguage,
                  props.issueList.filter(
                    (item) =>
                      item.code !== "15" &&
                      item.code !== "14" &&
                      item.code !== "10"
                  )
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={getLabel(
                      sysLanguage,
                      "3000",
                      "7",
                      "Select Issue Type"
                    )}
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={getLabel(sysLanguage, "3000", "33", "Issue Date")}
                  inputFormat="yyyy/MM/dd"
                  value={dateIssue}
                  onChange={handleDateIssue}
                  renderInput={(params) => (
                    <TextField required size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
              <TextField
                required
                id="title"
                name="title"
                label={getLabel(sysLanguage, "3000", "39", "Title")}
                value={values.title}
                onChange={handleChange("title")}
                size="small"
              />
              <TextField
                required
                id="description"
                name="description"
                label={getLabel(sysLanguage, "3000", "40", "Issue Description")}
                multiline
                rows={3}
                value={values.description}
                onChange={handleChange("description")}
                size="small"
              />
            </Stack>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
              sx={{ mt: 2 }}
            >
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                color="primary"
                sx={{ mr: 1 }}
              >
                {getLabel(sysLanguage, "9000", "6", "Save")}
              </LoadingButton>
              <Button
                color="warning"
                variant="contained"
                onClick={props.handleClose}
              >
                {getLabel(sysLanguage, "9000", "7", "Cancel")}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTicket;
