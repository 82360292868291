import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import Navigator from "../components/Navigator";
import TraineeDash from "./TraineeDash";
import Header from "../components/Header";
import Footer from "../components/Footer";

import * as dashActions from "../../store/actions/dashboard";
import * as configActions from "../../store/actions/config";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 0;

export default function Paperbase() {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.userData[0]);
  const awsKey = useSelector((state) => state.config.awsKey);

  const roleArray = useSelector((state) => state.auth.userRoles);
  const isOfficer =
    roleArray && roleArray?.some((item) => item.role_name === "OFFICER");

  const profileOfficer =
    roleArray &&
    roleArray?.some(
      (item) =>
        item.role_name === "PROFILE_READ" || item.role_name === "PROFILE_WRITE"
    );

  const bannerOfficer =
    roleArray &&
    roleArray?.some(
      (item) =>
        item.role_name === "PROFILE_READ" ||
        item.role_name === "CREATE_ANNOUNCEMENT"
    );

  const companyList = useSelector((state) => state.config.companyList);
  const dashBannerList = useSelector((state) => state.dashboard.dashBannerList);

  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [loading, setLoading] = useState(true);

  const [like, setLike] = useState([]);
  const [dislike, setDislike] = useState([]);
  const [selRow, setSelRow] = useState();

  const [bulletinNbr, setBulletinNbr] = useState(0);

  useEffect(() => {
    const fetchApi = async () => {
      const data = {
        row_id: "",
        title: "",
        company: !!userData?.company ? userData.company : null,
        show: "A",
        forDisplay: isOfficer ? false : true,
      };
      setLoading(true);
      await dispatch(dashActions.getAllBanner(data));
      await dispatch(configActions.insertAcessLog());
      setLoading(false);
      loadData();
    };

    fetchApi();
    // if (loading) fetchApi();
    // if (!loading) loadData();
  }, [loading]);

  const loadData = () => {
    let likeArray = [];
    let dislikeArray = [];

    if (dashBannerList.length > 0) {
      dashBannerList?.map((item) => {
        likeArray.push({ row_id: item.row_id, user_liked: item.user_liked });
      });

      dashBannerList?.map((item) => {
        dislikeArray.push({
          row_id: item.row_id,
          user_disliked: item.user_disliked,
        });
      });

      setDislike(dislikeArray);
      setLike(likeArray);
    }
  };

  const refreshBanner = async () => {
    const data = {
      row_id: "",
      title: "",
      company: !!userData.company ? userData.company : null,
      show: "A",
      forDisplay: isOfficer ? false : true,
    };

    await dispatch(dashActions.getAllBanner(data));

    loadData();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {/* {isSmUp ? null : ( */}
          <Navigator
            PaperProps={{ style: { width: 250 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          {/* )} */}

          {/* <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          /> */}
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header onDrawerToggle={handleDrawerToggle} />

          {/* {!!dashBannerList.length > 0 && ( */}
          <TraineeDash
            bulletinNbr={bulletinNbr}
            setBulletinNbr={setBulletinNbr}
            dashBannerList={dashBannerList}
            awsKey={awsKey}
            setLike={setLike}
            like={like}
            setDislike={setDislike}
            dislike={dislike}
            selRow={selRow}
            setSelRow={setSelRow}
            refreshBanner={refreshBanner}
            isOfficer={isOfficer}
            profileOfficer={profileOfficer}
            bannerOfficer={bannerOfficer}
            companyList={companyList}
          />
          {/* )} */}
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
