const getLabel = (labelArray, msgset, msgnbr, defaultText) => {
  if (!!labelArray) {
    const result = labelArray.filter(
      (item) => item.msgset === msgset && item.msgnbr === msgnbr
    );
    if (result[0]) {
      return result[0].label;
    } else {
      return defaultText;
    }
  } else {
    return defaultText;
  }
};

export default getLabel;
