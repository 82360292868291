import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/material/styles";

import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import ListIcon from "@mui/icons-material/List";

import * as ticketActions from "../../store/actions/ticket";
import * as profileActions from "../../store/actions/profile";

import getLabel from "../../utils/getLabel";

const BoxCard = (props) => {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            maxHeight: 70,
          }}
        >
          {props.icon === "A" && (
            <BusinessIcon sx={{ fontSize: 45, color: "#42a5f5" }} />
          )}
          {props.icon === "B" && (
            <GroupIcon sx={{ fontSize: 45, color: "#4caf50" }} />
          )}
          {props.icon === "C" && (
            <ListIcon sx={{ fontSize: 45, color: "#ff9800" }} />
          )}
          <Stack>
            <Typography
              sx={{ fontSize: 20, fontWeight: 100 }}
              color="#9c27b0"
              gutterBottom
            >
              {props.title}
            </Typography>
            {props.loading || props.loadingProfile ? (
              <CircularProgress />
            ) : (
              <Typography
                sx={{ fontSize: 35, fontWeight: 800 }}
                color="#9c27b0"
              >
                {props.count}
              </Typography>
            )}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

const TopCards = () => {
  const dispatch = useDispatch();

  const sysLanguage = useSelector((state) => state.config.labels);

  const companyList = useSelector((state) => state.config.companyList);

  const [ticketData, setTicketData] = useState();
  const [ticketDataSearch, setTicketDataSearch] = useState();

  const [loading, setLoading] = useState(true);

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileData, setProfileData] = useState();

  const fetchTicket = async () => {
    try {
      setLoading(true);

      const data = {
        ticket_id: "",
        title: "",
        statPending: true,
        statInProgress: true,
        statResolved: false,
        company: null,
        issueType: null,
        priority: null,
        issueDtFrom: null,
        issueDtTo: new Date(),
        owner_id: null,
        assigned_to: null,
        dashboard: true,
      };

      const output = await dispatch(ticketActions.dashboardTicket(data));
      const newOuput = output.filter((item) => item.status !== "RSV");

      setTicketData(newOuput);
      setTicketDataSearch(newOuput);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const traineeInfo = async () => {
    try {
      setLoadingProfile(true);

      const data = {
        batchNo: "",
        company: null,
        passportDateFrom: null,
        passportDateTo: null,
        tioDateFrom: null,
        tioDateTo: null,
        trainee: null,
        visa: null,
        visaDateFrom: null,
        visaDateTo: null,
      };

      const output = await dispatch(profileActions.profileDashboard(data));
      setProfileData(output);

      setLoadingProfile(false);
    } catch (error) {
      setLoadingProfile(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTicket();
    traineeInfo();
  }, [dispatch]);

  return (
    <Fragment>
      <Box
        sx={{
          flex: 1,
          marginBottom: 2,
        }}
      >
        <Stack direction={"row"} spacing={3}>
          <Grid xs>
            <BoxCard
              title={getLabel(sysLanguage, "2000", "35", "All Companies")}
              count={companyList?.length}
              icon={"A"}
              loading={false}
            />
          </Grid>
          <Grid xs>
            <BoxCard
              title={getLabel(sysLanguage, "2000", "36", "All Users")}
              count={profileData?.length}
              icon={"B"}
              loading={loadingProfile}
            />
          </Grid>
          <Grid xs>
            <BoxCard
              title={getLabel(sysLanguage, "2000", "37", "Active Tickets")}
              count={ticketData?.length}
              icon={"C"}
              loading={loading}
            />
          </Grid>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default TopCards;
